import React from "react";
import Home from "../../pages/Home/Home";
import Pricing from "../../pages/Home/Pricing/Pricing";
import TermConditions from "../../pages/TearmsConditions/TermConditions";
import Privacy from "../../pages/TearmsConditions/Privacy";
import LoginPage from "../../pages/loginPage/LoginPage";
import Faq from "../../pages/FAQ/FAQ.js";
import ForgotPassword from "../../pages/ForgotPassword/ForgotPassword";

const PublicRoute = ({ Routes, Route }) => {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/forgotPassword" element={<ForgotPassword />}></Route>
      <Route path="/pricing" element={<Pricing />}></Route>
      <Route path="/terms-and-conditions" element={<TermConditions />}></Route>
      <Route path="/faq" element={<Faq />}></Route>
      <Route path="/privacy" element={<Privacy />}></Route>
      <Route path="/login" element={<LoginPage />}></Route>
    </Routes>
  );
};

export default PublicRoute;
