import React from "react";
import "./home.css";
import Carousel from "../../components/Carousel/Carousel";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import book from "../../assets/home/book.png";
import books from "../../assets/home/books.png";
import battery from "../../assets/home/battery.png";
import arm from "../../assets/home/arm.png";
import heart from "../../assets/home/heart.png";
import trophy from "../../assets/home/trophy.png";
import trophyLarge from "../../assets/home/trophyLarge.svg";
import cp1 from "../../assets/home/2.png";
import cp2 from "../../assets/home/1.png";
import cp3 from "../../assets/home/3.png";
import card1 from "../../assets/home/card1.png";
import card2 from "../../assets/home/card2.png";
import card3 from "../../assets/home/card3.png";
import card4 from "../../assets/home/card4.png";
import tick from "../../assets/home/tick.png";
import img5 from "../../assets/home/boy-headphone.svg";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import TickMark from "./TickMark";
AOS.init();
function Home() {
  document.title = "prepBubble";
  document.getElementsByTagName("body")[0].style.overflowY = "scroll";
  document.getElementsByTagName("body")[0].style.overflowX = "hidden";
  return (
    <>
      <Header />

      {/* Area 1 */}

      <div id="arr1">
        {/* <div id='arr1-back'>
          <img src={img1} alt='' draggable='flase' id='back-1' data-aos="fade-up"
                        data-aos-duration="1500"/>
          <img src={img2} alt='' draggable='flase' id='back-2' data-aos="fade-up"
                        data-aos-duration="1500"/>
          <img src={img3} alt='' draggable='flase' id='back-3' data-aos="fade-up"
                        data-aos-duration="1500"/>
          <img src={img4} alt='' draggable='flase' id='back-4' data-aos="fade-up"
                        data-aos-duration="1500"/>
          <img src={img5} alt='' draggable='flase' id='back-5' data-aos="fade-up"
                        data-aos-duration="1500"/>
        </div> */}

        <div id="arr1-text " data-aos="fade-up" data-aos-duration="1500">
          <div id="arr1-head">
            Redesigning the way,<br></br> you prepare for UPSC CSE Exam
          </div>
          <div id="arr1-sub-head">
          We are a score improvement platform to help<br></br> UPSC CSE aspirants to do unlimited practice sessions for prelims,<br></br> as well as analyze performance, and learn based on results.
          </div>
          <div className="flex justify-center my-4 w-[100%]">
            <Link
              to="/login"
              id=""
              className=" btn object-contain  bg-[#5D44DE] text-white font-semibold text-[14px] leading-[17px] flex justify-center items-center py-4  px-8"
            >
              FREE SIGN UP!
            </Link>
          </div>

          <div id="arr1-subtext">
            *Limited Time Offer Signup and Learn for Free
          </div>
        </div>
      </div>

      {/* Area 2 */}
      <div id="arr2">
        {/* <div id='arr2-1'>prepBubble is a score improvement platform for UPSC CSE aspirants to
          do unlimited practice sessions </div> */}
        <div id="arr2-2">
          {/* <div className='arr2-2-1' data-aos="fade-up"
                        data-aos-duration="1500">
            <img src={trophy} alt='' />
            <div>
              Why not dodge the disappointment of another week with inconsistent
              practice and scoring less than average marks in mocks AND INSTEAD
              practice consistently to improve SCORES
            </div>
          </div> */}
        </div>
        <div id="arr2-3">
          <div
            className="border-right table-section"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={heart} alt="" />
            <div>
              Practice anytime, anywhere <br />
            </div>
          </div>
          <div
            className="border-right table-section"
            data-aos="fade-up"
            data-aos-duration="1250"
          >
            <img src={battery} alt="" />
            <div>Improve retention power</div>
          </div>
          <div
            className="border-right table-section"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <img src={books} alt="" />
            <div>Identify preparation gaps</div>
          </div>
          <div
            className="table-section"
            data-aos="fade-up"
            data-aos-duration="1750"
          >
            <img src={arm} alt="" />
            <div>Identify Your Strengths & Maintain Them</div>
          </div>
        </div>
        <div id="arr2-4">
          SIGNUP AND PRACTICE FOR FREE NOW!{" "}
          <Link to="/login" className="sign-up-1" href="#">
            Signup Now
          </Link>
        </div>
      </div>

      {/* Area 3*/}
      <div id="col-1" className="col">
        <img src={cp1} alt="" data-aos="fade-up" data-aos-duration="0" />
        <div className="col-wrapper">
          <h1
            style={{
              fontWeight: "bold",
              color: "#934ab0",
              fontFamily: "Dancing Script",
              fontSize: "3rem",
            }}
          >
            2,00,000+ Full Test Combinations at your finger tips
          </h1>
          <div className="col-head" data-aos="fade-up" data-aos-duration="1500">
            Practice through
          </div>
          <div className="col-body" data-aos="fade-up" data-aos-duration="1500">
            <ul>
              <li>
                <TickMark /> 16000+ MCQ’s
              </li>
              <li>
                <TickMark />9 Subjects
              </li>
              <li>
                <TickMark />
                239 chapters{" "}
              </li>
              <li>
                <TickMark />
                Previous year questions from 2013{" "}
              </li>
              <li>
                <TickMark />
                Current affairs MCQs from April 2022
              </li>
              <li>
                <TickMark />
                UPSC High starandard questions
              </li>
            </ul>
          </div>
          {/* <button className='col-btn btn' data-aos="fade-up"
            data-aos-duration="1500">Signup</button> */}
        </div>
      </div>
      <div className="col reverse">
        <img src={cp2} alt="" data-aos="fade-up" data-aos-duration="1000" />
        <div className="col-wrapper">
          <h1
            style={{
              fontWeight: "bold",
              color: "#934ab0",
              fontFamily: "Dancing Script",
              fontSize: "3rem",
            }}
          >
            Be prelims smart with prepBubble Platform
          </h1>
          <div className="col-head" data-aos="fade-up" data-aos-duration="1250">
            UPSC CSE practice simplified
          </div>
          <div className="col-body" data-aos="fade-up" data-aos-duration="1500">
            <ul>
              <li>
                <TickMark />
                Advanced Analytics{" "}
              </li>
              <li>
                <TickMark />
                Self-paced test series
              </li>
              <li>
                <TickMark />
                Identify gaps in your preparation early on
              </li>
              <li>
                <TickMark />
                Chapter-by-chapter revision
              </li>
              <li>
                <TickMark />
                Note maker and Time tracker
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col">
        <img src={cp3} alt="" data-aos="fade-up" data-aos-duration="1000" />
        <div className="col-wrapper">
          <h1
            style={{
              fontWeight: "bold",
              color: "#934ab0",
              fontFamily: "Dancing Script",
              fontSize: "3rem",
            }}
          >
            Practice & Learn from 16,000+ MCQs
          </h1>
          <div className="col-head" data-aos="fade-up" data-aos-duration="1250">
            Why should you sign up for prepBubble?{" "}
          </div>
          <div className="col-body" data-aos="fade-up" data-aos-duration="1500">
            <h4>Get unlimited free access to </h4>
            <ul>
              <li>
                <TickMark />
                Cover the entire UPSC CSE syllabus through practice.{" "}
              </li>
              <li>
                <TickMark />
                Attend chapterwise tests
              </li>
              <li>
                <TickMark />
                Increase your score by 50%
              </li>
              <li>
                <TickMark />
                Improve conceptual clarity
              </li>
              <li>
                <TickMark />
                Reduce your preparation time by 25%
              </li>
              <li>
                <TickMark />
                Practice anytime and anywhere{" "}
              </li>
              <li>
                <TickMark />
                Compete with serious aspirants
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="border"></div>

      {/* Carousel Area 4*/}
      <div id="arr4">
        <Carousel />
      </div>

      {/* Area 5 */}
      <div id="arr5">
        <div id="arr5-1">Walk Away With</div>
        <div id="arr5-2">
          Everyone prepares differently it is why prepBubble is committed
          to helping <br />
          you prepare at your own pace and time
        </div>

        <div id="arr5-3">
          <div
            className="border-right table-section-5"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src={card1} alt="" />
            <div>
              10x more practice <br /> sessions
            </div>
          </div>
          <div
            className="border-right table-section-5"
            data-aos="fade-up"
            data-aos-duration="1250"
          >
            <img src={card2} alt="" />
            <div>
              Maximise your chances <br /> for clearing Prelims
            </div>
          </div>
          <div
            className="border-right table-section-5 "
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <img src={card3} alt="" />
            <div>
              Enhance productivity
              <br /> with better time
              <br /> management so that
              <br /> no day is gone waste
            </div>
          </div>
          <div
            className="table-section-5"
            data-aos="fade-up"
            data-aos-duration="1750"
          >
            <img src={card4} alt="" />
            <div>
              Get smarter with every <br />
              practice session
            </div>
          </div>
        </div>
      </div>

      {/* Area 6*/}
      <div id="arr7" data-aos="fade-up" data-aos-duration="1500">
        <div id="arr7-head">
          Track Your Progress Based <br /> On Key Metrics
        </div>
        <div id="arr7-sub-head">
          Everyone prepares differently it is why prepBubble is <br></br>{" "}
          committed to helping you prepare the way you want to prepare.
        </div>
        <div className="green-buttons">
          <div className="gbtn">
            <img src={tick} alt="" />
            Speed &Time Management
          </div>
          <div className="gbtn">
            <img src={tick} alt="" />
            Accuracy
          </div>
          <div className="gbtn">
            <img src={tick} alt="" />
            Conceptual Clarity
          </div>
        </div>
        <div className="green-buttons">
          <div className="gbtn">
            <img src={tick} alt="" />
            Number of test attempts
          </div>
          <div className="gbtn">
            <img src={tick} alt="" />
            Four stage reading report
          </div>
          <div className="gbtn">
            <img src={tick} alt="" />
            Right/Wrongs Questions Answered
          </div>
        </div>
        <div className="green-buttons">
          <div className="gbtn">
            <img src={tick} alt="" />
            Syllabus coverage
          </div>
          <div className="gbtn">
            <img src={tick} alt="" />
            Identify strong subjects
          </div>
          <div className="gbtn">
            <img src={tick} alt="" />
            Subject wise reports
          </div>
        </div>
      </div>

      {/* Area 7 */}
      <div id="arr6" data-aos="fade-up" data-aos-duration="1500">
        <div id="arr6-text">
          <div id="arr6-head">Free</div>
          <div className="circle"></div>
          <div id="arr6-sub-head">
            prepBubble is the perfect blend of technology and strategy
            for an effortless <br /> UPSC Preparation.
          </div>
          <button id="arr6-btn" className="btn">
            Claim your 30 Day Free Trial!
          </button>
          <div id="arr6-subtext">
            *Limited Time Offer Signup and Learn for Free
          </div>
        </div>
      </div>

      

      {/* Area 8 */}
      <div id="arr8">
        <img
          id="trophy"
          src={trophyLarge}
          alt=""
          data-aos="fade-up"
          data-aos-duration="00"
        />
        <div id="arr8-head" data-aos="fade-up" data-aos-duration="00">
          Become An IAS Officer With <br /> prepBubble
        </div>
        <div id="arr8-sub-head" data-aos="fade-up" data-aos-duration="00">
          Staying focused on preparation for such a long time is indeed a
          challenge. You are <br />
          likely to feel depressed and doubtful every once in a while, and we
          understand that. <br></br>
          <br></br>
          To become an IAS officer, it is necessary to be like an officer with a
          well-organized daily <br /> routine, and prepBubble helps you
          do just that!
        </div>
        <Link to="/subscription">
          <button
            id="arr8-btn"
            className="btn flex justify-center w-[100%] px-14 "
            data-aos="fade-up"
            data-aos-duration="00"
          >
            Get your free account today
          </button>
        </Link>
      </div>

      <Footer />
    </>
  );
}

export default Home;
