import React, { useState } from "react";
import Sidebar from "../../components/Dashboard/Sidebar.js";
import DashboardDivision from "../../components/Dashboard/DashboardDivision.js";
import globe from "../../assets/login/globe.svg";

import service from "../../@common/auth/FetchInterceptor.js";
import { env } from "../../@common/helpers/EnvironmentConfig.js";
import {
  isEmail,
  isMobileNumber,
  isRequired,
} from "../../@common/helpers/Validators.js";
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
  USER_DETAILS_UPDATED,
  VALIDATION_ERROR,
} from "../../@common/constants/ActionTypes.js";
import { notification } from "antd";
import axios from "axios";

const Profile = () => {
  React.useEffect(() => {
    getUserInfo();
  }, []);

  const [userInfo, setUserInfo] = useState({});
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("")
  const [userInfoForm, setUserInfoForm] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    mobile_number: "",
    password: "",
  });
  const [disable, setDisable] = useState(true);
  const [passwordEnable, setPasswordEnable] = useState(true);

  const getUserInfo = async () => {
    try {
      const resp = await service.get(
        env.API_ENDPOINT_URL + "participant/get-user"
      );
      if (resp.success) {
        setUserInfo(resp.data);
        setUserInfoForm({
          first_name: resp.data.first_name,
          last_name: resp.data.last_name,
          email_id: resp.data.email_id,
          mobile_number: resp.data.mobile_number,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onCHangeInput = (val, name) => {
    console.log(val, name);
    if (name === "first_name") {
      setUserInfoForm({ ...userInfoForm, first_name: val });
    } else if (name === "last_name") {
      setUserInfoForm({ ...userInfoForm, last_name: val });
    } else if (name === "email_id") {
      setUserInfoForm({ ...userInfoForm, email_id: val });
    } else if (name === "mobile_number") {
      setUserInfoForm({ ...userInfoForm, mobile_number: val });
    } else {
      setUserInfoForm({ ...userInfoForm, password: val });
      setPassword(val);
    }
  };

  async function updatepassword() {
    const token = localStorage.getItem("access_token");
    const header_token = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const API_URL = env.API_ENDPOINT_URL + "participant/update-password";
    console.log("ans", userInfoForm.password);
    const resp = await axios.post(API_URL, password, header_token);

    console.log("ans-resp", resp);
  }

  const Validate = () => {
    const is_name = isRequired(userInfoForm.first_name);
    const is_last_name = isRequired(userInfoForm.last_name);
    const is_email = isEmail(userInfoForm.email_id);
    const is_phoneNumber = isMobileNumber(userInfoForm.mobile_number);
    if (
      is_name === "" &&
      is_last_name === "" &&
      is_email === "" &&
      is_phoneNumber === ""
    ) {
      return true;
    } else {
      notification[NOTIFICATION_TYPE_ERROR]({
        message: VALIDATION_ERROR,
        description: (
          <ul>
            <li>{`${is_email}`}</li>
            <li>{`${is_phoneNumber}`}</li>
            <li>{`${is_name ? "Required First Name" : ""}`}</li>
            <li>{`${is_last_name ? "Required Last Name" : ""}`}</li>
          </ul>
        ),
      });
      return false;
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    if (Validate()) {
      const request = {
        first_name: userInfoForm.first_name,
        last_name: userInfoForm.last_name,
        email_id: userInfoForm.email_id,
        mobile_number: userInfoForm.mobile_number,
        gender: gender,
      };
      const resp = await service.post(
        env.API_ENDPOINT_URL + "participant/update-user",
        request
      );
      console.log(resp);
      if (resp.success) {
        getUserInfo();
        setDisable(true);
        notification[NOTIFICATION_TYPE_SUCCESS]({
          message: USER_DETAILS_UPDATED,
        });
      }
    }
  };

  return (
    <div>
      <div className="h-2.5 custom-gradiant"></div>
      <div className="flex font-Inter non-italic w-[95%] m-auto lg:flex-col">
        <Sidebar />
        <div className="w-3/4  overflow-hidden flex flex-col mt-12 font-Inter non-italic text-[#303030] px-4 py-2 border-none lg:mt-0 lg:w-[98%] lg:m-auto">
          <div className="flex flex-col items-start mb-4">
            <h1 className="font-semibold text-[28px] leaading-[34px]">
              Profile
            </h1>
            <p className="font-light text-[14px] leading-[17px] my-4">
              Complete your daily tasks to fastrack your preparation.
            </p>
          </div>
          <DashboardDivision />
          <div className="flex my-8 sm:flex-col">
            <div className="w-[30%] pr-10 mr-20 flex flex-col items-start lg:w-[40%] sm:w-[100%] sm:pr-0 sm:mr-0 sm:mb-4">
              <h1 className="font-semibold text-[22px] leading-[27px] mb-6">
                Name & Communication Details
              </h1>
              <p className="font-medium text-[12px] leading-[17px] text-[#646060]">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium
              </p>
              <button
                onClick={() => setDisable(false)}
                className="bg-[#FB9C45] rounded-[5px] text-white px-4 py-2 my-4 mt-10"
              >
                <span className="font-normal text-[14px] leading-[19px]">
                  Edit Profile
                </span>
              </button>
              <button
                onClick={() => setPasswordEnable(false)}
                className="bg-[#FB6645] rounded-[5px] text-white px-4 py-2 my-2"
              >
                <span className="font-normal text-[14px] leading-[19px]">
                  Reset Password
                </span>
              </button>
              {!passwordEnable && (
                <>
                  <p className="font-semibold text-[16px] leading-[19px] text-[#303030] mb-6 mt-6">
                    Enter New Password
                  </p>
                  <input
                    className="border-[1px] border-[#E6E6E6] rounded-[5px] px-4 py-6 max-w-[338px] w-[100%] mb-10 font-semibold text-[14px] leading-[17px]"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter Your Password"
                    value={userInfoForm.password}
                    onChange={(event) =>
                      onCHangeInput(event.target.value, "password")
                    }
                    required
                  />
                  <button
                    onClick={updatepassword}
                    className="loginSubmitBtn bg-[#113359]"
                  >
                    Update Password
                  </button>
                </>
              )}
            </div>
            <div className="flex flex-col w-[50%] px-8 sm:w-[100%] sm:px-0 sm:mt-6">
              <form action="" onSubmit={handleSignup}>
                <p className="font-semibold text-[16px] leading-[19px] text-[#303030] mb-6">
                  First Name
                </p>
                <input
                  type="text"
                  placeholder="First Name"
                  className="border-[1px] border-[#E6E6E6] rounded-[5px] px-4 py-6 max-w-[338px] w-[100%] mb-14 font-semibold text-[14px] leading-[17px]"
                  value={userInfoForm.first_name}
                  disabled={disable}
                  onChange={(event) =>
                    onCHangeInput(event.target.value, "first_name")
                  }
                />
                <p className="font-semibold text-[16px] leading-[19px] text-[#303030] mb-6">
                  Last Name
                </p>
                <input
                  type="text"
                  placeholder="Last Name"
                  className="border-[1px] border-[#E6E6E6] rounded-[5px] px-4 py-6 max-w-[338px] w-[100%] mb-14 font-semibold text-[14px] leading-[17px]"
                  value={userInfoForm.last_name}
                  onChange={(event) =>
                    onCHangeInput(event.target.value, "last_name")
                  }
                  disabled={disable}
                />
                <p className="font-semibold text-[16px] leading-[19px] text-[#303030] mb-6">
                  Gender
                </p>
                <select onChange={(e)=>setGender(e.target.value)} className="border-[1px] border-[#E6E6E6] rounded-[5px] px-4 py-6 max-w-[338px] w-[100%] mb-14 font-semibold text-[14px] leading-[17px]">
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Prefer not to Say</option>
                </select>

                {/* <p className="font-semibold text-[16px] leading-[19px] text-[#303030] mb-6">
                  Location
                </p> */}
                {/* <input
                  type="text"
                  placeholder="Location"
                  className="border-[1px] border-[#E6E6E6] rounded-[5px] px-4 py-6 max-w-[338px] w-[100%] mb-14 font-semibold text-[14px] leading-[17px]"
                  
                  disabled={disable}
                /> */}
                <p className="font-semibold text-[16px] leading-[19px] text-[#303030] mb-6">
                  Communication details
                </p>
                <input
                  type="email"
                  name="your email"
                  placeholder="Your Email"
                  className="border-[1px] border-[#E6E6E6] rounded-[5px] px-4 py-6 max-w-[338px] w-[100%] font-semibold text-[14px] leading-[17px]"
                  value={userInfoForm.email_id}
                  onChange={(event) =>
                    onCHangeInput(event.target.value, "email_id")
                  }
                  disabled={disable}
                />

                <div className="flex my-6 items-center w-[100%]">
                  <div className="flex border-[1px] border-[#DEDEDE] py-6 px-6 rounded-[5px] items-center justify-center ">
                    <img src={globe} alt="globe" className="ml-3" />
                    <p className=" text-[14px] text-[#303030] font-semibold leading-[17px]">
                      +91
                    </p>
                  </div>
                  <input
                    type="number"
                    pattern="/^\d{10}$/"
                    maxLength="10"
                    placeholder="Phone Number"
                    value={userInfoForm.mobile_number}
                    onChange={(event) =>
                      onCHangeInput(event.target.value, "mobile_number")
                    }
                    disabled={disable}
                    className="max-w-[235px] w-[100%] text-left pl-6 font-semibold text-[14px] leading-[17px] ml-6"
                    required
                  />
                </div>
                {!disable && (
                  <button className="loginSubmitBtn bg-[#113359]" type="submit">
                    Update Details
                  </button>
                )}
              </form>
            </div>
          </div>
          {/* Choose you avatar part below */}
          {/* <div className="flex mb-20  sm:flex-col sm:text-center"> */}
          {/* <div className="w-[30%] pr-10 mr-20 flex flex-col items-start lg:w-[40%] sm:w-[100%] sm:pr-0 sm:mr-0">
              <h1 className="font-semibold text-[22px] leading-[27px] my-4 sm:w-[100%]">
                Choose Your Avatar
              </h1>
              <p className="font-normal text-[12px] leading-[17px] text-[#646060]">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium
              </p>
            </div>
            <div className="flex justify-center">
              <div className="flex px-8 items-center sm:mt-4">
                <div className="rounded-full hover:border-2 hover:border-[#5D44DE] p-4">
                  <div className="rounded-full relative    ">
                    <img
                      src={bckgrnd1}
                      alt="background"
                      className="absolute "
                    />
                    <img src={avatar1} alt="avatar" className="z-10 relative" />
                  </div>
                </div>
                <div className="rounded-full hover:border-2 hover:border-[#5D44DE] p-4">
                  <div className="rounded-full relative  ">
                    <img
                      src={bckgrnd2}
                      alt="background"
                      className="absolute "
                    />

                    <img src={avatar2} alt="avatar" className="z-10 relative" />
                  </div>
                </div>

                <div className="rounded-full hover:border-2 hover:border-[#5D44DE] p-4">
                  <div className="rounded-full relative ">
                    <img src={bckgrnd3} alt="background" className="absolute" />

                    <img src={avatar3} alt="avatar" className="z-10 relative" />
                  </div>
                </div>
                <div className="rounded-full hover:border-2 hover:border-[#5D44DE] p-4">
                  <div className="rounded-full relative ">
                    <img
                      src={bckgrnd4}
                      alt="background"
                      className="absolute z-0"
                    />

                    <img src={avatar4} alt="avatar" className="z-10 relative" />
                  </div>
                </div>
              </div>
            </div> */}
          {/* </div> */}
          <div className="flex mb-96 sm:flex-col sm:text-center">
            <div className="w-[30%] pr-10 mr-20 flex flex-col items-start lg:w-[40%] sm:w-[100%] sm:pr-0 sm:mr-0">
              <h1 className="font-semibold text-[22px] leading-[27px] text-[#303030] sm:w-[100%]">
                Your Referal Link
              </h1>
              <p className="font-normal text-[12px] leading-[17px] my-6">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium
              </p>
            </div>
            <div className="px-8 w-[40%] sm:w-[100%]">
              <form action="" className="mb-8">
                <input
                  type="url"
                  name="referalLink"
                  placeholder="Referal Link"
                  className="w-[100%] max-w-[587px] border-[1px] border-[#E6E6E6] rounded-[5px] px-4 py-6 font-semibold text-[14px] leading-[17px]"
                />
              </form>
              <a
                href="#"
                className="font-medium text-[14px] leading-[19px] underline active:text-[black]"
              >
                Copy Link
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
