//NOTIFICATION

export const NOTI_MESSAGE_VALIDATION = 'Please Enter The Details';
export const NOTI_DES_VALIDATION = 'Please enter the mandatory fields!!!';
export const NOTI_PLACEMENT_TOP = 'top';
export const NOTI_PLACEMENT_RIGHT = 'right';
export const NOTI_PLACEMENT_LEFT = 'left';
export const NOTI_PLACEMENT_BOTTOM = 'BOTTOM';

//Regex

export const EMAIL_REGEX = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";