import {
  NOTIFICATION_ENABLE,
  NOTIFICATION_DISABLE
} from '../../@common/constants/ActionTypes';

const INIT_STATE = {
  message: '',
  description: '',
  placement: ''
};

const notificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_ENABLE: {
      return { ...state, message: action.payload.message, description: action.payload.description, placement: action.payload.placement };
    }
    case NOTIFICATION_DISABLE: {
      return { ...state, toggle: false, message: '', description: '', placement: '' };
    }
    default:
      return state;
  }
};
export default notificationReducer;
