import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiMenuAltLeft } from "react-icons/bi";
import { env } from "../../@common/helpers/EnvironmentConfig";
import DashboardDivision from "../../components/Dashboard/DashboardDivision";
import Sidebar from "../../components/Dashboard/Sidebar";
import logo from "../../assets/header/logo.png";
import { getUserName } from "../../@common/shared/LocalStorage/localstorage";
import { useLocation } from "react-router-dom";
import "./TestExplanation.css";
import { ScaleLoader } from "react-spinners";

export const TestExplanation = () => {
  let location = useLocation();
  const [userName] = useState(getUserName());
  const [testExplainedQuestions, setTestExplaniedQuestions] = useState(null);
  const [phoneSidebarStatus, setPhoneSidebarStatus] = useState(false);
  const [testId, setTestId] = useState(location.state.testId);
  const [loader, setLoader] = useState(false);

  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };

  async function getTestExplainedQuestions() {
    const url =
      env.API_ENDPOINT_URL + `participant/test/get-answer-explanation`;
    try {
      const config = {
        test_id: testId,
        limit: 25,
        page: 1,
      };
      setLoader(true);
      const resp = await axios.post(url, config, header_token);
      setLoader(false);
      setTestExplaniedQuestions(resp.data.data.items);
    } catch (err) {
      setLoader(false);
    }
  }

  useEffect(() => {
    getTestExplainedQuestions();
  }, []);
  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      window.history.go(1);
    });
  }, []);

  const [activeId, setActiveId] = useState(null);

  const toggleActive = (id) => {
    if (id === activeId) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <div>
      <>
        {loader && <ScaleLoader
          color="rgb(93 68 222)"
          height={24}
          margin={4}
          radius={0}
          width={15}
          loading={true}
          className="loader"
        />}
        <div className="h-2.5 custom-gradiant"></div>
        {/* after completing content section remove height on this  */}

        <div className="flex items-center flex-row-reverse p-10 bg-[#FAFAFA] w-[95%] m-auto rounded-[20px] my-10 justify-between minlg:hidden">
          <div
            onClick={() => setPhoneSidebarStatus(!phoneSidebarStatus)}
            className="w-16"
          >
            <BiMenuAltLeft className="w-[100%] h-[100%]" />
          </div>
          <img
            src={logo}
            alt="logo"
            className="max-w-custom-s max-h-custom-s overflow-hidden"
          />
        </div>

        <div className="flex flex-wrap not-italic font-Inter w-[95%] m-auto">
          <Sidebar userName={userName} status={phoneSidebarStatus} />

          <div
            className="w-3/4  overflow-hidden flex flex-col mt-12 font-Inter non-italic text-[#303030] px-4 py-2 border-none lg:mt-0 lg:w-[98%] lg:m-auto "
            id="explanation"
          >
            <div className="flex flex-col mb-4">
              <h1 className="font-semibold  text-[28px] leading-[34px] mb-2">
                Test Explanation
              </h1>
              <p className="font-light text-[14px] leading-[17px] mt-2">
                Your previous test explanation.
              </p>
            </div>
            <DashboardDivision />

            {testExplainedQuestions?.map((question, index) => (
              <div
                key={question.correctAnswer}
                className="bg-white rounded-lg shadow-md overflow-hidden mb-4"
              >
                <div
                  className="flex justify-between items-center cursor-pointer px-6 py-4"
                  onClick={() => toggleActive(question.correctAnswer)}
                >
                  <h2
                    style={{ fontSize: "15px", color: "#9648ad" }}
                    className="text-lg font-large"
                  >
                    <p>
                      <div className="flex">
                        <span>{index + 1}. </span>{" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: question.questionTitle,
                          }}
                        />
                      </div>
                    </p>
                  </h2>
                  {activeId === question.correctAnswer ? (
                    <p className="sign">-</p>
                  ) : (
                    <p className="sign">+</p>
                  )}
                </div>
                {activeId === question.correctAnswer && (
                  <div className="p-6">
                    <strong className="text-fuchsia-700">Question</strong>
                    <p>
                      <div className="flex">
                        <span>{index + 1}. </span>{" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: question.questionTitle,
                          }}
                        />
                      </div>
                      <strong className="text-fuchsia-700">Options</strong>
                      {question?.questionOptions.map((option, index) => (
                        <p>
                          {option.isCorrect ? (
                            <p style={{ color: "green", fontWeight: "bold" }}>
                              {option.optionValue} ✅
                            </p>
                          ) : (
                            <p>{option.optionValue}</p>
                          )}{" "}
                        </p>
                      ))}
                    </p>
                    {/* <strong className="text-fuchsia-700">Answer</strong>
                    <p className="text-gray-700">{question.correctAnswer}</p> */}
                    <br></br>
                    <strong className="text-fuchsia-700 mt-8">
                      Explanation
                    </strong>
                    <p>
                      <div className="flex">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: question.questionExplanation,
                          }}
                        />
                      </div>
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    </div>
  );
};
