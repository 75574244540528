import React from "react";
import { Doughnut } from "react-chartjs-2";
const DonutGraphNew = ({ graphDescription, graphData, dataSet }) => {
  const data = {
    // labels: ['Total Questions','Correct','Incorrect','Unattended'],
    datasets: [
      {
        // label: "progress",
        data: dataSet,
        backgroundColor: ["red", "#008ce0", "#00af41"],
        borderColor: ["red", "#008ce0", "#00af41"],
        borderWidth: 1,
      },
    ],
  };
  var options = {
    cutout: '80%',
  };
  return (
    <div className="flex justify-center items-center flex-col w-[100%] relative mx-2">
      <Doughnut data={data} options={options} />
      <p className="mt-4">{graphDescription}</p>
      <div className="flex flex-col justify-center text-left items-center absolute top-[35%]">
        {graphData.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
      </div>
    </div>
  );
};

export default DonutGraphNew;
