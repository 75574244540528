import React, { useEffect, useState } from "react";
import service from "../../@common/auth/FetchInterceptor";
import { env } from "../../@common/helpers/EnvironmentConfig";
import logo from "../../assets/header/logo.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import { NOTIFICATION_TYPE_ERROR, VALIDATION_ERROR } from "../../@common/constants/ActionTypes";
import { notification } from "antd";

const ActivityTrackerModal = ({ open, setOpen, timerAPISubmit,setSubName,setChapName }) => {

  const [subjetList, setSubjectList] = useState(null);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isDropDownOpen2, setIsDropDownOpen2] = useState(false);
  const [timerRequest, setTimerRequest] = useState({ subjectId: '', subjectName: '', chapterId: '', chapterName: '' });
  const [choosenSubject, setChoosenSubject] = useState("-- Select Subject --");
  const [choosenChapter, setChapter] = useState("-- Select Chapter --");
  const [topic, setTopic] = useState(null);
  const dropDownOpen = {
    transitionDuration: "0.5s",
    position: "absolute",
    overflowX: " hidden",
    overflowY: "scroll",
    maxHeight: "40vh",
    height: "100%",
    // background: "white",
    background: "#fefaff",
    padding: "5px",
    borderRadius: "10px",
  };
  const dropDownClose = {
    transitionDuration: "0.3s",
    position: "absolute",
    overflowX: " hidden",
    overflowY: "hidden",
    maxHeight: "40vh",
    height: "0px",
    padding: "0px 5px",
    background: "white",
  };
  useEffect(() => {
    getSubjects();
  }, []);

  useEffect(() => {
    const closeDropdowne = (e) => {
      if (e.target.id !== "subjectDropdown") {
        // console.log(e.target.id);
        setIsDropDownOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdowne);
    return () => document.body.removeEventListener("click", closeDropdowne);
  }, []);
  useEffect(() => {
    const closeDropdowne = (e) => {
      if (e.target.id !== "subjectDropdown2") {
        // console.log(e.target.id);
        setIsDropDownOpen2(false);
      }
    };
    document.body.addEventListener("click", closeDropdowne);
    return () => document.body.removeEventListener("click", closeDropdowne);
  }, []);
  if (!open) return null;

  async function getTopics(id, subjectName) {
    setChoosenSubject(subjectName);
    setSubName(subjectName);
    setTimerRequest({ ...timerRequest, subjectId: id, subjectName: subjectName });
    setChapter("-- Select Chapter --");
    const api = env.API_ENDPOINT_URL + `participant/test/get-chapters/${id}`;
    try {
      const resp = await service.get(env.API_ENDPOINT_URL + `participant/test/get-chapters/${id}`);
      setTopic(resp.data.chapter);
    } catch (err) { }
  }

  async function getChapters(id, chapterName) {
    setTimerRequest({ ...timerRequest, chapterId: id, chapterName: chapterName });
    setChapter(chapterName);
    setChapName(chapterName);
  }

  async function getSubjects() {
    try {
      const resp = await service.get(env.API_ENDPOINT_URL + 'participant/test/get-subjects');
      setSubjectList(resp.data);
    } catch (err) {
      console.log(err);
    }
  }

  const submitAPI = async () => {
    console.log(timerRequest);
    if (timerRequest.chapterId !== '' && timerRequest.subjectId !== '' && timerRequest.subjectName !== '' && timerRequest.chapterName !== '') {
      timerAPISubmit(timerRequest);
      setOpen(false);
      setChapter("-- Select Chapter --");
      setChoosenSubject("-- Select Subject --");
      setTimerRequest({ subjectId: '', subjectName: '', chapterId: '', chapterName: '' });
    } else {
      notification[NOTIFICATION_TYPE_ERROR]({
        message: VALIDATION_ERROR,
        description: `Please Select the details...`,
      });
    }
  }

  return (
    <div className="bg-white z-30 fixed top-[1%] w-[45%] left-[25%] flex flex-col items-center max-w-[779px] rounded-[20px] py-10 px-14 font-Inter non-italic md:w-[80%] md:left-[10%] md:py-8 md:px-8 lg:w-[60%] lg:left-[20%] lg:py-8 lg:px-8">
      <img src={logo} alt="" className="mb-10" />
      <div className="flex flex-col items-center text-center mb-16">
        <h1 className="font-semibold text-[28px] leading-[34px] text-[#303030] my-6">
          Activity Starter
        </h1>
      </div>
      <div className="flex items-center justify-center mb-4">
        <div className="flex flex-col items-center mx-14 sm:text-center sm:mx-8">
          <h1 className="font-semibold text-[32px] leading-[39px] text-[#303030] sm:text-[20px]">
            <div onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
              <button
                id="subjectDropdown"
                className=" flex items-center rounded-[35px] border-2 px-2 py-2 border-[#E4E4E4] hover:border-[black] mt-4 mb-2"
              >
                <span className=" -z-50 bg-[#5D44DE] text-white rounded-full w-12 h-12 flex items-center justify-center">
                  A
                </span>
                <p className="w-[120px] truncate -z-50 mx-4 text-lg  text-black font-semibold ">
                  {choosenSubject}
                </p>

                <span className="-z-50">
                  <RiArrowDropDownLine size="24px" />
                </span>
              </button>
              <div style={isDropDownOpen ? dropDownOpen : dropDownClose}>
                {subjetList
                  ? subjetList.map((e) => (
                    <button
                      onClick={() => getTopics(e.id, e.subjectName)}
                      className="w-[185px]   flex items-center rounded-[35px]  px-2 py-1 border-[#E4E4E4] hover:border-[black] mt-4 mb-6 "
                    >
                      <span className="bg-[#5D44DE] text-white rounded-full w-12 h-12 flex items-center justify-center ">
                        {e.subjectName.charAt(0)}
                      </span>
                      <p className="w-[150px] truncate mx-4 text-lg text-left text-black font-semibold ">
                        {e.subjectName}
                      </p>
                    </button>
                  ))
                  : null}
              </div>
              {/* Add dropdown after suggestion */}
            </div>
          </h1>
        </div>
        <div className="border-[1px] border-[#B9B9B9] h-20  w-0 mx-6 "></div>
        <div className="flex flex-col items-center mx-14 sm:mx-8">
          <h1 className="font-semibold text-[32px] leading-[39px] text-[#303030] sm:text-[20px]  sm:w-[100%]">
            {" "}
            <div onClick={() => setIsDropDownOpen2(!isDropDownOpen2)}>
              <button
                id="subjectDropdown2"
                className=" flex items-center rounded-[35px] border-2 px-2 py-2 border-[#E4E4E4] hover:border-[black] mt-4 mb-2"
              >
                <span className=" -z-50 bg-[#5D44DE] text-white rounded-full w-12 h-12 flex items-center justify-center">
                  A
                </span>
                <p className="w-[120px] truncate -z-50 mx-4 text-lg  text-black font-semibold ">
                  {choosenChapter}
                </p>

                <span className="-z-50">
                  <RiArrowDropDownLine size="24px" />
                </span>
              </button>
              <div style={isDropDownOpen2 ? dropDownOpen : dropDownClose}>
                {topic
                  ? topic.map((e) => (
                    <button
                      onClick={() => getChapters(e._id, e.chapterName)}
                      className="w-[185px]   flex items-center rounded-[35px]  px-2 py-1 border-[#E4E4E4] hover:border-[black] mt-4 mb-6 "
                    >
                      <span className="bg-[#5D44DE] text-white rounded-full w-12 h-12 flex items-center justify-center ">
                        {e.chapterName.charAt(0)}
                      </span>
                      <p className="w-[150px] truncate mx-4 text-lg text-left text-black font-semibold ">
                        {e.chapterName}
                      </p>
                    </button>
                  ))
                  : null}
              </div>
              {/* Add dropdown after suggestion */}
            </div>
          </h1>
        </div>
      </div>
      <button
        className="bg-[#5D44DE] text-white text-center px-8 py-4 max-w-[260px] rounded-md w-[100%] my-6 hover:scale-110 transition-all"
        onClick={() => {
          submitAPI()
        }}
      >
        Start Timer
      </button>
    </div>
  );
};

export default ActivityTrackerModal;
