import React from "react";
import logo from "../../assets/header/logo.png";
import displayPic from "../../assets/dashboard/displayPic.svg";
import maleDisplayPic from "../../assets/dashboard/maleDisplayPic.svg";
import displayBckgrnd from "../../assets/dashboard/displayBckgrnd.svg";
import displayShadow from "../../assets/dashboard/displayShadow.svg";
import aspirantStar from "../../assets/dashboard/aspirantStar.svg";
import home from "../../assets/dashboard/home.svg";
import profile from "../../assets/dashboard/profile.svg";
import test from "../../assets/dashboard/test.svg";
import challenge from "../../assets/dashboard/challenge.svg";
import stats from "../../assets/dashboard/stats.svg";
import question from "../../assets/dashboard/question.svg";
import subscription from "../../assets/dashboard/subscription.svg";
import aspirantCoins from "../../assets/dashboard/aspirantCoins.svg";
import productivityTracker from "../../assets/dashboard/productivityTracker.svg";
import noteMaker from "../../assets/dashboard/noteMaker.svg";
import logoutSVG from "../../assets/dashboard/logout.svg";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import "./sidebar.css";
import { useState, useEffect } from "react";
import { useAuthMethod } from "../../@common/helpers/AuthHooks";
import Subscription from "../../pages/Subscription/Subscription";

const Sidebar = ({ userName, status }) => {
  const { logout } = useAuthMethod();
  const phoneSidebarOpen = {
    width: "80w !important",
    opacity: 1,
  };
  const phoneSidebarClose = {
    width: "0 !important",
    opacity: 0,
  };
  return (
    <>
      {/* To bring back the sidebar on small screen just chnage the width in line 34 and paddings in line 35 */}

      <div
        className={
          status
            ? "w-1/4 lg:w-4/5  lg:z-10 phoneSidebarOpen "
            : "w-1/4 lg:w-0 lg:z-10 phoneSidebar "
        }
      >
        <div className=" min-h-fit overflow-hidden  bg-[#FAFAFA] rounded-[24px] mx-14  px-8 py-4 lg:px-0 lg:py-0  flex flex-col align-center">
          <div className="flex justify-center items-center my-8 ">
            <img
              className="max-w-custom-s max-h-custom-s overflow-hidden"
              src={logo}
              alt="logo"
            />
          </div>
          <p className="text-center text-sm relative bottom-[15px] font-bold">
            BETA Version
          </p>
          <div className="flex justify-center items-center relative h-100px my-2 lg:hidden">
            <img
              className=" relative w-[55%] z-10"
              src={maleDisplayPic}
              alt="profile pic"
            />
            <img
              className=" absolute w-[55%] lg:hidden"
              src={displayBckgrnd}
              alt="profile pic"
            />
          </div>
          <div className="flex justify-center mt-0.5 mb-2 lg:hidden">
            <div className="max-w-[135px] w-[80%] overflow-hidden flex justify-center">
              <img className=" w-[100%]" src={displayShadow} alt="shadow" />
            </div>
          </div>

          <div className="flex items-center flex-col my-6 lg:hidden">
            <h1 className="mb-2 font-semibold text-[1vmax] leading-5 text-black">
              {userName}
            </h1>
            <div className="flex justify-center items-center my-[1vmin] w-2/3">
              <img src={aspirantStar} className=" w-[0.8vmax]" alt="" />
              <p className="mx-3 text-xl leading-4">prepBubble Star</p>
            </div>
          </div>

          <div className="text-black phoneSidebarContent">
            <ul className="transition-all">
              <NavLink
                to="/dashboard"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start `}
              >
                {/* <RiHomeSmileFill /> */}

                <img
                  className="w-[10%] max-h-[20px] lg:hidden"
                  src={home}
                  alt="dashboard"
                />
                <p className="mx-6 text-[1vmax] lg:text-[13px] ">Dashboard</p>
              </NavLink>

              <NavLink
                to="/profile"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start `}
              >
                {/* <RiHomeSmileFill /> */}

                <img
                  className="w-[10%] max-h-[20px] lg:hidden"
                  src={profile}
                  alt="home"
                />
                <p className="mx-6 text-[1vmax] lg:text-[13px] ">Profile</p>
              </NavLink>
              {/*<NavLink
                to="/syllabus"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start`}
              >
                <img
                  className="w-[10%] max-h-[20px] lg:hidden"
                  src={syllabus}
                />
                <p className=" mx-6 text-[1vmax] lg:text-[13px]">Syllabus</p>
      </NavLink> */}

              <NavLink
                to="/test"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start`}
              >
                <img className="w-[10%] max-h-[20px] lg:hidden" src={test} />
                <p className="mx-6 text-[1vmax] lg:text-[13px]">Test</p>{" "}
              </NavLink>

              {/* <NavLink
                to="/challenge"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start`}
              >
                <img
                  className="w-[10%] max-h-[20px] lg:hidden"
                  src={challenge}
                />
                <p className="mx-6 text-[1vmax] lg:text-[13px]">Ranking</p>
              </NavLink> */}

              <NavLink
                to="/stats"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start`}
              >
                <img className="w-[10%] max-h-[20px] lg:hidden" src={stats} />
                <p className="mx-6 text-[1vmax] lg:text-[13px]">My Analytics</p>
              </NavLink>
            </ul>
            <ul className="mt-8 lg:mt-0">
              {/* <NavLink
                to="/aspirantcoins"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start`}
              >
                <img
                  className="w-[10%] max-h-[20px] lg:hidden"
                  src={aspirantCoins}
                />
                <p className="mx-6 text-[1vmax] lg:text-[13px]">
                  prepBubble Coins
                </p>
              </NavLink> */}

              <NavLink
                to="/productivity"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start`}
              >
                <img
                  className="w-[10%] max-h-[20px] lg:hidden"
                  src={productivityTracker}
                />
                <p className="mx-6  text-[1vmax] 2xl:ml-6 2xl:mr-0 lg:text-[13px] ">
                  Learning Time Tracker
                </p>
              </NavLink>

              <NavLink
                to="/notes"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start`}
              >
                <img
                  className="w-[10%] max-h-[20px] lg:hidden"
                  src={noteMaker}
                />
                <p className="mx-6 text-[1vmax] lg:text-[13px]">Note Maker</p>
              </NavLink>
              <NavLink
                to="/subscription"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start`}
              >
                <img
                  className="w-[10%] max-h-[20px] lg:hidden"
                  src={subscription}
                />
                <p className="mx-6 text-[1vmax] lg:text-[13px]">
                  My Subscription
                </p>
              </NavLink>
              <Link
                to="/faq"
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start`}
              >
                <img
                  className="w-[10%] max-h-[20px] lg:hidden"
                  src={question}
                />
                <p className="mx-6 text-[1vmax] lg:text-[13px]">FAQ</p>
              </Link>
              <Link
                to="/"
                onClick={() => logout()}
                className={` text-[16px] h-[10%] rounded-[35px] flex items-center px-[10%] py-[7px] hover:bg-[#E5E5FE] hover:font-semibold cursor-pointer my-2 transition-all hover:text-black hover:no-underline active:no-underline active:text-black focus:no-underline focus:text-black lg:justify-start`}
              >
                <img
                  className="w-[10%] max-h-[20px] lg:hidden"
                  src={logoutSVG}
                />
                <p className="mx-6 text-[1vmax] lg:text-[13px]">Logout</p>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
