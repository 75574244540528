import "./App.css";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "./@common/auth/PrivateRoute";
import PublicRoute from "./@common/auth/PublicRoute";
import AuthRoutes from "./@common/helpers/AuthRoutes";
import AuthProvider from "./@common/auth/AuthProvider";
import configureStore from "./redux/store";
import axios from "axios";

const store = configureStore();

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <AuthProvider>
            <PublicRoute Routes={Routes} Route={Route} />
            <AuthRoutes>
              <PrivateRoute Routes={Routes} Route={Route} />
            </AuthRoutes>
          </AuthProvider>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
