import React from "react";
import "./home.css";

const TickMark = () => {
  return (
    <span>
      <img
        style={{ height: "15px", width: "15px", marginRight: "10px" }}
        src="/images/tick_Mark.png"
      />
    </span>
  );
};

export default TickMark;
