import React from "react";
import Sidebar from "../../components/Dashboard/Sidebar";
import { useState, useEffect } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import DashboardDivision from "../../components/Dashboard/DashboardDivision";
import runningMan from "../../assets/dashboard/runningMan.svg";
import greenTick from "../../assets/dashboard/greenTick.svg";
import purpleTick from "../../assets/dashboard/purpleTick.svg";
import blueTick from "../../assets/videos/blueTick.svg";
import { BiMenuAltLeft } from "react-icons/bi";
import logo from "../../assets/header/logo.png";
import { env } from "../../@common/helpers/EnvironmentConfig";
import { getUserName } from "../../@common/shared/LocalStorage/localstorage";
import TestModal from "./TestModal.js";
import axios from "axios";
import { AiOutlineArrowRight } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { render } from "@testing-library/react";

const Syllabus = () => {
  const [userName] = useState(getUserName());
  const [subjetList, setSubjectList] = useState(null);
  const [visible, setVisible] = useState("pending");
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [choosenSubject, setChoosenSubject] = useState("-- Select Subject --");
  const [testModal, setTestModal] = useState(false);
  const [testInfo, setTestInfo] = useState({});
  const [testInitiateResponse, setTestInitiateResponse] = useState({});
  const [totalQuestions, setTotalQuestions] = useState(0);
  const n = 3;
  const m = 2;
  let navigate = useNavigate();
  const [topic, setTopic] = useState(null);

  const [phoneSidebarStatus, setPhoneSidebarStatus] = useState(false);
  const [fullTest, setFullTest] = useState(false);
  const [fullTestList, setFullTestList] = useState(null);

  const dropDownOpen = {
    transitionDuration: "0.5s",
    position: "absolute",
    overflowX: " hidden",
    overflowY: "scroll",
    maxHeight: "40vh",
    height: "100%",
    background: "white",
    // background: "#fefaff",
    padding: "5px",
    borderRadius: "10px",
  };
  const dropDownClose = {
    transitionDuration: "0.3s",
    position: "absolute",
    overflowX: " hidden",
    overflowY: "hidden",
    maxHeight: "40vh",
    height: "0px",
    padding: "0px 5px",
    background: "white",
  };
  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };
  async function getSubjects() {
    const url = env.API_ENDPOINT_URL + `participant/test/get-subjects`;
    try {
      const resp = await axios.get(url, header_token);
      setSubjectList(resp.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  const notify = () => toast.warn("Max Test Limit reached!");

  useEffect(() => {
    getSubjects();

    const closeDropdowne = (e) => {
      if (e.target.id !== "subjectDropdown") {
        setIsDropDownOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdowne);
    return () => document.body.removeEventListener("click", closeDropdowne);
  }, []);

  async function getTopics(id, subjectName) {
    setChoosenSubject(subjectName);
    const api = env.API_ENDPOINT_URL + `participant/test/get-chapters/${id}`;
    try {
      const resp = await axios.get(api, header_token);
      setTopic(resp.data.data.chapter);
    } catch (err) {}
  }
  async function initiateOnlineTest(newTestInfo, choice) {
    // console.log(newTestInfo);
    let api;
    if (choice) {
      api = env.API_ENDPOINT_URL + "participant/test/initiate-full-test";
    } else {
      api = env.API_ENDPOINT_URL + "participant/test/initiate";
    }

    try {
      const resp = await axios.post(api, newTestInfo, header_token);
      setTestInitiateResponse(resp);

      setTotalQuestions(resp.data.data.max_questions);
      // if (choice) {
      //   console.log("clicked", choice);

      //   renderQuiz(resp.data.data.test_id);
      // }
    } catch (error) {
      // notify();
      if (error.response.status === 400) {
        notify();
      }
      console.log(error);
    }
  }

  const NavigateToTestExplanation = (testId) => {
    navigate("/testExplanation", {
      state: {
        testId: testId,
      },
    });
  };

  async function renderQuiz(data_id) {
    const id = data_id;
    navigate("/quiz", {
      state: {
        test_id: id,
        reading_stage: 1,
        current_question: 0,
        isFullTest: fullTest,
      },
    });
  }

  async function getFullTestList() {
    const api = env.API_ENDPOINT_URL + 'participant/test/mytest';
    try {
      const resp = await axios.post(api,'', header_token);
      setFullTestList(resp.data.data)
    } catch (err) {
      console.error(err); 
    }
  }
  
  useEffect(() => {
    getFullTestList();
  }, []);
  

  return (
    <React.StrictMode>
      <div className="h-2.5 custom-gradiant"></div>
      <div className="flex items-center flex-row-reverse p-10 bg-[#FAFAFA] w-[95%] m-auto rounded-[20px] my-10 justify-between minlg:hidden">
        <div
          onClick={() => setPhoneSidebarStatus(!phoneSidebarStatus)}
          className="w-16"
        >
          <BiMenuAltLeft className="w-[100%] h-[100%]" />
        </div>
        <img
          src={logo}
          alt="logo"
          className="max-w-custom-s max-h-custom-s overflow-hidden"
        />
      </div>
      <div className="flex flex-wrap not-italic font-Inter w-[95%] m-auto">
        <Sidebar userName={userName} status={phoneSidebarStatus} />

        <div className="w-3/4 flex flex-col mt-12 font-Inter non-italic min-h-[100vh] text-[#303030] px-4 py-2 border-none lg:mt-0 lg:w-[98%] lg:m-auto">
          <div className="flex flex-col mb-4">
            <h1 className="font-semibold  text-[28px] leading-[34px] mb-2">
              Tests
            </h1>
            <p className="font-light text-[14px] leading-[17px] mt-2 ">
              Take the Daily Test To Complete Your Syllabus.
            </p>
          </div>

          <div className="w-[100%] flex justify-start itemse-center mt-4">
            <div
              className={`${
                fullTest ? "bg-[#5D44DE] text-white" : "bg-[#E5E5FE]"
              } rounded-[5px] px-10 py-4 font-semibold text-[14px] leading-[17px] mr-2 hover:bg-[#5D44DE] hover:text-white  md:px-4 cursor-pointer`}
              onClick={() => {
                setFullTest(true);
              }}
            >
              Full Test
            </div>
            <div
              className={`${
                !fullTest ? "bg-[#5D44DE] text-white" : "bg-[#E5E5FE]"
              } rounded-[5px] px-10 py-4 font-semibold text-[14px] leading-[17px] mx-2 hover:bg-[#5D44DE] hover:text-white  md:px-4 cursor-pointer`}
              onClick={() => {
                setFullTest(false);
              }}
              // onClick={setFullTest(false)}
            >
              Subject Wise Test
            </div>
          </div>

          <DashboardDivision />

          {fullTest ? (
            <div>
              <h1 className="text-[24px] font-semibold leading-[19px] my-2">
                 Full Test
              </h1>
              <div className="flex w-[100%] justify-between items-center">
                <p className="text-[20px] leading-[19px] font-medium flex">
                  Want to give full syllabus test
                  <AiOutlineArrowRight className="ml-2" />
                </p>

                <button
                  className="bg-[#5D44DE] rounded-[8px] mx-10 p-4 px-6 text-white text-[14px] leading-[17px] font-medium hover:scale-110 hover:bg-[#FB9C45] duration-300 transition ease-in-out delay-150 lg:w-40"
                  onClick={() => {
                    const newTestInfo = {
                      chapterId: "null",
                      chapterName: "null",
                      subjectId: "null",
                      subjectName: "null",
                      is_fulltest: true,
                    };
                    setTestInfo(newTestInfo);
                    setTestModal(true);
                    initiateOnlineTest(newTestInfo, true);
                  }}
                >
                 Take Full test
                </button>
              </div>

              <div>
               
                <ul className="transition-all w-[100%] mt-6">
                  {fullTestList.map((test, index)=>(
                    <li
                    className={`flex w-[100%] justify-between px-2 py-6  rounded-[10px] mb-4 cursor-pointer`}
                  >
                    <div className="flex justify-start">
                      <div className="mx-6 ">
                        <img
                          style={{ width: "24px" }}
                          src={blueTick}
                          alt="tick"
                        />
                      </div>
                      <div className="flex flex-col">
                        <h1 className="text-[16px] leading-[19px] font-medium w-[100%]">
                          Full Test {index+1}
                        </h1>
                        <div className="flex  mt-1 w-[100%]"></div>
                      </div>
                    </div>
                    <div>
                      <button
                      onClick={()=>NavigateToTestExplanation(test.test_id)}
                        className={`bg-[#0ea150] ${"bg-[#5D44DE]"} rounded-[8px] mx-10 p-4 px-6 text-white text-[14px] leading-[17px] font-medium hover:scale-110 hover:bg-[#FB9C45] duration-300 transition ease-in-out delay-150 lg:w-40 `}
                      >
                        Explanation
                      </button>
                    </div>
                  </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <>
              <div onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
                <button
                  id="subjectDropdown"
                  className=" flex items-center rounded-[35px] border-2 px-2 py-2 border-[#E4E4E4] hover:border-[black] mt-4 mb-2"
                >
                  <span className=" -z-50 bg-[#5D44DE] text-white rounded-full w-12 h-12 flex items-center justify-center">
                    A
                  </span>
                  <p className="w-[120px] truncate -z-50 mx-4 text-lg  text-black font-semibold ">
                    {choosenSubject}
                  </p>

                  <span className="-z-50">
                    <RiArrowDropDownLine size="24px" />
                  </span>
                </button>

                <div style={isDropDownOpen ? dropDownOpen : dropDownClose}>
                  {subjetList
                    ? subjetList.map((e) => (
                        <button
                          onClick={() => getTopics(e.id, e.subjectName)}
                          className="w-[185px]   flex items-center rounded-[35px]  px-2 py-1 border-[#E4E4E4] hover:border-[black] mt-4 mb-6 "
                        >
                          <span className="bg-[#5D44DE] text-white rounded-full w-12 h-12 flex items-center justify-center ">
                            {e.subjectName.charAt(0)}
                          </span>
                          <p className="w-[150px] truncate mx-4 text-lg text-left text-black font-semibold ">
                            {e.subjectName}
                          </p>
                        </button>
                      ))
                    : null}
                </div>
                {/* Add dropdown after suggestion */}
              </div>

              <div className=" flex flex-col items-center py-4 pl-20 mr-4 lg:pl-0 lg:py-0">
                <h1 className="flex justify-start w-[100%] my-4 mt-14 font-medium text-[16px] text-black leading-[19px]">
                  {choosenSubject !== "-- Select Subject --"
                    ? choosenSubject
                    : ""}
                </h1>
                <ul className="transition-all w-[100%] mt-6">
                  {topic != null ? (
                    topic.map((item, idx) => (
                      <li
                        className={`flex w-[100%] justify-between px-2 py-6  rounded-[10px] mb-4 cursor-pointer`}
                      >
                        <div className="flex justify-start">
                          <div className="mx-6 ">
                            <img
                              style={{ width: "24px" }}
                              src={blueTick}
                              alt="tick"
                            />
                          </div>
                          <div className="flex flex-col ">
                            <h1 className="text-[16px] leading-[19px] font-medium w-[100%]">
                              {item.chapterName}
                            </h1>
                            <div className="flex  mt-1 w-[100%]"></div>
                          </div>
                        </div>
                        <div>
                          <button
                            className={`bg-[#5D44DE] ${"bg-[#5D44DE]"} rounded-[8px] mx-10 p-4 px-6 text-white text-[14px] leading-[17px] font-medium hover:scale-110 hover:bg-[#FB9C45] duration-300 transition ease-in-out delay-150 lg:w-40 `}
                            onClick={() => {
                              const newItem = topic.map((curr, index) => {
                                if (item === curr) {
                                  return { ...curr, testTaken: true };
                                } else {
                                  return curr;
                                }
                              });

                              const newTestInfo = {};
                              const name = item.chapterName;
                              const id = item._id;
                              newTestInfo["chapterName"] = name;
                              newTestInfo["chapterId"] = id;

                              subjetList.filter((subject, idx) => {
                                if (subject.subjectName === choosenSubject) {
                                  const name = subject.subjectName;
                                  const id = subject.id;
                                  newTestInfo["subjectName"] = name;
                                  newTestInfo["subjectId"] = id;
                                }
                              });
                              setTestInfo(newTestInfo);
                              setTestModal(true);
                              initiateOnlineTest(newTestInfo, false);
                            }}
                          >
                            {item.testTaken ? "Re-Take Test" : "Take Test"}
                          </button>
                        </div>
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </>
          )}
        </div>
        {testModal ? (
          <div className="absolute top-0 left-0 w-[100vw] h-[100%] bg-black z-20 opacity-50"></div>
        ) : (
          <></>
        )}
      </div>
      <TestModal
        open={testModal}
        setOpen={setTestModal}
        testInfo={testInfo}
        testInitiateResponse={testInitiateResponse}
        totalQuestions={totalQuestions}
        fullTest={fullTest}
        setFullTest={setFullTest}
      />
      <ToastContainer />
    </React.StrictMode>
  );
};

export default Syllabus;
