// ForJWT Auth
import { getUserFromJwtAuth } from "./AuthHelper";
import {
  useJWTAuth,
  useJWTAuthActions,
} from "../auth/AuthProvider";

export const useAuthUser = () => {
  const { user, isAuthenticated, isLoading } = useJWTAuth();
  return {
    isLoading,
    isAuthenticated,
    user: getUserFromJwtAuth(user),
  };
};

export const useAuthMethod = () => {
  const { signInUser, signUpUser, otpVerify, logout } = useJWTAuthActions();

  return {
    signInUser,
    logout,
    signUpUser,
    otpVerify
  };
};

