import React from "react";
import Sidebar from "../../components/Dashboard/Sidebar";
import { useState, useEffect } from "react";
import "./Notemaker.css";
import DashboardDivision from "../../components/Dashboard/DashboardDivision";
import logo from "../../assets/header/logo.png";
import { BiDownload, BiMenuAltLeft } from "react-icons/bi";
import { getUserName } from "../../@common/shared/LocalStorage/localstorage";
import { env } from "../../@common/helpers/EnvironmentConfig";
import axios from "axios";
import swal from "@sweetalert/with-react";
import { degrees, PDFDocument, rgb } from "pdf-lib";

const Notemaker = () => {
  const [userName] = useState(getUserName());
  const [visible, setVisible] = useState("pending");
  const [phoneSidebarStatus, setPhoneSidebarStatus] = useState(false);
  const [notes, setNotes] = useState([]);
  const [title, setTitle] = useState("");
  const [data, setData] = useState("");
  const [editNote, setEditNote] = useState(false);
  const [editId, setEditId] = useState(null);

  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const generatePDF = async (noteTitle, noteData) => {
    const pdfDoc = await PDFDocument.create();
    const pngUrl = "https://i.ibb.co/bP2dCRL/logo.png";
    const page = pdfDoc.addPage();
    const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(pngImageBytes);
    page.drawImage(pngImage, {
      x: 40,
      width: 120,
      height: 50,
      y: page.getHeight() - 70,
    });

    page.drawText(userName, {
      x: 450,
      y: page.getHeight() - 70,
      size: 9,
      color: rgb(0.1, 0.5, 0.1),
    });
    page.drawText(
      "These are user-created notes; they are not publications or academic material that prepBubble or Leasys Technologies ",
      {
        x: 40,
        y: 65,
        size: 8,
      }
    );
    page.drawText("Pvt Ltd published or endorse for academic study. ", {
      x: 40,
      y: 55,
      size: 8,
    });

    const underlineY = page.getHeight() - 85;
    page.drawText(noteTitle, {
      x: 40,
      y: page.getHeight() - 110,
      size: 16,
      color: rgb(0.25, 0.2, 0.22),
    });
    page.drawText(noteData, {
      x: 40,
      y: page.getHeight() - 135,
      size: 11,
      color: rgb(0.15, 0.2, 0.2),
    });
    const { width, height } = page.getSize();
    page.drawText("prepBubble", {
      x: 150,
      y: height / 2 + 100,
      size: 50,
      color: rgb(0.9, 0.8, 0.9),
      rotate: degrees(-45),
    });
    page.drawLine({
      start: { x: 40, y: 80 },
      end: { x: 500, y: 80 },
      thickness: 1,
      color: rgb(0.15, 0.2, 0.2),
      opacity: 0.75,
    });
    page.drawLine({
      start: { x: 40, y: page.getHeight() - 80 },
      end: { x: 500, y: page.getHeight() - 80 },
      thickness: 1,
      color: rgb(0.15, 0.2, 0.2),
      opacity: 0.75,
    });

    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${noteTitle}.pdf`;
    link.click();

    URL.revokeObjectURL(url);
  };

  async function getNotes() {
    const url = env.API_ENDPOINT_URL + `participant/notes/list`;
    try {
      const resp = await axios.get(url, header_token);
      setNotes(resp.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function addNote(event) {
    event.preventDefault();

    const noteData = {};
    noteData["note_title"] = event.target[0].value;
    noteData["note_data"] = event.target[1].value;

    setTitle("");
    setData("");

    if (editNote) {
      const url = env.API_ENDPOINT_URL + `participant/notes/update`;
      const config = {
        note_title: title,
        note_data: data,
        note_id: editId,
      };
      try {
        const resp = await axios.post(url, config, header_token);
        setEditNote(false);
        getNotes();
      } catch (err) {
        console.log(err);
      }
    } else {
      const url = env.API_ENDPOINT_URL + `participant/notes/create`;
      try {
        const resp = await axios.post(url, noteData, header_token);
        // console.log(resp);
        getNotes();
      } catch (err) {
        console.log(err);
      }
    }
  }
  useEffect(() => {
    getNotes();
  }, []);

  const showAlert = (id) => {
    swal({
      title: "Are you sure you want to delete?",
      text: "You can't retrive it back once it get deleted",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteNote(id);
      }
    });
  };
  async function deleteNote(id) {
    const newNotes = notes.filter((l) => l.id !== id);
    setNotes(newNotes);
    const url = env.API_ENDPOINT_URL + `participant/notes/delete`;
    const noteId = {
      note_id: id,
    };
    try {
      const resp = await axios.post(url, noteId, header_token);
    } catch (err) {
      console.log(err);
    }
  }
  function editNotes(id, title, data) {
    setTitle(title);
    setData(data);
    setEditId(id);
    setEditNote(true);
  }

  const Subject = [
    {
      "subjectName": "Ancient Indian History",
      "id": "6375b3e3313e4fed24c3568d"
    },
    {
      "subjectName": "Medieval Indian History",
      "id": "6375b3e9313e4fed24c35692"
    },
    {
      "subjectName": "Modern Indian History",
      "id": "6375b3ee313e4fed24c35697"
    },
    {
      "subjectName": "Indian Polity",
      "id": "6375b3f3313e4fed24c3569c"
    },
    {
      "subjectName": "Indian Economy",
      "id": "6375b3f9313e4fed24c356a1"
    },
    {
      "subjectName": "Environment and Ecology",
      "id": "6375b3fe313e4fed24c356a6"
    },
    {
      "subjectName": "Indian Geography",
      "id": "6375b40b313e4fed24c356ab"
    },
    {
      "subjectName": "World Geography",
      "id": "6375b413313e4fed24c356b0"
    },
    {
      "subjectName": "General Science",
      "id": "6375b41a313e4fed24c356b5"
    },
    {
      "subjectName": "Indian Art and Culture",
      "id": "6379104d18edad3c2d88fa1a"
    },
    {
      "subjectName": "Current Affairs",
      "id": "6381dacf18edad3c2d898323"
    },
    {
      "subjectName": "Previous Year Questions",
      "id": "638e3852218dab0f169d7850"
    }
  ]



  return (
    <>
      <div className="h-2.5 custom-gradiant"></div>
      {/* after completing content section remove height on this  */}

      <div className="flex items-center flex-row-reverse p-10 bg-[#FAFAFA] w-[95%] m-auto rounded-[20px] my-10 justify-between minlg:hidden">
        <div
          onClick={() => setPhoneSidebarStatus(!phoneSidebarStatus)}
          className="w-16"
        >
          <BiMenuAltLeft className="w-[100%] h-[100%]" />
        </div>
        <img
          src={logo}
          alt="logo"
          className="max-w-custom-s max-h-custom-s overflow-hidden"
        />
      </div>

      <div className="flex flex-wrap not-italic font-Inter w-[95%] m-auto">
        <Sidebar userName={userName} status={phoneSidebarStatus} />

        <div className="w-3/4  overflow-hidden flex flex-col mt-12 font-Inter non-italic text-[#303030] px-4 py-2 border-none lg:mt-0 lg:w-[98%] lg:m-auto ">
          <div className="flex flex-col mb-4">
            <h1 className="font-semibold  text-[28px] leading-[34px] mb-2">
              Note Maker
            </h1>
            <p className="font-light text-[14px] leading-[17px] mt-2">
              Create edit and download your notes
            </p>
          </div>
          <DashboardDivision />
          <form className="task-form" onSubmit={addNote}>
            <div className="task-input">
              <p>Subject</p>

              <select 
              id="subject"
              type="name"
              name="note_title"
              className="note_title"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              >
                <option>-- Choose Subject --</option>
                {Subject.map((subject)=>{
                 return <option> {subject.subjectName}</option>
                })}
              </select>
              
            </div>
            <div className="task-input">
              <p>Note Content</p>
              <textarea
                id="note"
                type="text"
                name="note_data"
                value={data}
                onChange={(event) => {
                  setData(event.target.value);
                }}
              ></textarea>
            </div>
            <button id="saveNote" onClick={""} type="submit">
              Save Note
            </button>
          </form>
          <br></br>
          <div className="status-button">
            <button className="saved-notes">Saved Notes</button>
          </div>
          <div className="notes-area">
            <div className="saved-notes-list1">
              {notes.map((item, idx) => (
                <div className="saved-notes-list">
                  <div className="note-img">
                    <img src="./images/note.svg"></img>
                  </div>
                  <div className="notes-content">
                    <h2 className="notes-heading">{item.note_title}</h2>
                    <p>{item.note_data}</p>
                  </div>
                  <div className="controls">
                    <button
                      onClick={() =>
                        editNotes(item.id, item.note_title, item.note_data)
                      }
                      className="notes-edit"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => showAlert(item.id)}
                      className="notes-delete"
                    >
                      Delete
                    </button>
                    <button
                      onClick={() =>
                        generatePDF(item.note_title, item.note_data)
                      }
                      className="notes-download"
                    >
                      <BiDownload />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notemaker;
