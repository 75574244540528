import React from "react";
import DashboardDivision from "../../components/Dashboard/DashboardDivision";
import { useState, useEffect } from "react";
import { getUserName } from "../../@common/shared/LocalStorage/localstorage";
import Sidebar from "../../components/Dashboard/Sidebar";
import { BiMenuAltLeft } from "react-icons/bi";
import logo from "../../assets/header/logo.png";
import runningMan from "../../assets/dashboard/runningMan.svg";
import "./Stats.css";
import info from "../../assets/dashboard/info.svg";
import LineGraph from "../ProductivityTracker/LineGraph";
import DonutGraph from "../ProductivityTracker/DonutGraph";
import "react-calendar/dist/Calendar.css";
import service from "../../@common/auth/FetchInterceptor";
import { env } from "../../@common/helpers/EnvironmentConfig";
import moment from "moment";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import DonutGraphNew from "../ProductivityTracker/DonutGraphNew";
import DonutGraphSubjects from "../../components/Stats/DonutGraphSubjects";
import AverageScoreGraph from "./AverageScoreGraph";
import SubjectDropdown from "../../components/Stats/SubjectDropdown";

ChartJS.register(ArcElement, Tooltip, Legend);

const Stats = () => {
  const [userName] = useState(getUserName());
  const [insideGraphOne, setInsideGraphOne] = useState([]);
  const [insideGraphTwo, setInsideGraphTwo] = useState([]);
  const [insideGraphTwoDataSet, setInsideGraphTwoDataSet] = useState([]);
  const [fullTest, setFullTest] = useState(true);
  const [strongSubjects, setStrongSubjects] = useState([]);
  const [strongSubjectsError, setStrongSubjectsError] = useState(false);
  const [strongSubjectsErrorMsg, setStrongSubjectsErrorMsg] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [subjectwiseAvgScore, setSubjectwiseAvgScore] = useState([]);
  const [readingGraph, setReadingGraph] = useState({
    graphDescription: "",
    graphData: [],
    dataSet: [],
  });
  const [readingGraph1, setReadingGraph1] = useState({
    graphDescription: "",
    graphData: [],
    dataSet: [],
  });
  const [readingGraph2, setReadingGraph2] = useState({
    graphDescription: "",
    graphData: [],
    dataSet: [],
  });
  const [readingGraph3, setReadingGraph3] = useState({
    graphDescription: "",
    graphData: [],
    dataSet: [],
  });
  const [phoneSidebarStatus, setPhoneSidebarStatus] = useState(false);
  const [roundGraphData, setRoundGraphData] = useState({
    total_questions: "",
    correct_questions: "",
    incorrect_questions: "",
    unattended_questions: "",
    total_t_attended: "",
    data_set: [],
    averageTimes: {},
  });
  const data1 = {
    // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        // label: "# of Votes",
        data: [12, 19, 3],
        backgroundColor: ["red", "#008ce0", "#00af41"],
        borderColor: ["red", "#008ce0", "#00af41"],
        borderWidth: 1,
      },
    ],
  };
  var options = {
    cutout: 80,
  };

  const [avgLearningTime, setAvgLearningTime] = useState([]);
  const [avgTimeData, setAvgTimeData] = useState([]);

  const [avgTestTime, setAvgTestTime] = useState([]);
  const [avgTestTimeData, setAvgTestTimeData] = useState([]);

  useEffect(() => {
    getActivityStatistics();
    getAverageLearningTime();
    getAverageTestTime();
    getStrongSubjects();
  }, []);

  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const getAverageTestTime = async () => {
    try {
      const resp = await service.post(
        // env.API_ENDPOINT_URL + "participant/tracker/average-test-time",
        env.API_ENDPOINT_URL + "participant/tracker/full-test-analytics",
        header_token
      );
      
      if (resp.success) {
        let arr1 = [];
        let arr2 = [];
        
        resp?.data.map((val) => {
          // const day = moment(val.createdAt).format("dddd").slice(0, 3);
          arr1.push(val.createdAt);
          arr2.push(parseFloat(Number(val.average_time/60).toFixed(1)));
        });
        arr1 = arr1.reverse();
        arr1 = arr1.slice(0, 50);
        arr2 = arr2.reverse();
        arr2 = arr2.slice(0, 50);
        setAvgTestTime(arr1);
        setAvgTestTimeData(arr2);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getSubjectWiseAverageScore = async () => {
    try {
      const resp = await service.post(
        env.API_ENDPOINT_URL + "participant/tracker/test-score-subject",
        { subject: selectedSubject, test_count: 5 },
        header_token
      );
      if (resp.success) {
        setSubjectwiseAvgScore(resp?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSubjectWiseAverageScore();
  }, [selectedSubject]);

  const getAverageLearningTime = async () => {
    try {
      const resp = await service.post(
        env.API_ENDPOINT_URL + "participant/tracker/full-test-analytics",
        // { type: "fullTest", subject: "Null" },
        header_token
      );
      if (resp.success) {
        let arr1 = [];
        let arr2 = [];
        let flag =0;
        resp?.data.map((val) => {
          if(flag<=50){
            flag+=1;
          // const day = moment(val.createdAt).format("dddd").slice(0, 3);
          const day = val.createdAt;
          arr1.push(day);

          arr2.push(
            Number(val.total_correct_answers * 2 - val.total_incorrect_answers)
          );}
        });
        arr1 = arr1.reverse();
        arr1 = arr1.slice(0, 50);
        arr2 = arr2.reverse();
        arr2 = arr2.slice(0, 50);
        setAvgLearningTime(arr1);
        setAvgTimeData(arr2);
       
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getStrongSubjects = async () => {
    try {
      const resp = await service.get(
        env.API_ENDPOINT_URL + "participant/subjects/strong-subjects",
        header_token
      );
      if (resp.success) {
        console.log(resp?.data);
        setStrongSubjects(resp?.data.data);
      }
    } catch (err) {
      setStrongSubjectsError(true);
      setStrongSubjectsErrorMsg(err?.response.data.message);
      // console.log(err?.response.data.message);
    }
  };

  const getActivityStatistics = async () => {
    try {
      const resp = await service.get(
        env.API_ENDPOINT_URL + "participant/tracker/test-report"
      );
      if (resp.success) {
        setRoundGraphData({
          ...roundGraphData,
          total_questions: resp?.data?.total_q_attended[0]?.total_attended,
          correct_questions: resp?.data?.totalCorrectQ[0]?.total_attended,
          incorrect_questions: resp?.data?.totalInCorrectQ[0]?.total_attended,
          unattended_questions: resp?.data?.totalunattQ[0]?.total_attended,
          total_no_q_attended: resp?.data?.total_q_attended[0]?.total_attended,
          total_t_attended: resp?.data?.total_t_attended,
          data_set: [
            resp?.data?.totalCorrectQ[0]?.total_attended,
            resp?.data?.totalInCorrectQ[0]?.total_attended,
            resp?.data?.totalunattQ[0]?.total_attended,
          ],
          averageTimes: resp?.data?.averageTimes,
        });
        setInsideGraphOne([
          `Correct Questions: ${resp?.data?.totalCorrectQ[0]?.total_attended}`,
          `Incorrect Questions: ${resp?.data?.totalInCorrectQ[0]?.total_attended}`,
          `Unattended Questions: ${resp?.data?.totalunattQ[0]?.total_attended}`,
        ]);
        let readArry = [];
        let readArryForGraph = [];
        resp?.data?.readingStage.map((val) => {
          if (val.reading_stage === 1) {
            readArry.push(`R1: ${val.total_attended}`);
            readArryForGraph.push(val.total_attended);
          } else if (val.reading_stage === 2) {
            readArry.push(`R2: ${val.total_attended}`);
            readArryForGraph.push(val.total_attended);
          } else if (val.reading_stage === 3) {
            readArry.push(`R3: ${val.total_attended}`);
            readArryForGraph.push(val.total_attended);
          } else if (val.reading_stage === 4) {
            readArry.push(`R4: ${val.total_attended}`);
            readArryForGraph.push(val.total_attended);
          }
        });
        setInsideGraphTwo(readArry);
        setInsideGraphTwoDataSet(readArryForGraph);

        resp?.data?.stageGraphValues.map((val) => {
          if (val.reading_stage === 1) {
            let data_set = [
              val.unattended,
              val.correct_answers,
              val.incorrect_answers,
            ];
            let data_Arr = [
              `Attended:${parseInt(val.correct_answers) + parseInt(val.incorrect_answers)}`,
              `Correct: ${val.correct_answers}`,
              `Incorrect: ${val.incorrect_answers}`,
            ];
            setReadingGraph({
              ...readingGraph,
              graphDescription: "R1 Analytics",
              graphData: data_Arr,
              dataSet: data_set,
            });
          }
          if (val.reading_stage === 2) {
            let data_set = [
              val.unattended,
              val.correct_answers,
              val.incorrect_answers,
            ];
            let data_Arr = [
              `Attended:${parseInt(val.correct_answers) + parseInt(val.incorrect_answers)}`,
              `Correct: ${val.correct_answers}`,
              `Incorrect: ${val.incorrect_answers}`,
            ];
            let obj = {
              graphDescription: "R2 Analytics",
              graphData: data_Arr,
              dataSet: data_set,
            };
            setReadingGraph1({
              ...readingGraph,
              graphDescription: "R2 Analytics",
              graphData: data_Arr,
              dataSet: data_set,
            });
          }
          if (val.reading_stage == 3) {
            let data_set = [
              val.unattended,
              val.correct_answers,
              val.incorrect_answers,
            ];
            let data_Arr = [
              `Attended:${parseInt(val.correct_answers) + parseInt(val.incorrect_answers)}`,
              `Correct: ${val.correct_answers}`,
              `Incorrect: ${val.incorrect_answers}`,
            ];
            let obj = {
              graphDescription: "R3 Analytics",
              graphData: data_Arr,
              dataSet: data_set,
            };
            setReadingGraph2({
              ...readingGraph,
              graphDescription: "R3 Analytics",
              graphData: data_Arr,
              dataSet: data_set,
            });
          }
          if (val.reading_stage == 4) {
            let data_set = [
            val.unattended,
              val.correct_answers,
              val.incorrect_answers,
            ];
            let data_Arr = [
              `Attended:${parseInt(val.correct_answers) + parseInt(val.incorrect_answers)}`,
              `Correct: ${val.correct_answers}`,
              `Incorrect: ${val.incorrect_answers}`,
            ];
            let obj = {
              graphDescription: "R4 Analytics",
              graphData: data_Arr,
              dataSet: data_set,
            };
            setReadingGraph3({
              ...readingGraph,
              graphDescription: "R4 Analytics",
              graphData: data_Arr,
              dataSet: data_set,
            });
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="flex items-center flex-row-reverse p-10 bg-[#FAFAFA] w-[95%] m-auto rounded-[20px] my-10 justify-between minlg:hidden">
        <div
          onClick={() => setPhoneSidebarStatus(!phoneSidebarStatus)}
          className="w-16"
        >
          <BiMenuAltLeft className="w-[100%] h-[100%]" />
        </div>
        <img
          src={logo}
          alt="logo"
          className="max-w-custom-s max-h-custom-s overflow-hidden"
        />
      </div>

      <div className="flex flex-wrap not-italic font-Inter w-[95%] m-auto">
        <Sidebar userName={userName} status={phoneSidebarStatus} />

        <div className="w-3/4  overflow-hidden flex flex-col mt-12 font-Inter non-italic text-[#303030] px-4 py-2 border-none lg:mt-0 lg:w-[98%] lg:m-auto ">
          <div className="flex flex-col mb-4">
            <h1 className="font-semibold  text-[28px] leading-[34px] mb-2">
              My Analytics
            </h1>
            <p className="font-light text-[14px] leading-[17px] mt-2">
              {/* Your Score and details are below */}
              Evaluate your UPSC preparation based on the tests taken
            </p>
          </div>
          <br />
          {/* <div className="flex md:flex-col">
            <div className="flex mx-4">
              <div className=" flex bg-[#E9F5FE] rounded-full mx-4">
                <img className="rotate-image" src={runningMan} alt="man" />
              </div>
              <div className=" mx-4 flex flex-col items-center">
                <span className="mt-4 font-medium text-black ">
                  Your Doing Great! Keep Going
                </span>
                <p className="font-light text-[#303030] text-[12px] flex justify-start w-[100%]">
                  Last 5 Days Completion Rate
                </p>
              </div>
            </div>
            <div className="flex ml-[30%] items-center md:hidden lg:ml-[20%]">
              <div className="box-date">
                <div>
                  <h1>{String(new Date().getDate()).padStart(2, "0")}</h1>
                  <p>APR</p> {/*need to update by hashmap*/}
          {/* </div>
                <div>
                  <p>Today</p>
                  <img src={info}></img>
                </div>
              </div> */}
          {/* </div> */}
          {/* </div> */}
          <DashboardDivision />
          <div className="w-[100%] flex justify-start itemse-center">
            <div
              className={`${
                fullTest ? "bg-[#5D44DE] text-white" : "bg-[#E5E5FE]"
              } rounded-[5px] px-10 py-4 font-semibold text-[14px] leading-[17px] mr-2 hover:bg-[#5D44DE] hover:text-white  md:px-4 cursor-pointer`}
              onClick={() => {
                setFullTest(true);
              }}
            >
              Full Test Analytics
            </div>
            <div
              className={`${
                !fullTest ? "bg-[#5D44DE] text-white" : "bg-[#E5E5FE]"
              } rounded-[5px] px-10 py-4 font-semibold text-[14px] leading-[17px] mx-2 hover:bg-[#5D44DE] hover:text-white  md:px-4 cursor-pointer`}
              onClick={() => {
                setFullTest(false);
              }}
              // onClick={setFullTest(false)}
            >
              Subject Wise Analytics
            </div>
          </div>
          {fullTest ? (
            <>
              <div className="flex mt-16 justify-between items-center md:flex-col">
                <div className="flex flex-col md:text-center ">
                  <h1 className="font-semibold text-[22px] leading-[27px]">
                    Full Test Analytics
                  </h1>
                  <h2 className="font-medium text-[16px] leading-[19px] my-4">
                    Average Score{" "}
                  </h2>
                  <p className="font-normal text-[12px] leading-[17px] text-[#646060] my-2">
                    This graph displays your overall average score for all tests
                    taken on a given day.
                  </p>
                </div>
              </div>
              <div className="flex justify-around">
                <LineGraph
                  graphDescription="Average Score"
                  avgLearningTime={avgLearningTime}
                  avgTimeData={avgTimeData}
                  title={"Average test Score"}
                  yAxis={"Average Test Score"}
                />

                {/* <div className="flex flex-col items-start px-2 py-2 bg-[#FAFAFA] rounded-[20px] justify-center w-1/3 max-h-[200px]">
                  <h1 className="text-[#303030] text-[20px] leading-[32px] font-bold my-2 mx-4">
                    Average Score
                  </h1>

                  <p className="text-[#303030] text-[16px] leading-[19px] font-normal my-2 mx-4">
                    Total Time spent on test :{" "}
                    {roundGraphData?.averageTimes?.total} hrs
                  </p>
                  <p className="text-[#303030] text-[16px] leading-[19px] font-normal my-2 mx-4">
                    Average Time per Question :{" "}
                    {Math.round(roundGraphData?.averageTimes?.average * 100) /
                      100}{" "}
                    sec
                  </p>
          </div> */}
              </div>
              <DashboardDivision />
              <div className="flex mt-2 justify-between items-center md:flex-col">
                <div className="flex flex-col md:text-center ">
                  <h2 className="font-medium text-[16px] leading-[19px] my-4">
                    Average Test Time{" "}
                  </h2>
                  <p className="font-normal text-[12px] leading-[17px] text-[#646060] my-2">
                  This graph shows your overall average test completion time for all tests you took on a certain day.
                  </p>
                </div>
              </div>
              <div className="flex justify-around">
                <LineGraph
                  graphDescription="Test Time Trend"
                  avgLearningTime={avgTestTime}
                  avgTimeData={avgTestTimeData}
                  title={"Average Test Time"}
                  yAxis={"Time in minute"}
                />
                {/*<div className="flex flex-col items-start px-2 py-2 bg-[#FAFAFA] rounded-[20px] justify-center w-1/3 max-h-[200px]">
                  <h1 className="text-[#303030] text-[20px] leading-[32px] font-bold my-2 mx-4">
                    Average Test Time
                  </h1>

                  <p className="text-[#303030] text-[16px] leading-[19px] font-normal my-2 mx-4">
                    Total Time spent on test :{" "}
                    {roundGraphData?.averageTimes?.total} hrs
                  </p>
                  <p className="text-[#303030] text-[16px] leading-[19px] font-normal my-2 mx-4">
                    Average Time per Question :{" "}
                    {Math.round(roundGraphData?.averageTimes?.average * 100) /
                      100}{" "}
                    sec
                  </p>
          </div> */}
              </div>
              <DashboardDivision />

              <div className="flex flex-col justify-center">
                <div className="flex flex-col md:text-center">
                  <h2 className="font-medium text-[16px] leading-[19px] my-4">
                    Strong Subjects
                  </h2>
                  <p className="font-normal text-[12px] leading-[17px] text-[#646060] my-2">
                    Students can analyze their strong and weak subject based R1
                    scores from their subject wise tests
                  </p>
                </div>
                <div className="flex justify-around">
                  {strongSubjectsError ? (
                    <div className="w-[100%] flex items-center justify-center p-4">
                      <p className="text-[16px] leading-[19px] font-semibold text-center">
                        !{strongSubjectsErrorMsg}
                      </p>
                    </div>
                  ) : (
                    <>
                      <DonutGraphSubjects strongSubjects={strongSubjects} />
                      <div className="flex flex-col items-start px-2 py-2 bg-[#FAFAFA] rounded-[20px] justify-center w-1/3 max-h-[200px]">
                        <h1 className="text-[#303030] text-[20px] leading-[32px] font-bold my-2 mx-4">
                          Strong subjects
                        </h1>

                        <p className="text-[#303030] text-[16px] leading-[19px] font-normal my-2 mx-4">
                          Total Time spent on test :{" "}
                          {roundGraphData?.averageTimes?.total} hrs
                        </p>
                        <p className="text-[#303030] text-[16px] leading-[19px] font-normal my-2 mx-4">
                          Average Time per Question :{" "}
                          {Math.round(
                            roundGraphData?.averageTimes?.average * 100
                          ) / 100}{" "}
                          sec
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex my-4">
                <SubjectDropdown setSelectedSubject={setSelectedSubject} />
              </div>

              {/* THIS IS SUBJECT WISE ANALYTICS */}
              {/* <DashboardDivision /> */}

              {/* <div className="flex mt-4 justify-between items-start md:flex-col flex-col">
                <div className="flex flex-col md:text-center ">
                  <h1 className="font-semibold text-[22px] leading-[27px]">
                    Subject Wise Analytics
                  </h1>
                  <h2 className="font-medium text-[16px] leading-[19px] my-4">
                    Average Learning Time{" "}
                  </h2>
                  <p className="font-normal text-[12px] leading-[17px] text-[#646060] my-2">
                    This chart shows how much time you worked o average on a
                    given day of the week for the selected subject
                  </p>
                </div>
              </div>
              <div className="bg-white flex flex-col justify-center">
                <h1 className="my-2 text-[14px] leading-[17px] font-medium">
                  Average Time worked by day of the week
                </h1>
                <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
                  <div
                    className="h-[37px] w-[10%] bg-[#9449ae] transition-all duration-500 "
                   
                  ></div>
                  <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                    Monday
                  </p>
                  <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                    0h 0m
                  </p>
                </div>
                <div className="flex justify-between bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
                  <div className="h-[37px] w-[20%]  bg-[#9449ae] transition-all duration-500 "></div>
                  <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                    Tuesday
                  </p>
                  <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                    0h 0m
                  </p>
                </div>
                <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
                  <div className="h-[37px] w-[0%] bg-[#9449ae] transition-all duration-500 "></div>
                  <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                    Wednesday
                  </p>
                  <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                    0h 0m
                  </p>
                </div>
                <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
                  <div className="h-[37px] w-[0%] bg-[#9449ae] transition-all duration-500 "></div>
                  <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                    Thursday
                  </p>
                  <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                    0h 0m
                  </p>
                </div>
                <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
                  <div
                    className="h-[37px] w-[0%] bg-[#9449ae] transition-all duration-500 "
                    // onLoad={}
                  ></div>
                  <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                    Friday
                  </p>
                  <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                    0h 0m
                  </p>
                </div>
                <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden">
                  <div className="h-[37px] w-[5%] bg-[#9449ae] transition-all duration-500 "></div>
                  <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                    Saturday
                  </p>
                  <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                    0h 0m
                  </p>
                </div>
                <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
                  <div className="h-[37px] w-[20%] bg-[#9449ae] transition-all duration-500 "></div>
                  <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                    Sunday
                  </p>
                  <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                    0h 0m
                  </p>
                </div>
              </div> */}
            </>
          )}

          <DashboardDivision />
          {fullTest ? (
            <></>
          ) : (
            <>
              <div className="flex flex-col md:text-center my-4">
                <h1 className="font-medium text-[16px] leading-[19px] my-4">
                  Average Score
                </h1>
                <p className="font-normal text-[12px] leading-[17px] text-[#646060] my-2">
                  {" "}
                  Based on correct vs. incorrect answers from your
                  subject-specific test, this graph displays your performance on
                  the last five tests.
                </p>
                <AverageScoreGraph subjectwiseAvgScore={subjectwiseAvgScore} />
              </div>
              <DashboardDivision />
              <h1 className="font-semibold text-[22px] leading-[27px] mt-[30px]">
                Overall Test Analytics
              </h1>
              <h2 className="font-medium text-[16px] leading-[19px] my-4">
                Combined data of full test and subjectwise test
              </h2>
              <div className="flex  p-4 lg:flex-col">
                <div className="flex flex-col items-start px-[20px] py-2 bg-[#FAFAFA] rounded-[20px] justify-center w-1/3 lg:w-[100%]">
                  <h1 className="text-[#303030] text-[20px] leading-[32px] font-bold my-2 ">
                    Overall Test Analytics
                  </h1>
                  <p className="text-[#303030] text-[16px] leading-[19px] font-normal my-2">
                    Total No. of Questions attended :{" "}
                    {roundGraphData?.total_questions}
                  </p>
                  <p className="text-[#303030] text-[16px] leading-[19px] font-normal my-2 ">
                    Total No. of Tests attended :{" "}
                    {roundGraphData?.total_t_attended}
                  </p>
                </div>
                <div className="lg:w-[100%] md:flex-col flex md:justify-center items-center p-0 lg:my-4 justify-between w-1/2">
                  <div className="lg:w-1/2 lg:px-4 w-1/2 relative h-[100%] mx-16 lg:mr-2 lg:mx-0 md:w-[100%]">
                    <DonutGraph
                      graphDescription="Overall Test Performance"
                      graphData={insideGraphOne}
                      dataSet={roundGraphData?.data_set}
                    />
                  </div>

                  <div className="lg:w-1/2 lg:px-4 w-1/2 relative h-[100%] mx-12 lg:ml-2 lg:mx-0 md:w-[100%]">
                    <DonutGraph
                      graphDescription="Four Stage reading Analytics "
                      graphData={insideGraphTwo}
                      dataSet={insideGraphTwoDataSet}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between  p-4 w-[100%] flex-wrap">
                <div className="w-1/5 lg:w-1/2 md:w-[100%] lg:px-2">
                  <DonutGraphNew
                    graphDescription={readingGraph?.graphDescription}
                    graphData={readingGraph?.graphData}
                    dataSet={readingGraph?.dataSet}
                  />
                </div>

                <div className="w-1/5 lg:w-1/2 md:w-[100%] lg:px-2">
                  <DonutGraphNew
                    graphDescription={readingGraph1?.graphDescription}
                    graphData={readingGraph1?.graphData}
                    dataSet={readingGraph1?.dataSet}
                  />
                </div>

                <div className="w-1/5 lg:w-1/2 md:w-[100%] lg:px-2">
                  <DonutGraphNew
                    graphDescription={readingGraph2?.graphDescription}
                    graphData={readingGraph2?.graphData}
                    dataSet={readingGraph2?.dataSet}
                  />
                </div>

                <div className="w-1/5 lg:w-1/2 md:w-[100%] lg:px-2">
                  <DonutGraphNew
                    graphDescription={readingGraph3?.graphDescription}
                    graphData={readingGraph3?.graphData}
                    dataSet={readingGraph3?.dataSet}
                  />
                </div>
              </div>
              <DashboardDivision />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Stats;
