import React from "react";
import Dashboard from "../../pages/Dashboard/Dashboard.js";
import Quiz from "../../pages/Quiz/Quiz";
import Test from "../../pages/TestPage/Test";
import Notemaker from "../../pages/NoteMaker/Notemaker";
import Challenge from "../../pages/Challenge/Challenge";
import Profile from "../../pages/profile/Profile.js";
import ProductivityTracker from "../../pages/ProductivityTracker/ProductivityTracker.js";
import Aspirantcoins from "../../pages/AspirantCoins/Aspirantcoins";
import FAQ from "../../pages/FAQ/FAQ";
// import Syllabus from "../../pages/Syllabus/Syllabus"
import Stats from "../../pages/Stats/Stats";
import Subscription from "../../pages/Subscription/Subscription.js";
import {TestExplanation} from "../../pages/TestExplanation/TestExplanation.js";

const PrivateRoute = ({ Routes, Route }) => {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />}></Route>
      {/*<Route path="/syllabus" element={<Syllabus />}></Route> */}
      <Route path="/quiz" element={<Quiz />}></Route>
      <Route path="/test" element={<Test />}></Route>
      <Route path="/notes" element={<Notemaker />}></Route>
      <Route path="/challenge" element={<Challenge />}></Route>
      <Route path="/profile" element={<Profile />}></Route>
      <Route path="/productivity" element={<ProductivityTracker />}></Route>
      <Route path="/aspirantcoins" element={<Aspirantcoins />}></Route>
      <Route path="/stats" element={<Stats />}></Route>
      <Route path="/subscription" element={<Subscription />}></Route>
      <Route path="/testExplanation" element={<TestExplanation />}></Route>
      {/* <Route path="/mySubStription" element={<Aspirantcoins />}></Route> */}
      {/* <Route path="/stats" element={<Stats />}></Route> */}
    </Routes>
  );
};

export default PrivateRoute;
