import React from "react";
import { Doughnut } from "react-chartjs-2";
const DonutGraph = ({ graphDescription, graphData, dataSet }) => {
  const data = {
    // labels: ['Total Questions','Correct','Incorrect','Unattended'],
    datasets: [
      {
        // label: "progress",
        data: dataSet,
        backgroundColor: ["#FF9900", "#9648AD", "#177E89", "#DB3A34"],
        borderColor: ["#FF9900", "#9648AD", "#177E89", "#DB3A34"],
        borderWidth: 1,
      },
    ],
  };

  var options = {
    cutout: '80%',
  };
  return (
    <div className="flex justify-center items-center flex-col w-[100%] relative mx-4 lg:w-[100%] md:m-auto md:my-4">
      <Doughnut data={data} options={options} />
      <p className="mt-4">{graphDescription}</p>
      <div className="flex flex-col justify-center text-left items-center absolute top-[30%]">
        {graphData.map((item, i) => (
          <p key={i}>{item}</p>
        ))}

      </div>
    </div>
  );
};

export default DonutGraph;
