import React from "react";
import DashboardDivision from "../../components/Dashboard/DashboardDivision.js";
import whiteLogo from "../../assets/dashboard/whiteLogo.svg";
import coin from "../../assets/dashboard/Coin.svg";
import Slider from "react-slick";
import Sidebar from "../../components/Dashboard/Sidebar.js";
import { BiMenuAltLeft } from "react-icons/bi";
import { getUserName } from "../../@common/shared/LocalStorage/localstorage.js";
import logo from "../../assets/header/logo.png";
import { useState } from "react";
const Aspirantcoins = () => {
  var settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [userName] = useState(getUserName());
  const [phoneSidebarStatus, setPhoneSidebarStatus] = useState(false);
  return (
    <>
      <div className="h-2.5 custom-gradiant"></div>
      {/* after completing content section remove height on this  */}
      {/* after completing content section remove height on this  */}
      <div className="flex items-center flex-row-reverse p-10 bg-[#FAFAFA] w-[95%] m-auto rounded-[20px] my-10 justify-between minlg:hidden">
        <div
          onClick={() => setPhoneSidebarStatus(!phoneSidebarStatus)}
          className="w-16"
        >
          <BiMenuAltLeft className="w-[100%] h-[100%]" />
        </div>
        <img
          src={logo}
          alt="logo"
          className="max-w-custom-s max-h-custom-s overflow-hidden"
        />
      </div>
      <div className="flex  not-italic font-Inter w-[95%] m-auto lg:flex-col ">
        <Sidebar userName={userName} status={phoneSidebarStatus} />
        <div className="w-3/4  overflow-hidden flex flex-col mt-12 font-Inter non-italic text-[#303030] px-4 py-2 border-none lg:mt-0 lg:w-[98%] lg:m-auto">
          <div className="flex flex-col mb-4">
            <h1 className="font-semibold  text-[28px] leading-[34px] mb-2">
              prepBubble Coins
            </h1>
            <p className="font-light text-[14px] leading-[17px] mt-2">
              Complete your daily tasks to fastrack your preparation.
            </p>
          </div>

          <DashboardDivision />

          <div className="flex overflow-hidden mt-6 sm:flex-col sm:items-center">
            <div className="bg-[#5D44DE] w-1/3 mr-14 rounded-2xl flex flex-col items-center py-10 border-none sm:w-[80%] sm:mb-12 sm:mx-0">
              <img src={whiteLogo} alt="" className="my-6 sm:my-4" />
              <img
                src={coin}
                alt="coin"
                className="my-6 max-w-[84px] max-h-[84px]"
              />
              <p className="font-semibold text-[14px] text-white leading-[17px] my-2">
                Rewards
              </p>
              <div className="text-white flex flex-col items-center my-6">
                <p className="font-semibold text-[32px] leading-[39px]">
                  2,500
                </p>
                <p className="font-medium text-[16px] leading-[19px]">
                  prepBubble Reward Points
                </p>
              </div>

              <div className="flex w-[110%] items-center">
                <div className="bg-white rounded-full w-[34px] h-[34px]"></div>
                <div className=" border border-white border-dashed h-[0.5px] my-10 bg-transparent w-[85%] mx-2"></div>
                <div className="bg-white rounded-full w-[34px] h-[34px]"></div>
              </div>

              <div className="text-[#FFFFFF] px-20 py-10 flex justify-center text-center sm:px-10">
                <p className="text-[12px] leading-[17px] max-w-[226px font-normal]">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam
                </p>
              </div>
            </div>
            <div className="w-2/3 flex flex-col pl-6 sm:w-[100%] sm:mt-8 sm:text-center sm:px-3">
              <h1 className="font-medium text-[18px] leading-[19px] mb-6">
                Instructions
              </h1>

              <div className="mb-12">
                <h2 className="my-4 font-semibold text-[16px] leading-[17px]">
                  How to use coins
                </h2>
                <p className="font-light text-[14px] leading-[19px]">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur
                </p>
              </div>
              <div className="mb-12">
                <h2 className="my-4 font-semibold text-[16px] leading-[17px]">
                  How to Get More Coins
                </h2>
                <p className="font-light text-[14px] leading-[19px]">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae <br /> <br /> vitae dicta sunt explicabo. Nemo enim
                  ipsam voluptatem quia voluptas sit aspernatur aut odit aut
                  fugit, sed quia consequuntur
                </p>
              </div>
              <div className="mb-12">
                <h2 className="my-4 font-semibold text-[16px] leading-[17px]">
                  Is it Possible to convert coin
                </h2>
                <p className="font-light text-[14px] leading-[19px]">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem
                  quia voluptas sit aspernatur aut odit aut fugit, sed quia
                  consequuntur
                </p>
              </div>
            </div>
          </div>

          <div className="mt-20 font-Inter non-italic mb-20 ">
            <h1 className="mb-10 font-semibold text-[16px] leading-[19px]">
              Get More Coins
            </h1>
            <Slider {...settings}>
              <div className="bg-[#E5E5FE] rounded-[20px] px-16  py-10 text-left max-w-[341px] text-[#303030]">
                <div className="flex flex-col my-6 ">
                  <h1 className="font-semibold text-[32px] leading-[39px] ">
                    599
                  </h1>
                  <p className="font-medium text-[16px] leading-[19px]">
                    Reward Points
                  </p>
                </div>
                <h1 className="font-semibold text-[22px] mt-10 mb-4  leading-[27px]">
                  Share with Friends
                </h1>
                <p className="font-light text-[14px] leading-[17px] mb-8">
                  Your daily dose of current affairs test served hot. Attempt
                  these{" "}
                </p>
                <button className="bg-[#5D44DE] text-white text-center text-[14px] font-semibold leading-[17px] px-8 py-8 max-w-[260px] rounded-[5px] w-[100%] my-6 hover:scale-110 transition-all">
                  Share Now
                </button>
              </div>
              <div className="bg-[#E9F5FE] rounded-[20px] px-16  py-10 text-left max-w-[341px] text-[#303030]">
                <div className="flex flex-col my-6 ">
                  <h1 className="font-semibold text-[16px] leading-[39px] ">
                    {" "}
                    <span className="mr-2 font-semibold text-[32px] leading-[39px]">
                      999
                    </span>
                    /signup{" "}
                  </h1>
                  <p className="font-medium text-[16px] leading-[19px]">
                    Reward Points
                  </p>
                </div>
                <h1 className="font-semibold text-[22px] mt-10 mb-4  leading-[27px]">
                  Referal program
                </h1>
                <p className="font-light text-[14px] leading-[17px] mb-8">
                  Your daily dose of current affairs test served hot. Attempt
                  these{" "}
                </p>
                <button className="bg-[#24AEDA] text-white text-center text-[14px] font-semibold leading-[17px] px-8 py-8 max-w-[260px] rounded-[5px] w-[100%] my-6 hover:scale-110 transition-all">
                  Create a Referal Link
                </button>
              </div>
              <div className="bg-[#FFE5DD] rounded-[20px] px-16  py-10 text-left max-w-[341px] text-[#303030]">
                <div className="flex flex-col my-6 ">
                  <h1 className="font-semibold text-[16px] leading-[39px] ">
                    {" "}
                    <span className="mr-2 font-semibold text-[32px] leading-[39px]">
                      100
                    </span>
                    /Sylabus{" "}
                  </h1>
                  <p className="font-medium text-[16px] leading-[19px]">
                    Reward Points
                  </p>
                </div>
                <h1 className="font-semibold text-[22px] mt-10 mb-4  leading-[27px]">
                  Complete Sylabus
                </h1>
                <p className="font-light text-[14px] leading-[17px] mb-8">
                  Your daily dose of current affairs test served hot. Attempt
                  these{" "}
                </p>
                <button className="bg-[#FB9C45] text-white text-center text-[14px] font-semibold leading-[17px] px-8 py-8 max-w-[260px] rounded-[5px] w-[100%] my-6 hover:scale-110 transition-all">
                  Complete Now
                </button>
              </div>
              <div className="bg-[#E5E5FE] rounded-[20px] px-16  py-10 text-left max-w-[341px] text-[#303030]">
                <div className="flex flex-col my-6 ">
                  <h1 className="font-semibold text-[32px] leading-[39px] ">
                    599
                  </h1>
                  <p className="font-medium text-[16px] leading-[19px]">
                    Reward Points
                  </p>
                </div>
                <h1 className="font-semibold text-[22px] mt-10 mb-4  leading-[27px]">
                  Share with Friends
                </h1>
                <p className="font-light text-[14px] leading-[17px] mb-8">
                  Your daily dose of current affairs test served hot. Attempt
                  these{" "}
                </p>
                <button className="bg-[#5D44DE] text-white text-center text-[14px] font-semibold leading-[17px] px-8 py-8 max-w-[260px] rounded-[5px] w-[100%] my-6 hover:scale-110 transition-all">
                  Share Now
                </button>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aspirantcoins;
