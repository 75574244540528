import React from 'react';
import PropTypes from 'prop-types';
import { useAuthUser } from './AuthHooks';
import ScaleLoader from "react-spinners/ScaleLoader";

const AuthRoutes = ({ children }) => {
  const { isLoading } = useAuthUser();
  return isLoading ? <ScaleLoader
    color="rgb(93 68 222)"
    height={24}
    margin={4}
    radius={0}
    width={15}
    loading={true}
    className='loader'
  /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
