import React from "react";
import { useState, useEffect } from "react";
import { env } from "../../@common/helpers/EnvironmentConfig";
import axios from "axios";
import { RiArrowDropDownLine } from "react-icons/ri";

const SubjectDropdown = ({ setSelectedSubject }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const dropDownOpen = {
    transitionDuration: "0.5s",
    position: "absolute",
    overflowX: " hidden",
    overflowY: "scroll",
    maxHeight: "40vh",
    "z-index": "10",
    height: "100%",
    background: "#fefaff",
    padding: "5px",
    borderRadius: "10px",
  };
  const dropDownClose = {
    transitionDuration: "0.5s",
    position: "absolute",
    overflowX: " hidden",
    overflowY: "hidden",
    maxHeight: "40vh",
    "z-index": "10",
    height: "0px",
    padding: "0px 5px",
    background: "#fefaff",
  };
  const [subjetList, setSubjectList] = useState(null);
  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [choosenSubject, setChoosenSubject] = useState("-- Select Subject --");

  async function getSubjects() {
    const url = env.API_ENDPOINT_URL + `participant/test/get-subjects`;
    try {
      const resp = await axios.get(url, header_token);
      setSubjectList(resp.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getSubjects();
  }, []);

  return (
    <>
      <div onClick={() => setIsDropDownOpen(!isDropDownOpen)} className="lg:w-[100%]">
        <button
          id="subjectDropdown"
          className=" flex items-center rounded-[35px] border-2 px-2 py-2 border-[#E4E4E4] hover:border-[black] mt-4 mb-2 lg:w-[100%] lg:justify-between"
        >
          <span className=" -z-50 bg-[#5D44DE] text-white rounded-full w-12 h-12 flex items-center justify-center">
            A
          </span>
          <p className="w-[120px] truncate -z-50 mx-4 text-lg  text-black font-semibold ">
            {choosenSubject}
          </p>

          <span className="-z-50 ">
            <RiArrowDropDownLine size="24px" />
          </span>
        </button>
        <div style={isDropDownOpen ? dropDownOpen : dropDownClose} className="lg:w-[85%]">
          {subjetList
            ? subjetList.map((e) => (
                <button
                  onClick={() => {
                    setChoosenSubject(e.subjectName);
                    setSelectedSubject(e.subjectName);
                  }}
                  className="w-[185px]   flex items-center rounded-[35px]  px-2 py-1 border-[#E4E4E4] hover:border-[black] mt-0 mb-2 "
                >
                  <span className="bg-[#5D44DE] text-white rounded-full w-12 h-12 flex items-center justify-center ">
                    {e.subjectName.charAt(0)}
                  </span>
                  <p className="w-[150px] truncate mx-4 text-lg text-left text-black font-semibold ">
                    {e.subjectName}
                  </p>
                </button>
              ))
            : null}
        </div>
        {/* Add dropdown after suggestion */}
      </div>
    </>
  );
};

export default SubjectDropdown;
