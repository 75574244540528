import React, { useContext } from "react";
import Sidebar from "../../components/Dashboard/Sidebar.js";
import Content from "../../components/Dashboard/Content.js";
import { useState, useEffect } from "react";
import "./dashboard.css";
import DashboardHomeModal from "./DashboardHomeModal.js";
import { BiMenuAltLeft } from "react-icons/bi";
import logo from "../../assets/header/logo.png";
import { getUserName } from "../../@common/shared/LocalStorage/localstorage.js";
import { env } from "../../@common/helpers/EnvironmentConfig";
import axios from "axios";

const Dahsboard = () => {
  const [userName] = useState(getUserName());
  const [openAspirantcoins, setOpenAspirantcoins] = useState(false);
  const [homeCard, setHomeCard] = useState(true);
  const [dashboardHomeModal, setDashboardHomeModal] = useState(false);
  const [phoneSidebarStatus, setPhoneSidebarStatus] = useState(false);
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [testHistory, setTestHistory] = useState([]);
  const [allowedDailyTests, setAllowedDailyTests] = useState(0);
  const [completedTestsToday, setCompletedTestsToday] = useState(0);
  const [testCompleted, setTestCompleted] = useState(false);

  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };
  

  async function intialiseDashboard() {
    const url = env.API_ENDPOINT_URL + `participant/dashboard/overview`;
    try {
      const resp = await axios.get(url, header_token);
      setDashboardInfo(resp.data);
      setTestHistory(resp.data.data.testHistory);
      setAllowedDailyTests(resp.data.data.allowedDailyTests);
      setCompletedTestsToday(resp.data.data.completedTests.completedToday);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    console.log(testCompleted);
  }, [testCompleted]);

  useEffect(() => {
    setTestCompleted(allowedDailyTests === completedTestsToday);

  }, [allowedDailyTests]);

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      console.log("working or not")
      window.history.go(1);
    });
    intialiseDashboard();
   
  }, []);
 

  return (
    <>
      <div className="h-[100%] min-h-[100vh] ">
        
        <div className="h-2.5 custom-gradiant"></div>

        <div className="flex items-center flex-row-reverse p-10 bg-[#FAFAFA] w-[95%] m-auto rounded-[20px] my-10 justify-between minlg:hidden">
          <div
            onClick={() => setPhoneSidebarStatus(!phoneSidebarStatus)}
            className="w-16"
          >
            <BiMenuAltLeft className="w-[100%] h-[100%]" />
          </div>
          <img
            src={logo}
            alt="logo"
            className="max-w-custom-s max-h-custom-s overflow-hidden"
          />
        </div>

        <div className="flex  not-italic font-Inter w-[95%] m-auto ">
          <Sidebar userName={userName} status={phoneSidebarStatus} />

          <Content
            userName={userName}
            setDashboardHomeModal={setDashboardHomeModal}
            dashboardHomeModal={dashboardHomeModal}
            testHistory={testHistory}
            testCompleted={testCompleted}
            allowedDailyTests={allowedDailyTests}
            completedTestsToday={completedTestsToday}
          />
        </div>

        {dashboardHomeModal ? (
          <div className="absolute top-0 left-0 w-[100vw] h-[100%] bg-black z-20 opacity-50"></div>
        ) : (
          <></>
        )}
      </div>
      <DashboardHomeModal
        open={dashboardHomeModal}
        setOpen={setDashboardHomeModal}
      />
    </>
  );
};

export default Dahsboard;
