import axios from "axios";
import React, { useState } from "react";
import "./reportQuestion.css";

const ReportQuestion = ({ setShowModal, question }) => {
  const [review, setReview] = useState(false);

  async function reportQuestion(question, review) {
    const url =
      "https://sheet.best/api/sheets/75dd2692-4284-4bbb-8537-eeb69e9abced";
    try {
      const resp = await axios.post(url, {
        question: question,
        review: review,
      });
      setShowModal(false);
      if (resp.data.status === "success") {
        console.log("success");
        setShowModal(false);
      }
    } catch (err) {}
  }
  return (
    <div className="overlay">
      <div className="report-container">
        <h2 style={{ fontWeight: "bold" }}>Report Question</h2>
        <div
          className="question"
          dangerouslySetInnerHTML={{
            __html: question,
          }}
        ></div>
        <br></br>
        <textarea
          className="text-area-to-report"
          placeholder="Reason to Report"
          onChange={(e) => setReview(e.target.value)}
        ></textarea>
        <div className="gap"></div>
        <button
          onClick={() => setShowModal(false)}
          style={{ marginRight: "5px" }}
          className=" btn btn-primary"
        >
          Cancel
        </button>
        <button
          onClick={() => reportQuestion(question, review)}
          className="btn btn-danger"
        >
          Report
        </button>
      </div>
    </div>
  );
};

export default ReportQuestion;
