import axios from 'axios'
import history from '../../history'
import { env } from '../helpers/EnvironmentConfig';
import { notification } from 'antd';

const service = axios.create({
	baseURL: env.API_ENDPOINT_URL,
	timeout: 60000
})

const TOKEN_PAYLOAD_KEY = 'Authorization'
const AUTH_TOKEN = 'access_token'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(AUTH_TOKEN)
	config.headers['Content-Type'] = 'application/json';
	config.headers['Bypass-Tunnel-Reminder'] = 'nill';
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`
	}

	return config
}, error => {
	// Do something with request error here
	// notification.error({
	// 	message: 'Error'
	// })
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {
	return response.data
}, (error) => {

	let notificationParam = {
		message: '',
		description: ''
	}

	console.log(error);

	// if (error.response.status === 400) {
	// 	notificationParam.message = error.response.data.message
	// 	notification.error(notificationParam)
	// 	localStorage.clear();
	// }

	// //Remove token and redirect 
	// if (error.response.status === 403) {
	// 	notificationParam.message = 'Authentication Fail'
	// 	notificationParam.description = 'Please login again'
	// 	notification.error(notificationParam)
	// 	localStorage.clear();
	// 	history.push(ENTRY_ROUTE)
	// 	window.location.reload();
	// }

	// if (error.response.status === 401) {
	// 	notificationParam.message = 'Unauthorized!'
	// 	notificationParam.description = 'Please login again'
	// 	notification.error(notificationParam)
	// 	localStorage.clear();
	// 	history.push(ENTRY_ROUTE)
	// 	window.location.reload();
	// }

	// if (error.response.status === 404) {
	// 	notificationParam.message = error.response?.data?.message
	// 	notificationParam.description = 'Please login again'
	// 	notification.error(notificationParam)
	// 	localStorage.clear();
	// 	history.push(ENTRY_ROUTE)
	// 	window.location.reload();
	// }

	if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}

	if (error.response.status === 508) {
		notificationParam.message = 'Time Out'
	}

	return Promise.reject(error);
});

export default service