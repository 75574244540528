import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import ExitBtn from "../../components/Login/ExitBtn";
import LoginHeading from "../../components/Login/LoginHeading";
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  async function forgotPassword() {
    const resp = await axios.post(
      "https://devyuvraj.aspirantlearning.com/api/v1/participant/reset-password",
      email
    );
    if (resp.status === 200) {
      toast.success("Password Reset Link Sent to your Email");
    }
  }

  return (
    <div>
      <ToastContainer />
      <div
        style={{ margin: "auto" }}
        className="login lg:px-2 lg:pt-40 lg:items-center lg:text-center  lg:h-[100%] lg:w-[100%] lg:m-auto"
      >
        <ExitBtn />
        <LoginHeading highlightedText="Forgot Password" text="" />

        <span>Enter Email Id </span>
        <form>
          <input
            type="email"
            name="email"
            id="email"
            value={email}
            placeholder="Enter Your Username or Email ID"
            autoComplete="off"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            required
          />
        </form>
        <button
          onClick={forgotPassword}
          className="loginSubmitBtn bg-[#113359]"
        >
          Get New Password
        </button>
        <div>
         
            <Link to="/login" className="passLink">
              Login
            </Link>
       
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
