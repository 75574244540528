import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

const AverageScoreGraph = ({ subjectwiseAvgScore }) => {
  const [correctScore, setCorrectScore] = useState([]);
  const [wrongScore, setWrongScore] = useState([]);

  useEffect(() => {
    const rightAnswer = [];
    const wrongAnswer = [];
    const score = subjectwiseAvgScore?.score;

    let len = score.length;
      if (len % 2 !== 0) {
        len = len - 1;
      }
    console.log('lenght',len);
    if (score.length >= 10) {
      for (let i = 0; i < 10; i += 2) {
        if (score[i].test_id === score[i + 1].test_id) {
          rightAnswer.push(parseInt(score[i + 1].answer));
          wrongAnswer.push(parseInt(score[i].answer));
        } else {
          rightAnswer.push(0);
          wrongAnswer.push(parseInt(score[i].answer));
        }
      }
    } else {
      
      for (let i = 0; i < len; i += 2) {
        if (score[i].test_id === score[i + 1].test_id) {
          rightAnswer.push(parseInt(score[i + 1].answer));
          wrongAnswer.push(parseInt(score[i].answer));
        } else {
          rightAnswer.push(0);
          wrongAnswer.push(parseInt(score[i].answer));
          i = i - 1;
        }
      }
    }
    setCorrectScore(rightAnswer);
    setWrongScore(wrongAnswer);
    console.log("rightAnswer", rightAnswer);
    console.log("wrongAnswer", wrongAnswer);
  }, [subjectwiseAvgScore]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  const labels = ["Test1", "Test2", "Test3", "Test4", "Test5"];
  const data = {
    labels,
    datasets: [
      {
        label: "Correct",
        data: correctScore,
        backgroundColor: "#2ECC71",
      },
      {
        label: "Incorrect",
        data: wrongScore,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div className="flex py-6 my-2 justify-center">
      <Bar options={options} data={data} />
    </div>
  );
};

export default AverageScoreGraph;
