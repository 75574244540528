import React from "react";
import { getUserName } from "../../@common/shared/LocalStorage/localstorage";
import clap from "../../assets/quiz/clap.svg";
import { Link, useNavigate } from "react-router-dom";
import coin from "../../assets/quiz/coins.svg";
const Modal = ({ open, correctQuestion, incorrectQuestion, testId,maxQuestions }) => {
  let navigate = useNavigate();

  const NavigateToTestExplanation = () => {
    navigate("/testExplanation", {
      state: {
        testId: testId,
      },
    });
  };
  if (!open) return null;
  return (
    <div
      className="bg-white text-center rounded-[20px] fixed top-[10%] left-[40%]  px-10 py-8  max-w-[398px] z-40 flex flex-col items-center font-Inter non-italic  lg:left-[30%] lg:right-[30%] md:left-[20%] md:right-[20%] sm:left-[15%] sm:right-[15%] "
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="bg-[#E9F5FE] rounded-full w-48 my-4">
        <img src={clap} alt="clap" className="rotate-image" />
      </div>
      <div className=" flex flex-col items-center mt-4 ">
        <h1 className="font-semibold text-[32px] leading-[39px] text-black">
          {" "}
          <span className="text-[#34A853]">{maxQuestions===100 ? correctQuestion*2-incorrectQuestion : correctQuestion}</span>/{maxQuestions===100 ? maxQuestions*2: maxQuestions}
        </h1>
        <p className="font-medium">Your Score</p>
      </div>
      <div className="flex flex-col items-center my-6">
        <h1 className="text-[#303030] font-semibold text-[28px] leading-[34px]">
          {correctQuestion / (correctQuestion + incorrectQuestion) > 0.7
            ? "Great Work!"
            : correctQuestion / (correctQuestion + incorrectQuestion) > 0.5
            ? "Good job!"
            : "Needs Improvment"}{" "}
          {getUserName()}
        </h1>
        <p className="text-[#303030] text-[14px] font-light leading-[17px] my-2">
          You have completed quiz successfully
        </p>
      </div>
      {/* <div className="bg-[#5D44DE] flex text-white rounded-[35px] px-20 py-4 items-center my-6">
        <img src={coin} alt="coins" />
        <span className="ml-10 font-semibold text-[16px] leading-[19px] mt-1">
          299 Coins Reward
        </span>
      </div> */}

      {/* <div onClick={NavigateToTestExplanation}> */}
      <div
        className="bg-[#34a853] flex text-white rounded-[35px] px-20 py-4 items-center my-6 text-center justify-center w-[84%] cursor-pointer"
        onClick={NavigateToTestExplanation}
      >
        <span className=" font-semibold text-[16px] text-center leading-[19px] mt-1 cursor-pointer">
          Test Explanation
        </span>
      </div>
      {/* </div> */}

      <a
        href="/dashboard"
        className="my-6 flex items-center  font-medium text-[16px] leading-[19px] hover:underline hover:decoration-white"
      >
        <span className="font-semibold mr-4">&#60; &nbsp;</span>
        <span className="underline">Back to Home</span>
      </a>
    </div>
  );
};

export default Modal;
