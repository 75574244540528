import React from "react";
import { useState, useEffect } from "react";
import logo from "../../assets/header/logo.png";
import axios from "axios";
import { env } from "../../@common/helpers/EnvironmentConfig";

import swal from "@sweetalert/with-react";

const Header = ({
  questions,
  headerArr,
  setHeaderArr,
  NavigateToQuestion,
  setIncorrectQuestion,
  setCorrectQuestion,
  setOpenModal,
  test_initiate,
  fullTest,
  setFullTest,
  maxQuestReached,
}) => {
  const [changeVal, setChangeVal] = useState(true);

  useEffect(() => {
    setChangeVal(!changeVal);
  }, [questions]);

  const showAlert = () => {
    swal({
      title: "Are you sure you want to submit?",
      text: "Once submitted cannot go back!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willSubmit) => {
      if (willSubmit) {
        submitTest();
      }
    });
  };
  const navigateAlert= () =>{
    swal({
      title:"You cannot navigate to unattended questions but can move back",
      icon: "info",
    }).then(()=>{
      console.log("clicked");
    });
  }
  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };

  async function submitTest() {
    console.log("ckicked");
    const test = {
      test_id: test_initiate.test_id,
    };
    const url = env.API_ENDPOINT_URL + `participant/test/get-results`;
    const resp = await axios.post(url, test, header_token);

    console.log("set question   ");
    setCorrectQuestion(resp.data.data.total_question_correct);
    setIncorrectQuestion(resp.data.data.total_question_incorrect);
    setOpenModal(true);
  }
  return (
    <div className="flex flex-col  items-center mt-10 mb-4 relative">
      <div className="flex items-center justify-between w-[100%]">
        <div className="flex justify-end minlg:w-[54%]">
          <img className="w-48 my-6" src={logo} alt="logo" />
        </div>
        <div className=" flex justify-end font-Inter non-italic hover:scale-110 ease-in duration-100 cursor-pointer">
          <span className=" font-medium text-[14px] text-black">
            &#60; &nbsp;
          </span>
          <p className=" hover:no-underline  px-0 py-0">
            {" "}
            <span
              className="underline font-medium text-[14px] text-black"
              onClick={() => {
                showAlert();
                setFullTest(true);
              }}
            >
              End Quiz
            </span>
          </p>
        </div>
      </div>

      {
        <div className="flex justify-center items-center my-6 flex-wrap w-[100vw]">
          {}
          {headerArr.map((item, index) => {
            return (
              <div
                className={` rounded-full px-2 py-2 w-16 h-16 my-2 flex justify-center items-center mx-[7px] cursor-pointer ${
                  item.answered
                    ? "bg-green-500 text-white"
                    : item.visited
                    ? "bg-[#5D44DE] text-white"
                    : "bg-[#E9F5FE] text-black"
                } lg:my-2 `}
                onClick={() => {
                  if(index<=maxQuestReached)
                    NavigateToQuestion(index + 1);
                  else{
                    navigateAlert();
                  }

                  // console.log("called");
                }}
                // style={item.visited === true ? attended : ""}
              >
                <p className="text-[16px] font-semibold">{index + 1}</p>
              </div>
            );
          })}
        </div>
      }
    </div>
  );
};

export default Header;
