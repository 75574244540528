import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Notification from './Notification';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    notification: Notification,
  });
export default reducers;
