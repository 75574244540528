const dev = {
  API_ENDPOINT_URL: "https://devyuvraj.aspirantlearning.com/api/v1/",
  RAZORPAY_URL: "rzp_test_kmsb5tHqDT3GgQ"
  // API_ENDPOINT_URL: 'https://api.winterfeelglobal.com/api/v1/'
};


const prod = {
  API_ENDPOINT_URL: "https://devyuvraj.aspirantlearning.com/api/v1/",
  // API_ENDPOINT_URL: 'https://api.winterfeelglobal.com/api/v1/'
  RAZORPAY_URL: ""
};
// https://region1.aspirantlearning.com/api/v1/
const test = {
  API_ENDPOINT_URL: "https://devyuvraj.aspirantlearning.com/api/v1/",
  // API_ENDPOINT_URL: 'https://uat.winterfeelglobal.com/api/v1/'
  RAZORPAY_URL: ""
};
const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
