import React from "react";
import logo from "../../assets/header/logo.png";

const DashboardHomeModal = ({ open, setOpen }) => {
  if (!open) return null;

  return (
    <div className="bg-white z-30 fixed top-[1%] w-[45%] left-[25%] flex flex-col items-center max-w-[779px] rounded-[20px] py-10 px-14 font-Inter non-italic md:w-[80%] md:left-[10%] md:py-8 md:px-8 lg:w-[60%] lg:left-[20%] lg:py-8 lg:px-8">
      <img style={{height:"100px;", marginBottom:"30px"}} src={logo}  alt="" className="mb-10" />
      <div className="flex flex-col items-center text-center mb-16">
        <h1 className="font-semibold text-[28px] leading-[34px] text-[#303030] my-6">
          Current Affairs Quiz
        </h1>
        <p className="font-normal text-[12px] leading-[17px] text-[#646060]">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi architecto beatae vitae dicta
          sunt explicab.
        </p>
      </div>
      <div className="flex items-center justify-center mb-4">
        <div className="flex flex-col items-center mx-14 sm:text-center sm:mx-8">
          <h1 className="font-semibold text-[32px] leading-[39px] text-[#303030] sm:text-[20px]">
            15
          </h1>
          <p className="font-medium text-[16px] leading-[19px] text-[#303030] sm:text-[14px]">
            No of Questions
          </p>
        </div>
        <div className="border-[1px] border-[#B9B9B9] h-20  w-0 mx-6 "></div>
        <div className="flex flex-col items-center mx-14 sm:mx-8">
          <h1 className="font-semibold text-[32px] leading-[39px] text-[#303030] sm:text-[20px]  sm:w-[100%]">
            {" "}
            15 Min
          </h1>
          <p className="font-medium text-[16px] leading-[19px] text-[#303030]">
            Time alloted
          </p>
        </div>
      </div>
      <div className="bg-[#FB9C45] rounded-[5px] px-8 py-4 font-medium text-[16px] leading-[19px] text-white my-12">
        Instructions
      </div>
      <div className="flex flex-col items-center text-center text-[#646060] font-normal text-[12px] leading-[17px]">
        <p>
          Please answer the questions honestly as this test will help us
          identify your weak areas and design the most suitable study-plan for
          you.
        </p>
        <p>
          The assessment test might end in between if the weak areas have
          already been identified.
        </p>
      </div>
      <a
        href="/quiz"
        className="bg-[#5D44DE] rounded-[5px] px-20 py-6 text-white font-semibold text-[14px] leading-[17px] mt-16 mb-10 hover:scale-110 transition duration-300 ease-in-out w-[100%] text-center "
      >
        im Ready
      </a>
      <div
        className="flex justify-center font-medium text-[16px] leading-[19px] items-center cursor-pointer"
        onClick={() => {
          setOpen(false);
        }}
      >
        <span className="decoration-white mx-6 font-bold">&#60;</span>
        <p className="underline "> Im Not Ready Yet</p>
      </div>
    </div>
  );
};

export default DashboardHomeModal;
