import React, { useEffect, useState } from "react";
import logo from "../../assets/header/logo.png";
import { Link, useNavigate } from "react-router-dom";

const TestModal = ({
  open,
  setOpen,
  testInfo,
  testInitiateResponse,
  totalQuestions,
  fullTest,
  setFullTest
}) => {

  let navigate = useNavigate();
  async function renderQuiz() {
    const id = testInitiateResponse.data.data.test_id;
    navigate("/quiz", {
      state: {
        test_id: id,
        reading_stage: 1,
        current_question: 0,
        isFullTest: fullTest,
      },
    });
  }
  if (!open) return null;

  return (
    <div className="bg-white z-30 max-h-[100vh] overflow-hidden fixed top-[1%] bottom-[1%] overflow-y-scroll w-[45%] left-[25%] flex flex-col items-center max-w-[779px] rounded-[20px] py-10 px-14 font-Inter non-italic md:w-[80%] md:left-[10%] md:py-8 md:px-8 lg:w-[60%] lg:left-[20%] lg:py-8 lg:px-8">
      <img style={{height:"100px;", width:"200px", marginBottom:"30px"}} src={logo} alt="" className="mb-4" />
      <div className="flex flex-col items-center text-center mb-16">
        <h1 className="font-semibold text-[28px] leading-[34px] text-[#303030] my-2">
          {`${testInfo.chapterName !== "null" ? testInfo.chapterName : "Full test"} quiz`}
        </h1>
        {fullTest ? (
          <ul className="list-disc text-left">
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              This test contains 100 questions and each question comprises of
              four responses(answers).
            </li>
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              Select the right response and select the appropriate checkbox
              after carefully reading each phrase.
            </li>
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              Each question carries 2 marks each and for each wrong answered
              question one-third (.66) marks will be deducted from the overall
              score.
            </li>
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              If a question is left blank i.e., no answer is given by the
              candidate, there will be no penalty for that question.
            </li>
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              Time limit for this test is 2 hrs. (120 min) after which the test
              will be submitted automatically.
            </li>
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              Detailed explanation with your responses for each question can
              viewed after submitting the test.
            </li>
          </ul>
        ) : (
          <ul className="list-disc text-left">
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              Questions for each test may vary between 20-50 questions per test
              depending on the chapter selected.
            </li>
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              Select the right response and select the appropriate checkbox
              after carefully reading each phrase.
            </li>
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              There will be no marks given for this test; instead, the final
              score will be displayed based on the number of correct and
              incorrect answers in relation to the total number of test
              questions.
            </li>
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              A question will not be considered either correct or incorrect if
              it is left unanswered by the candidate.
            </li>
            <li className="font-normal text-[12px] leading-[17px] text-[#646060]">
              Although the exam has no time limit, the amount of time it took to
              answer each question and overall time take to complete the
              test will be recorded and later shown in test analytics. Detailed
              explanation with your responses for each question can viewed after
              submitting the test.
            </li>
          </ul>
        )}
      </div>
      <div className="flex items-center justify-center mb-4">
        <div className="flex flex-col items-center mx-14 sm:text-center sm:mx-8">
          <h1 className="font-semibold text-[32px] leading-[39px] text-[#303030] sm:text-[20px]">
            {fullTest ? "100" : totalQuestions}
          </h1>
          <p className="font-medium text-[16px] leading-[19px] text-[#303030] sm:text-[14px]">
            No of Questions
          </p>
        </div>
        <div className="border-[1px] border-[#B9B9B9] h-20  w-0 mx-6 "></div>
        <div className="flex flex-col items-center mx-14 sm:mx-8">
          <h1 className="font-semibold text-[32px] leading-[39px] text-[#303030] sm:text-[20px]  sm:w-[100%]">
            {" "}
            {fullTest ? "120 Min":"60 Min"}
          </h1>
          <p className="font-medium text-[16px] leading-[19px] text-[#303030]">
            Time alloted
          </p>
        </div>
      </div>
      <div className="bg-[#FB9C45] rounded-[5px] px-8 py-4 font-medium text-[16px] leading-[19px] text-white my-12">
        Instructions
      </div>
      <div className="flex flex-col items-center text-center text-[#646060] font-normal text-[12px] leading-[17px]">
        <p>
          Please answer the questions honestly as this test will help us
          identify your weak areas and design the most suitable study-plan for
          you.
        </p>
        <p>
          The assessment test might end in between if the weak areas have
          already been identified.
        </p>
      </div>
      <button
        onClick={() => {
          renderQuiz();
        }}
        className="bg-[#5D44DE] rounded-[5px] px-20 py-6 text-white font-semibold text-[14px] leading-[17px] mt-16 mb-10 hover:scale-110 transition duration-300 ease-in-out w-[100%] text-center "
      >
        im Ready
      </button>
      <div
        className="flex justify-center font-medium text-[16px] leading-[19px] items-center cursor-pointer"
        onClick={() => {
          setOpen(false);
        }}
      >
        <span className="decoration-white mx-6 font-bold">&#60;</span>
        <p className="underline "> Im Not Ready Yet</p>
      </div>
    </div>
  );
};

export default TestModal;
