import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const DonutGraphSubjects = ({strongSubjects}) => {
  const data = {
    labels: [
      "History",
      "Polity",
      "Geography",
      "Art& Culture",
      "Society",
      "Goverance",
      "Social Justice",
      "Economic Development",
    ],
    datasets: [
      {
        data: strongSubjects,
        backgroundColor: [
          "#ff6961",
          "#ffb480",
          "#f8f38d",
          "#42d6a4",
          "#08cad1",
          "#59adf6",
          "#9d94ff",
          "#c780e8",
        ],
        borderColor: [
          "#ff6961",
          "#ffb480",
          "#f8f38d",
          "#42d6a4",
          "#08cad1",
          "#59adf6",
          "#9d94ff",
          "#c780e8",
        ],
        borderWidth: 1,
      },
    ],
  };
  var options = {
    cutout: 120,
  };
  return (
    <div className="w-[40%] flex flex-col ">
      <Doughnut data={data} options={options} className="w-[20%]" />
      <p className="my-4 text-[14px] font-medium text-center">
        Based on R1 questions you answered correctly in chapter-wise
        assessments, this diagram displays your strongest subjects.
      </p>
    </div>
  );
};

export default DonutGraphSubjects;
