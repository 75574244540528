import React, { useEffect } from "react";
import Sidebar from "../../components/Dashboard/Sidebar.js";
import greenTick from "../../assets/dashboard/greenTick.svg";
import purpleTick from "../../assets/dashboard/purpleTick.svg";
import runningMan from "../../assets/dashboard/runningMan.svg";
import Graph from "../../components/Dashboard/DashboardGraph.js";
import info from "../../assets/dashboard/info.svg";
import DashboardDivision from "../../components/Dashboard/DashboardDivision.js";
import { BiMenuAltLeft } from "react-icons/bi";
import logo from "../../assets/header/logo.png";
import { useState } from "react";
import { getUserName } from "../../@common/shared/LocalStorage/localstorage.js";
import ActivityTrackerModal from "./ActivityTrackerModal.js";
import QuizGraph from "../../components/Quiz/QuizGraph.js";
import service from "../../@common/auth/FetchInterceptor.js";
import { env } from "../../@common/helpers/EnvironmentConfig.js";
import BarGraph from "./BarGraph.js";
import {
  SUCCESS_STOP,
  NOTIFICATION_TYPE_SUCCESS,
} from "../../@common/constants/ActionTypes.js";
import { notification } from "antd";
import LineGraph from "./LineGraph.js";
import DonutGraph from "./DonutGraph.js";
import HourBarGraph from "./HourBarGraph.js";

const ProductivityTracker = () => {
  const totalMinutes = 25;
  const [minutes, setMinutes] = useState(10);
  const [seconds, setSeconds] = useState(0);
  const [divManage, setdivManage] = useState(false);
  const [timeOverviewTable, settimeOverviewTable] = useState([]);
  const [activityStatistics, setactivityStatistics] = useState({
    total_test: "",
    total_questions_attended: "",
    correct_answers: "",
    weekly_score: "",
    weekly_tag: "",
    total_subjects: "",
    total_activity: "",
  });
  const [percent, setPercent] = useState(0);
  const [activityTrackerModal, setActivityTrackerModal] = useState(false);
  const [userName] = useState(getUserName());
  const n = 3;
  const m = 2;
  const [phoneSidebarStatus, setPhoneSidebarStatus] = useState(false);
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [testHistory, setTestHistory] = useState([]);
  const [allowedDailyTests, setAllowedDailyTests] = useState(0);
  const [completedTestsToday, setCompletedTestsToday] = useState(0);
  const [chapterName, setChapterName] = useState("");
  const [subjectName, setSubjectName] = useState("");

  const [timeChartData, setTimeChartData] = useState({
    datasets: [
      {
        label: "",
        data: [
          minutes * 60 + seconds,
          totalMinutes * 60 - (minutes * 60 + seconds),
        ],
        backgroundColor: ["#FF9900", "#9648AD"],
        borderColor: ["#FF9900", "#9648AD"],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    const percentData = (completedTestsToday / allowedDailyTests) * 100;
    if (percentData >= 0) {
      setPercent(percentData);
    }
  }, [allowedDailyTests, completedTestsToday]);

  useEffect(() => {
    getInitialDataSet();
    timerCheck();
    timerOverviewData();
    getActivityStatistics();
  }, []);

  useEffect(() => {
    if (divManage) {
      let myInterval = setInterval(() => {
        if (minutes < totalMinutes) {
          if (seconds < 59) {
            setSeconds(seconds + 1);
          }
          if (seconds === 59) {
            if (minutes === totalMinutes) {
              clearInterval(myInterval);
            } else {
              setMinutes(minutes + 1);
              setSeconds(0);
            }
          }
        } else {
          clearInterval(myInterval);
        }

        setTimeChartData({
          datasets: [
            {
              label: "",
              data: [
                minutes * 60 + seconds,
                totalMinutes * 60 - (minutes * 60 + seconds),
              ],
              backgroundColor: ["#FF9900", "#9648AD"],
              borderColor: ["#FF9900", "#9648AD"],
              borderWidth: 1,
            },
          ],
        });
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  const timerCheck = async () => {
    try {
      const resp = await service.get(
        env.API_ENDPOINT_URL + "participant/timer/checkTimer"
      );
      if (resp.success) {
        if (resp.data.isTimerRunning) {
          setSubjectName(resp.data?.timerData.subjectName);
          setChapterName(resp.data?.timerData.chapterName);
          setdivManage(true);
          setMinutes(resp.data?.diffInMinutes);
          setSeconds(resp.data?.diffInSecs);
        } else {
          setdivManage(false);
          setMinutes(0);
          setSeconds(0);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const timerOverviewData = async () => {
    try {
      const resp = await service.get(
        env.API_ENDPOINT_URL + "participant/timer/overview"
      );
      if (resp.success) {
        settimeOverviewTable(resp.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getActivityStatistics = async () => {
    try {
      const resp = await service.get(
        env.API_ENDPOINT_URL + "participant/tracker/overview"
      );
      if (resp.success) {
        setactivityStatistics(resp.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getInitialDataSet = async () => {
    try {
      const resp = await service.get(
        env.API_ENDPOINT_URL + "participant/dashboard/overview"
      );
      setDashboardInfo(resp.data);
      setTestHistory(resp.data.testHistory);
      setAllowedDailyTests(resp.data.allowedDailyTests);
      setCompletedTestsToday(resp.data.completedTests.completedToday);
    } catch (err) {
      console.log(err);
    }
  };

  const timerAPISubmit = async (request) => {
    try {
      const resp = await service.post(
        env.API_ENDPOINT_URL + "participant/timer/trigger",
        request
      );
      if (resp.success) {
        setdivManage(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const timerAPIStopSubmit = async () => {
    try {
      const resp = await service.get(
        env.API_ENDPOINT_URL + "participant/timer/stop"
      );
      if (resp.success) {
        setdivManage(false);
        setMinutes(0);
        setSeconds(0);
        setActivityTrackerModal(false);
        notification[NOTIFICATION_TYPE_SUCCESS]({
          message: SUCCESS_STOP,
        });
        timerOverviewData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const overviewTimeCal = (val) => {
    const diffInMinutes = Math.floor(val / 60);
    const diffInSecs = val - diffInMinutes * 60;
    const diffInMMSS = `${diffInMinutes}:${
      diffInSecs < 10 ? "0" : ""
    }${diffInSecs}`;
    return diffInMMSS;
  };

  return (
    <div>
      <div className="h-2.5 custom-gradiant"></div>
      <div className="flex items-center flex-row-reverse p-10 bg-[#FAFAFA] w-[95%] m-auto rounded-[20px] my-10 justify-between minlg:hidden">
        <div
          onClick={() => setPhoneSidebarStatus(!phoneSidebarStatus)}
          className="w-16"
        >
          <BiMenuAltLeft className="w-[100%] h-[100%]" />
        </div>
        <img
          src={logo}
          alt="logo"
          className="max-w-custom-s max-h-custom-s overflow-hidden"
        />
      </div>
      <div className="flex font-Inter non-italic w-[95%] m-auto lg:flex-col">
        <Sidebar userName={userName} status={phoneSidebarStatus} />
        <div className="w-3/4  overflow-hidden flex flex-col mt-12 font-Inter non-italic text-[#303030] px-4 py-2 border-none lg:mt-0 lg:w-[98%] lg:m-auto ">
          <div className="mt-8 mb-4 px-4">
            <h1 className="text-[28px] text-black mb-4 font-semibold">
              {" "}
              Learning Time Tracker
            </h1>
            <p>Simplify your time tracking and learning management.</p>
          </div>
          <div className="flex items-center md:flex-col md:items-start w-[100%]">
            {!divManage && (
              <div className="flex md:mx-4 md:my-4 md:items-center">
                <div className="flex justify-center items-start lg:items-center px-4 py-4 md:w-1/3 md:items-center md:text-center">
                  <button
                    className="bg-[#5D44DE] text-white text-center px-8 py-4 max-w-[260px] lg:min-w-[200px] rounded-md w-[100%] my-6 hover:scale-110 transition-all"
                    onClick={() => {
                      setActivityTrackerModal(true);
                    }}
                  >
                    Start Timer
                  </button>
                </div>
              </div>
            )}
            {divManage && (
              <div className="flex md:mx-4 md:my-4 md:items-center justify-between items-center w-[100%]">
                <div className="flex flex-col justify-center px-4">
                  <h1 className="text-[16px] leading-[19px] font-bold my-2">
                    {" "}
                    Subject Name:{" "}
                    <span className="font-medium text-[14px]">
                      {subjectName}
                    </span>
                  </h1>
                  <h1 className="text-[16px] leading-[19px] font-bold my-2 ">
                    Chapter Name:
                    <span className="font-medium text-[14px] ">
                      {" "}
                      {chapterName}
                    </span>
                  </h1>
                </div>
                <div className="flex justify-center items-start px-4 py-4 md:w-1/3 md:items-center md:text-center">
                  <button
                    className="bg-[#d61c1c] text-white text-center px-8 py-4 max-w-[260px] rounded-md w-[100%] my-6 hover:scale-110 transition-all"
                    onClick={() => {
                      timerAPIStopSubmit();
                    }}
                  >
                    Stop Timer
                  </button>
                </div>
              </div>
            )}
            {divManage && (
              <div className="flex md:mx-4 md:my-4 md:items-center">
                <div className="flex justify-center items-start px-4 py-4 md:w-1/3 md:items-center md:text-center">
                  <div className="flex items-center mb-4">
                    <div className="flex items-center justify-center w-36 h-36  mx-4 relative">
                      <QuizGraph chartData={timeChartData} className="w-[400px] h-auto"/>{" "}
                      <span className="absolute top-[43%] left-[26%] text-black text-[16px] font-semibold">
                        {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <DashboardDivision /> */}

          {/* <DashboardDivision /> */}
          {/* <div className="flex items-center justify-start md:justify-center">
            <div className="bg-[#E9F5FE] rounded-[5px] px-10 py-4 font-semibold text-[14px] leading-[17px] mr-2 hover:bg-[#5D44DE] hover:text-white cursor-default md:px-4 ">
              Weekly
            </div>
            <div className="bg-[#E9F5FE] rounded-[5px] px-10 py-4 font-semibold text-[14px] leading-[17px] mx-2 hover:bg-[#5D44DE] hover:text-white cursor-default md:px-4">
              Monthly
            </div>
          </div> */}

          {/* <div className="flex items-center justify-between md:flex-col">
            <div className="flex justify-center flex-col">
              <div className="flex items-center md:justify-center ">
                <div className="relative w-40 mr-4 ">
                  <ActivityGraph correct_answers={activityStatistics?.weekly_score} />

                  <p className="absolute top-[65%] left-[30%] font-medium text-[16px] leading-[19px]">
                    {`${activityStatistics?.correct_answers}/${activityStatistics?.total_test}`}
                  </p>
                </div>

                <div className="flex flex-col pt-10 mx-6">
                  <div className="flex items-center my-1">
                    <img src={star} alt="star" />
                    <h1 className="font-medium text-[16px] leading-[19px] mx-2">
                      {activityStatistics?.weekly_tag}
                    </h1>
                  </div>
                  <p className="font-light text-[14px] leading-[17px]">
                    Your Weekly Score
                  </p>
                </div>
              </div>
              <div className="flex my-14">
                <div className="flex flex-col items-center justify-ceneter mr-6 lg:mr-0 ">
                  <h1 className="font-medium text-[16px] leading-[19px] lg:w-32">
                    Test Done
                  </h1>
                  <p className="font-semibold text-[28px] text-[#5D44DE] leading-[34px] my-2">
                    {activityStatistics?.total_test}
                  </p>
                </div>
                <div className="border  border-[#CFCFCF] w-20 rotate-90 mx-0"></div>
                <div className="flex flex-col items-center justify-ceneter mx-6 lg:mx-2">
                  <h1 className="font-medium text-[16px] leading-[19px]">
                    Activities
                  </h1>
                  <p className="font-semibold text-[28px] text-[#FB9C45] leading-[34px] my-2">
                    {activityStatistics?.total_activity}
                  </p>
                </div>
                <div className="border  border-[#CFCFCF] w-20 rotate-90 mx-0"></div>

                <div className="flex flex-col items-center justify-ceneter mx-6 lg:mx-2">
                  <h1 className="font-medium text-[16px] leading-[19px]">
                    Syllabus
                  </h1>
                  <p className="my-2 font-semibold text-[28px] text-[#24AEDA] leading-[34px]">
                    {activityStatistics?.total_subjects}
                  </p>
                </div>
              </div>
            </div>
            <div className="mx-20">
              <img src={graph} draggable="false" />
            </div>
          </div> */}
          <DashboardDivision />
          {/* <div className="flex mt-55 mb-10 justify-between items-center md:flex-col">
            <div className="flex flex-col md:text-center ">
              <h1 className="font-semibold text-[22px] leading-[27px]">
                Average time worked by the day of the week
              </h1>
            </div>
          </div>
          <div className="bg-white flex flex-col justify-center">
            <h1 className="my-2 text-[14px] leading-[17px] font-medium mb-4">
              This chart shows how much time you worked on average on a given
              day of the week for all the subjects together
            </h1>

            <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
              <div
                className="h-[37px] w-[10%] bg-[#9449ae] transition-all duration-500 "
                // style={{
                //   height: "37px",
                //   width: "0%",
                //   backgroundColor: "#a66cff",
                //   transition: "width 0.5s",
                //   margin: "0px",
                // }}
              ></div>
              <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                Monday
              </p>
              <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                0h 0m
              </p>
            </div>
            <div className="flex justify-between bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
              <div className="h-[37px] w-[20%]  bg-[#9449ae] transition-all duration-500 "></div>
              <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                Tuesday
              </p>
              <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                0h 0m
              </p>
            </div>
            <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
              <div className="h-[37px] w-[0%] bg-[#9449ae] transition-all duration-500 "></div>
              <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                Wednesday
              </p>
              <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                0h 0m
              </p>
            </div>
            <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
              <div className="h-[37px] w-[0%] bg-[#9449ae] transition-all duration-500 "></div>
              <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                Thursday
              </p>
              <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                0h 0m
              </p>
            </div>
            <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
              <div
                className="h-[37px] w-[0%] bg-[#9449ae] transition-all duration-500 "
                // onLoad={}
              ></div>
              <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                Friday
              </p>
              <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                0h 0m
              </p>
            </div>
            <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden">
              <div className="h-[37px] w-[5%] bg-[#9449ae] transition-all duration-500 "></div>
              <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                Saturday
              </p>
              <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                0h 0m
              </p>
            </div>
            <div className="flex justify-between  bg-[#ECC9EE] my-2 relative h-[37px] overflow-hidden ">
              <div className="h-[37px] w-[20%] bg-[#9449ae] transition-all duration-500 "></div>
              <p className="text-white text-[14px] leading-[17px] absolute left-[1%] top-[27%]">
                Sunday
              </p>
              <p className="text-white text-[14px] leading-[17px] absolute right-[1%] top-[27%]">
                0h 0m
              </p>
            </div>
          </div>
          <DashboardDivision /> */}
          <div>
            {/* <BarGraph graphDescription="Average Learning Time"></BarGraph> */}
            <div className="mb-10"></div>
            <h1 className="font-semibold text-[22px] leading-[27px]">
              
              Daily Activity
            </h1>
            <p className="text-[14px] leading-[19px] font-normal my-2">
              Show how much work you did on average during a given hour of the
              day. A full bar on an hour would mean you worked for that entire
              hour for the day.
            </p>
            <HourBarGraph timeOverviewTable={timeOverviewTable}></HourBarGraph>
            <div className="mb-20"></div>
          </div>
          <DashboardDivision />

          <div className="flex flex-col">
            <h1 className="font-semibold text-[22px] leading-[27px]">
              Project worked on for the day
            </h1>
            <p className="text-[14px] leading-[19px] font-normal mb-6 mt-4">
              Shows how much time you spent working on specific subject for the
              day
            </p>
            <div className="challenge-list my-4">
              <div className="challenge-left">
                <div className="challenge-right">
                  <div className="challenge-point">
                    <h2>Subject Name </h2>
                  </div>
                </div>
              </div>
              <div className="challenge-right">
                <div className="challenge-point">
                  <h2>Time Which Participate</h2>
                </div>
              </div>
            </div>
            <DashboardDivision />
            {timeOverviewTable.map((val, i) => (
              <div key={i} className="challenge-list mt-5 mb-5">
                <div className="challenge-left">
                  <div className="challenge-right">
                    <div className="challenge-point">
                      <h4>{val.subjectName}</h4>
                    </div>
                  </div>
                </div>
                <div className="challenge-right">
                  <div className="challenge-point">
                    <h4>{overviewTimeCal(val.total_time_spent)}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {activityTrackerModal ? (
        <div className="absolute top-0 left-0 w-[100vw] h-[100%] bg-black z-20 opacity-50"></div>
      ) : (
        <></>
      )}
      <ActivityTrackerModal
        open={activityTrackerModal}
        setOpen={setActivityTrackerModal}
        timerAPISubmit={timerAPISubmit}
        setSubName={setSubjectName}
        setChapName={setChapterName}
      />
    </div>
  );
};

export default ProductivityTracker;
