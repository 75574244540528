import React from "react";
import Sidebar from "../../components/Dashboard/Sidebar";
import { useState } from "react";
import runningMan from "../../assets/dashboard/runningMan.svg";
import "./Challenge.css";
import DashboardDivision from "../../components/Dashboard/DashboardDivision";
import { BiMenuAltLeft } from "react-icons/bi";
import logo from "../../assets/header/logo.png";
import { getUserName } from "../../@common/shared/LocalStorage/localstorage";
const Challenge = () => {
   const [phoneSidebarStatus, setPhoneSidebarStatus] = useState(false);
   const [userName] = useState(getUserName());
   return (
      <>
         <div className="h-2.5 custom-gradiant"></div>
         <div className="flex items-center flex-row-reverse p-10 bg-[#FAFAFA] w-[95%] m-auto rounded-[20px] my-10 justify-between minlg:hidden">
        <div
          onClick={() => setPhoneSidebarStatus(!phoneSidebarStatus)}
          className="w-16"
        >
          <BiMenuAltLeft className="w-[100%] h-[100%]" />
        </div>
        <img
          src={logo}
          alt="logo"
          className="max-w-custom-s max-h-custom-s overflow-hidden"
        />
      </div>
         {/* after completing content section remove height on this  */}
         <div className="flex font-Inter non-italic w-[95%] m-auto lg:flex-col">
        <Sidebar userName={userName} status={phoneSidebarStatus} />

        <div className="w-3/4  overflow-hidden flex flex-col mt-12 font-Inter non-italic text-[#303030] px-4 py-2 border-none lg:mt-0 lg:w-[98%] lg:m-auto ">
               <div className="flex flex-col mb-4">
                  <h1 className="font-semibold  text-[28px] leading-[34px] mb-2">
                     Ranking
                  </h1>
                  <p className="font-light text-[14px] leading-[17px] mt-2">
                     Complete your daily tasks to fastrack your preparation.
                  </p>
               </div>
               <div className="runner-container flex flex-wrap ">
                  <div className="flex mx-4">
                     <div className=" flex bg-[#E9F5FE] rounded-full mx-4">
                        <img className="rotate-image" src={runningMan} alt="man" />
                     </div>
                     <div className=" mx-4 flex flex-col items-center">
                        <span className="mt-4 font-medium text-black ">
                           Your Doing Great! Keep Going
                        </span>
                        <p className="font-light text-[#303030] text-[12px] flex justify-start w-[100%]">
                           Last 5 Days Completion Rate
                        </p>
                     </div>
                  </div>
                  <div className="star-need flex ml-[30%] items-center">
                     <img src="./images/one_star.svg"></img>You Just Need 3 Points to
                     Get First
                  </div>
               </div>
               <DashboardDivision />
               <div className="challenge-list">
                  <div className="challenge-left">
                     <div className="star">
                        <img src="./images/3star.svg"></img>
                     </div>
                     <div className="challenge-person-name">
                        <div className="challenge-avtar">
                           <img src="./images/avtar1.svg"></img>
                        </div>
                        <div className="persone-name-challenge">
                           <h2>Ashwathi Arun</h2>
                           <p>First</p>
                        </div>
                     </div>
                  </div>
                  <div className="challenge-right">
                     <div className="coin-icon">
                        <img src='./images/coin-icon-blue.svg'></img>
                     </div>
                     <div className="challenge-point">
                        <h2>5000</h2>
                        <p>Reward Points</p>
                     </div>
                  </div>
               </div>
               <DashboardDivision />


               <div className="challenge-list">
                  <div className="challenge-left">
                     <div className="star">
                        <img src="./images/2star.svg"></img>
                     </div>
                     <div className="challenge-person-name">
                        <div className="challenge-avtar">
                           <img src="./images/avtar2.svg"></img>
                        </div>
                        <div className="persone-name-challenge">
                           <h2>Swetha Mehta</h2>
                           <p>Second</p>
                        </div>
                     </div>
                  </div>
                  <div className="challenge-right">
                     <div className="coin-icon">
                        <img src='./images/coin-icon-blue.svg'></img>
                     </div>
                     <div className="challenge-point">
                        <h2>4598</h2>
                        <p>Reward Points</p>
                     </div>
                  </div>
               </div>
               <DashboardDivision />

               <div className="challenge-list">
                  <div className="challenge-left">
                     <div className="star">
                        <img src="./images/one_star.svg"></img>
                     </div>
                     <div className="challenge-person-name">
                        <div className="challenge-avtar">
                           <img src="./images/avtar1.svg"></img>
                        </div>
                        <div className="persone-name-challenge">
                           <h2>Vivek T Raj</h2>
                           <p>Third</p>
                        </div>
                     </div>
                  </div>
                  <div className="challenge-right">
                     <div className="coin-icon">
                        <img src='./images/coin-icon-blue.svg'></img>
                     </div>
                     <div className="challenge-point">
                        <h2>4285</h2>
                        <p>Reward Points</p>
                     </div>
                  </div>
               </div>
               <DashboardDivision />

               <div className="challenge-list">
                  <div className="challenge-left">
                     <div className="star">
                        <img src="./images/fadeStar.svg"></img>
                     </div>
                     <div className="challenge-person-name">
                        <div className="challenge-avtar">
                           <img src="./images/avtar2.svg"></img>
                        </div>
                        <div className="persone-name-challenge">
                           <h2>Ponnam Reddy</h2>
                           <p>Fourth</p>
                        </div>
                     </div>
                  </div>
                  <div className="challenge-right">
                     <div className="coin-icon">
                        <img src='./images/coin-icon-blue.svg'></img>
                     </div>
                     <div className="challenge-point">
                        <h2>3985</h2>
                        <p>Reward Points</p>
                     </div>
                  </div>
               </div>
               <DashboardDivision />

               <div className="challenge-list">
                  <div className="challenge-left">
                     <div className="star">
                        <img src="./images/fadeStar.svg"></img>
                     </div>
                     <div className="challenge-person-name">
                        <div className="challenge-avtar">
                           <img src="./images/avtar1.svg"></img>
                        </div>
                        <div className="persone-name-challenge">
                           <h2>Arun Pandey</h2>
                           <p>Fifth</p>
                        </div>
                     </div>
                  </div>
                  <div className="challenge-right">
                     <div className="coin-icon">
                        <img src='./images/coin-icon-blue.svg'></img>
                     </div>
                     <div className="challenge-point">
                        <h2>3265</h2>
                        <p>Reward Points</p>
                     </div>
                  </div>
               </div>
               <DashboardDivision />
            </div>
         </div>
      </>
   );
};

export default Challenge;
