import React, { useState, useEffect } from "react";
import { FiCheck } from "react-icons/fi";
import swal from "@sweetalert/with-react";
import axios from "axios";
import { env } from "../../@common/helpers/EnvironmentConfig";
import { notification } from "antd";

import {
  NOTIFICATION_TYPE_WARNING,
  ANSWER_ATLEAST_ONE,
} from "../../@common/constants/ActionTypes";
import ReportQuestion from "./ReportQuestion";

const Question = ({
  test_initiate,
  question,
  questionOptions,
  questionTitle,
  getQuestion,
  setQuestions,
  currentQuestion,
  setCurrentQuestion,
  setOpenModal,
  nextQuestion,
  openModal,
  setNextQuestion,
  setQuestionOptions,
  setQuestionTitle,
  setQuestion,
  state,
  correctQuestion,
  incorrectQuestion,
  setIncorrectQuestion,
  setCorrectQuestion,
  currReadingStage,
  setCurrReadingStage,
  headerArr,
  setHeaderArr,
  selected,
  setSelected,
  setMaxQuestReached,
  maxQuestReached,
}) => {
  const [flag, setFlag] = useState(0);
  const [chosenOption, setChosenOption] = useState({});
  const [callAmICorrect, setCallAmICorrect] = useState(false);
  const [open, setOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    console.log("curr reading stage", currReadingStage);
  }, [currReadingStage]);

  async function submitTest() {
    const test = {
      test_id: test_initiate.test_id,
    };
    const url = env.API_ENDPOINT_URL + `participant/test/get-results`;
    const resp = await axios.post(url, test, header_token);

    console.log("set question   ");
    setCorrectQuestion(resp.data.data.total_question_correct);
    setIncorrectQuestion(resp.data.data.total_question_incorrect);
    setOpenModal(true);
  }

  useEffect(() => {
    // console.log(headerArr);
  }, [headerArr]);

  const showAlert = () => {
    swal({
      title: "Are you sure you want to submit?",
      text: "Once submitted cannot go back!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willSubmit) => {
      if (willSubmit) {
        submitTest();
      }
    });
  };

  const previous = async (value) => {
    if (currentQuestion > 1) {
      setMaxQuestions(maxQuestions - 1);
      setCurrentQuestion(maxQuestions - 1);
      const url = env.API_ENDPOINT_URL + `participant/test/get-question`;
      setChosenOption({});

      try {
        setCurrentQuestion(currentQuestion - 1);
        const resp = await axios.post(
          url,
          { ...state, current_question: value - 1 },
          header_token
        );
        setQuestion(resp);

        let counter = 1;
        const formattedQuestionHtml =
          resp.data.data.question.questionTitle.replace(
            /<li>/g,
            () => `<li>${counter++}. `
          );

        setQuestionTitle(formattedQuestionHtml);

        setQuestionOptions(resp.data.data.question.questionOptions);
        if (resp.data.data.is_answered) {
          setSelected(resp.data.data.is_answered.selected_answer);
          setCurrReadingStage(resp.data.data.is_answered.reading_stage);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const AnswerNotSubmitted = () => {
    swal({
      title: "Option not submitted successfully",
      text: "Try again by clicking the next button!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  };

  const [maxQuestions, setMaxQuestions] = useState(1);
  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };

  async function optionSelected(currentOption) {
    const updatedOption = {
      ...chosenOption,
      test_id: test_initiate.test_id,
      reading_stage: currReadingStage,
      question_ref: question.data.data.question_ref,
      answer_id: currentOption._id,
      report_id: question.data.data.report_id,
    };

    setChosenOption(updatedOption);

    console.log("choose option", updatedOption);
    console.log("curr reading stage", currReadingStage);

    const url = env.API_ENDPOINT_URL + `participant/test/am-i-correct`;
    try {
      setCallAmICorrect(false);
      const resp = await axios.post(url, updatedOption, header_token);
      if (resp.status === 200) {
        // checkAnswer();
        getQuestion();
        setCurrReadingStage(1);
        setSelected(false);
      
      } else {
        AnswerNotSubmitted();
      }
    } catch (err) {
      AnswerNotSubmitted();
      console.log(err);
    }
  }

  async function checkAnswer() {
    // const url = env.API_ENDPOINT_URL + `participant/test/am-i-correct`;
    // alert("check answer");
    // const updatedOption = {
    //   ...chosenOption,
    //   test_id: test_initiate.test_id,
    //   reading_stage: currReadingStage,
    //   question_ref: question.data.data.question_ref,
    //   report_id: question.data.data.report_id,
    // };

    if (callAmICorrect === true) {
      try {
        setCallAmICorrect(false);
        // const resp = await axios.post(url, updatedOption, header_token);
        const resp ="";
        if (resp.status === 200) {
          getQuestion();
          setCurrReadingStage(1);
          setSelected(false);
        } else {
          AnswerNotSubmitted();
        }
      } catch (err) {
        AnswerNotSubmitted();
        console.log(err);
      }
    } else {
      getQuestion();
      setCurrReadingStage(1);
    }
  }

  useEffect(() => {}, []);

  return (
    <div className="w-2/3 font-Inter non-italic select-none lg:w-[100%] lg:mt-10 lg:p-4">
      <div className="absolute right-[5%]">
        <button
          onClick={() => setShowModal(!showModal)}
          className="btn btn-danger"
        >
          Report
        </button>
      </div>

      <div className="flex items-top ">
        <span className="h-auto w-auto text-[20px] mr-2">
          {currentQuestion}.
        </span>
        <h1
          className="text-black text-[20px] max-w-[642px] leading-[34px] lg:max-w-none "
          dangerouslySetInnerHTML={{
            __html: questionTitle,
          }}
        ></h1>
      </div>
      <div>
        {showModal && (
          <ReportQuestion
            setShowModal={setShowModal}
            question={questionTitle}
          />
        )}
      </div>

      <p className="font-medium text-[16px] leading-[19px] text-black mt-14">
        Choose the right Answer
      </p>
      <div className="my-8">
        {questionOptions.map((option, index) => (
          <div
            className="flex items-center px-10 py-4 rounded-2xl my-4 cursor-pointer ease-in duration-100 {flag}"
            style={
              selected === option._id
                ? { border: "1px solid #5D44DE" }
                : { border: "none" }
            }
            onClick={() => {
              const currentOption = {
                _id: option._id,
              };
              setChosenOption(currentOption);
              setCallAmICorrect(true);
              setSelected(option._id);

              let newArr = [];
              for (let i = 0; i < headerArr.length; i++) {
                newArr[i] = headerArr[i];
              }
              headerArr[currentQuestion - 1].answered = true;

              setFlag(!flag);
            }}
          >
            <div className="flex items-center justify-center min-w-14 min-h-14">
              <div
                className="bg-white rounded-full border-[7px] w-14 h-14 flex justify-center items-center mr-6"
                style={
                  selected === option._id
                    ? { border: "7px solid #5D44DE" }
                    : { border: "7px solid #9648AD" }
                }
              >
                <FiCheck
                  size="10px"
                  color={selected === option._id ? "#5D44DE" : "Black"}
                  stroke-width="4"
                />
              </div>
            </div>
            <p className="font-normal text-[16px] leading-[19px] text-black ml-4 max-w-[630px]">
              {option.optionValue}
            </p>
          </div>
        ))}
      </div>
      <div className="flex justify-between my-16  ">
        <button
          className="bg-[#9648AD] py-6 px-10 rounded-md w-96 text-white hover:scale-110 select-none ease-in duration-300 font-semibold text-[14px] leading-[17px] sm:px-6 sm:w-60 sm:mr-2"
          onClick={(event) => {
            previous(currentQuestion);
          }}
        >
          Prev
        </button>
        {currentQuestion >= question?.data?.data.max_questions ? (
          <button
            className="bg-[#5D44DE] py-6 px-10 rounded-md w-96 text-white hover:scale-110 ease-in duration-300 select-none font-semibold text-[14px] leading-[17px] sm:px-6 sm:w-60 sm:ml-2"
            onClick={(event) => {
              showAlert();
            }}
          >
            Submit
          </button>
        ) : (
          <button
            className="bg-[#5D44DE] py-6 px-10 rounded-md w-96 text-white hover:scale-110 ease-in duration-300 select-none font-semibold text-[14px] leading-[17px] sm:px-6 sm:w-60 sm:ml-2"
            onClick={(event) => {
              setMaxQuestions(maxQuestions + 1);
              setMaxQuestReached(maxQuestReached + 1);
              optionSelected(chosenOption);
              // checkAnswer();
            }}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default Question;
