export const dateFormat = 'MM-DD-YYYY';
export const timeFormat = 'HH:mm';


export const isRequired = (value, min = 1, max = 100) => {
    if (!value) return "Required!";
    let error = '';
    if (!value && value.length) error = "Required";
    if (min && !value && value.length < min) error = `Min Length is ${min}`;
    if (max && !value && value.length > max) error = `Max Length is ${max}`;
    return error
}
export const isMatch = (a, b) => {
    let error = ''
    if (!a.length) error = 'Required';
    if (a.trim() !== b.trim()) error = 'No Match Found';
    return error
};

export const isEmail = (value) => {
    let errors = '';
    if (!value) {
        errors = 'Required!';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        errors = 'Invalid email address!';
    }
    return errors;
}

export const isMobileNumber = (value) => {
    const regex = /^\d{10}$/;
    let errors = '';
    if (!value) {
        errors = 'Required!';
    } else if (!regex.test(value)) {
        errors = 'Invalid Mobile Number!';
    }
    return errors;
}


export const isPassword = value => {
    let errors = '';
    if (!value) {
        errors = 'Required!';
    } else if (value && value.length < 9) {
        errors = 'Password must be 9 characters long';
    }
    return errors;
};
export const isUsername = value => {
    let errors = '';
    if (!value) {
        errors = 'Required!';
    } else if (value && value.length < 9) {
        errors = 'Username must be 9 characters long';
    }
    return errors;
};
export const isImage = (data, size) => {
    if (!data) return 'Please Select An  Image !';
    if (typeof data === 'object') {
        if (Object.keys(data).length) {
            if (size) {
                if (data.size * 1000 >= size) {
                    return `Please Select a File Below ${size} kb`
                }
                return ""
            }
        } else {
            return 'Invalid Image';
        }
    } else if (typeof data === 'string') {
        if (!data.length) {
            return "Please provide image !"
        }
        return ""
    } else {
        return 'Please provide image !'
    }
    return ""
};
