//API
export const FETCH_START = 'fetch_start';
export const FETCH_END = 'fetch_end';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//NOTIFICATION

export const NOTIFICATION_TYPE_SUCCESS = 'success';
export const NOTIFICATION_TYPE_INFO = 'info';
export const NOTIFICATION_TYPE_WARNING = 'warning';
export const NOTIFICATION_TYPE_ERROR = 'error';
export const NOTIFICATION_ENABLE = 'notification_enable';
export const NOTIFICATION_DISABLE = 'notification_disable';
export const SUCCESS_LOGIN = 'Successfully Logged In...';
export const SUCCESS_SIGNUP = 'Verify Your Mobile Number';
export const SUCCESS_STOP = 'Successfully Stopped the Timer...';
export const SUCCESS_SIGNUP_DESC = 'Please Enter OTP to Verify Your Mobile Number...';
export const ERROR_LOGIN = 'Invalid Credentials';
export const VALIDATION_ERROR = 'Validation Error';
export const OTP_ERROR = 'Please enter Valid OTP';
export const ANSWER_ATLEAST_ONE = 'Choose Option !'
export const USER_DETAILS_UPDATED = 'User Details Updated Successfully...'



