import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const QuizGraph = ({ chartData }) => {
  var options = {
    cutout: 35,
  };

  return <Doughnut data={chartData} options={options}  style={{height:"120px", width:"auto"}}  />;
};

export default QuizGraph