import React from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const LineGraph = ({ graphDescription, avgLearningTime, avgTimeData, title, yAxis }) => {
  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Days in Week",
          color: "red",
        },
      },
      y: {
        title: {
          display: true,
          text: "Average Score for the day",
          color: "red",
        },
      },
    },
  };

  const labels = avgLearningTime;

  const data = {
    labels,
    datasets: [
      {
        label: title,
        data: avgTimeData,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  console.log(data);
  return (
    <div className="relative p-6  w-[100%]  flex flex-col items-center ">
      <Line option={options} data={data} />
      <p className="absolute -rotate-90 left-[-3%] top-[40%] text-[10px]">
       {yAxis}
      </p>
      {/* <p className="absolute left-[40%] bottom-10"> Days</p> */}
      <p className="mt-8">{graphDescription}</p>
    </div>
  );
};

export default LineGraph;
