import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import './terms.css';
import { useEffect } from 'react';

const TermConditions = () => {
   document.title="Terms and Conditions | prepBubble"
   document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
   document.getElementsByTagName('body')[0].style.overflowX = 'hidden';
   useEffect(() => {

      window.scroll(0,1);
    }, [])
   return (
      <div>
         <Header />

         <div className='terms-main-container'>

            <h4>LEASYS TECHNOLOGIES PVT LTD TERMS AND CONDITIONS</h4>
            <br></br>
            <ul>
               <li className='bold'>
                  1.	Website and Service Terms and Conditions - General
               </li>
               <li>
                  The prepBubble website ("Website") and related services (together with the Website, the "Service") are operated by Leasys Technologies Pvt Ltd ("prepBubble", "us" or "we"). Access and use of the Service is subject to the following Terms and Conditions of Service ("Terms and Conditions"). By accessing or using any part of the Service, you represent that you have read, understood, and agree to be bound by these Terms and Conditions including any future modifications. prepBubble may amend, update or change these Terms and Conditions. If we do this, we will post a notice that we have made changes to these Terms and Conditions on the Website for at least 7 days after the changes are posted and will indicate at the bottom of the Terms and Conditions the date these terms were last revised.
                  Any revisions to these Terms and Conditions will become effective the earlier of
                  <ul>
                     <li>(i)	the end of such 7-day period or </li>
                     <li>(ii)	(ii) the first time you access or use the Service after such changes. If you do not agree to abide by these Terms and Conditions, you are not authorized to use, access or participate in the Service.</li>
                  </ul>
               </li>

               <li className='bold'>2. Description of Website and Service</li>
               <li>
                  The Service allows users to (i) prepare for any examination online. Users interested in learning or practicing are presented with different types of educational activities. prepBubble may, in its sole discretion and at any time, update, change, suspend, make improvements to or discontinue any aspect of the Service, temporarily or permanently.
               </li>

               <li className='bold'>
                  3. Registration
               </li>
               <li>In connection with registering for and using the Service, you agree

                  <ul>

                     <li>(i)	to provide accurate, current and complete information about you and/or your organization as requested by prepBubble; </li>
                     <li>(ii)	to maintain the confidentiality of your password and other information related to the security of your account; </li>
                     <li>(iii)	to maintain and promptly update any registration information you provide to prepBubble while registering or during your usage of prepBubble’s services, to keep such information accurate, current and complete; </li>
                     <li> (iv)	to be fully responsible for all use of your account and for any actions that take place through your account; </li>
                     <li>(v)	By signing up on prepBubble you deemed to have given your consent to be contacted by us via phone calls and/or SMS notifications and offer you our services, imparting product knowledge, offer promotional, offers running on website/app, offers offered by the associated third parties and also order/shipment/delivery related updates, irrespective of the fact if also you have registered yourself under DND or DNC or NCPR service, you still authorize prepBubble to give you a call for the above mentioned purposes.</li>
                     <li>(vi)	You are required to provide your examination registration information for the purpose of our internal estimation of successful candidates. If you do not provide the information, your partial or full access to the website content and/or other prepBubble services may be blocked. No refund will be provided in such a scenario. We assure you that we will not use this information for any other purpose or share this information with any third party.</li>
                  </ul>
               </li>

               <li className='bold'>4. Your Representations and Warranties</li>
               <li>You represent and warrant to prepBubble that your access and use of the Service will be in accordance with these Terms and Conditions and with all applicable laws, rules and regulations of Constitution of India and any other relevant jurisdiction, including those regarding online conduct or acceptable content, and those regarding the transmission of data or information exported from India and/or the jurisdiction in which you reside. You further represent and warrant that you have created or own any material you submit to prepBubble and that you have the right to grant us a license to use that material.</li>

               <li className='bold'>
                  5. Inappropriate Use
               </li>
               <li>
                  You will not upload, display or otherwise provide on or through the Service any content that:
                  <ul>
                     <li>is libellous, defamatory, abusive, threatening, harassing, hateful, offensive or otherwise violates any law or infringes upon the right of any third party (including copyright, trademark, privacy, publicity or other personal or proprietary rights); or </li>
                     <li>●	in prepBubble’s sole judgment, is objectionable or which restricts or inhibits any other person from using the Service or which may expose prepBubble or its users to any harm or liability of any kind.</li>
                  </ul>
               </li>


               <li className='bold'>
                  6. Indemnification of prepBubble
               </li>
               <li>
                  You agree to defend, indemnify and hold harmless prepBubble and its directors, officers, employees, contractors, agents, suppliers, licensors, successors and assigns, from and against any and all losses, claims, causes of action, obligations, liabilities and damages whatsoever, including attorneys' fees, arising out of or relating to your access or use of the Service, any false representation made to us (as part of these Terms and Conditions or otherwise), your breach of any of these Terms and Conditions, or any claim that any service we provide to you is inaccurate, inappropriate or defective in any way whatsoever.
               </li>

               <li className='bold'>7. No Representations or Warranties</li>
               <li>The service, including all images, audio files and other content therein, and any other information, property and rights granted or provided to you by prepBubble are provided to you on an "as is" basis. prepBubble and its suppliers make no representations or warranties of any kind with respect to the service, either express or implied, and all such representations and warranties, including warranties of merchantability, fitness for a particular purpose or non-infringement, are expressly disclaimed. Without limiting the generality of the foregoing, prepBubble does not make any representation or warranty of any kind relating to accuracy, service availability, completeness, informational content, error-free operation, results to be obtained from use, or non-infringement. Access and use of the service may be unavailable during periods of peak demand, system upgrades, malfunctions or scheduled or unscheduled maintenance or for other reasons. Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may not apply to you.</li>

               <li className='bold'>
                  8. Limitation on types of Damages/Limitation of Liability
               </li>

               <li>In no event will prepBubble be liable to you or any third party claiming through you (whether based in contract, tort, strict liability or other theory) for indirect, incidental, special, consequential or exemplary damages arising out of or relating to the access or use of, or the inability to access or use, the service or any portion thereof, including but not limited to the loss of use of the service, inaccurate results, loss of profits, business interruption, or damages stemming from loss or corruption of data or data being rendered inaccurate, the cost of recovering any data, the cost of substitute services or claims by third parties for any damage to computers, software, modems, telephones or other property, even if prepBubble has been advised of the possibility of such damages. prepBubble’s liability to you or any third party claiming through you for any cause whatsoever, and regardless of the form of the action, is limited to the amount paid, if any, by you to prepBubble for the service in the 12 months prior to the initial action giving rise to liability. This is an aggregate limit. The existence of more than one claim hereunder will not increase this limit.</li>

               <li className='bold'>
                  9. Termination
               </li>
               <li>
                  prepBubble may terminate your access and use of the Service immediately at any time, for any reason, and at such time you will have no further right to use the Service. You may terminate your prepBubble account at any time by following the instructions available through the Service. The provisions of these Terms and Conditions relating to the protection and enforcement of prepBubble’s proprietary rights, your representations and warranties, disclaimer of representations and warranties, release and indemnities, limitations of liability and types of damages, ownership of data and information, governing law and venue, and miscellaneous provisions shall survive any such termination.
                  prepBubble holds the right to terminate the engagement without any prior notice, with or without a penalty, in case of the following circumstances.

                  <ul>
                     <li>(i)	If you cause a breach in the terms.</li>
                     <li>(ii)	Any abuse (including but not limited to verbal, psychological, physical, discriminatory, or legal) by you.</li>
                     <li>(iii)	In the event of plagiarism found in any document provided by you.</li>
                     <li>(iv)	In the event of falsified or fake information provided by you or in the event of request from you to compromise details in any application related documents.</li>
                     <li>(v)	If it is found that any information or collateral pertaining to our process has been shared with any third party, including friends or relatives</li>
                     <li>(vi)	If you disparage our reputation in any manner whatsoever or make any negative statements about us (written/oral) either on social media or through any other platform.</li>
                  </ul>
               </li>


               <li className='bold'>10. Proprietary Rights in Service Content and Activity Data</li>
               <li>
                  All content available through the Service, including designs, text, graphics, images, information, software, audio and other files, and their selection and arrangement (the "Service Content"), are the proprietary property of prepBubble or its licensors. No Service Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, other than as expressly permitted in these Terms and Conditions. You may not use any data mining, robots, scraping or similar data gathering or extraction methods to obtain Service Content. As between you and prepBubble, all data and information generated from your access and use of the educational activities made available on or through the Service, including translated content generated by you (collectively, the "Activity Data"), shall be exclusively owned by prepBubble, and you shall not have any right to use such Activity Data except as expressly authorized by these Terms and Conditions. Activity Data will not include material you submit for translation or any resulting translation. By using the Service, you hereby assign to prepBubble any and all rights, title and interest, including any intellectual property rights or proprietary rights, in the Activity Data. All rights of prepBubble or its licensors that are not expressly granted in these Terms and Conditions are reserved to prepBubble and its licensors.
               </li>

               <li className='bold'>

                  11. Trademarks
                  </li>
                  <li>"prepBubble" and all other trademarks, service marks, graphics and logos used in connection with the Service are trademarks or service marks of prepBubble or their respective owners. Access and use of the Service does not grant or provide you with the right or license to reproduce or otherwise use the prepBubble name or any prepBubble or third-party trademarks, service marks, graphics or logos.</li>
             

               <li className='bold'>
                  12. Privacy
               </li>
               <li>
                  Use of the Service is also governed by our Privacy Policy, a copy of which is located at http://www.prepbubble.com/privacy. By using the Service, you consent to the terms of the Privacy Policy.
               </li>

               <li className='bold'>

                  13. Cancellation Policy

               </li>
               <li>
                  <ul>
                     <li>
                        (i)	Cancellations will be considered only if the cancellation request is made within 24 hours of placing the order for any order, sale or subscription package above INR 15,000. However, the cancellation request will not be entertained if the orders have already been completed.
                     </li>
                     <li>(ii)	No cancellations are allowed for products on which the prepBubble marketing team has given special offers for various purposes including but not limited to special occasions like New Year, festivals, specific examinations etc. These are limited validity offers and therefore cancellations are not possible.</li>
                     <li>(iii)	In case you feel that the product received is not as shown on the site and/or as per your expectations, you must bring it to the notice of our customer service within 24 hours of receiving the product. The customer service team, after looking into your complaint, will make an appropriate decision.</li>

                  </ul>
               </li>

               <li className='bold'>
                  14. Refund Policy
                  </li>
                  <ul>
                     <li>
                        (i)	The refund is valid for our products/services only until the login details for online access to test material, etc., are disclosed/dispatched/updated on your user account, and the order, sale for subscription package should be worth more than INR 15,000.
                     </li>
                     <li>
                        (ii)	Please include your order/transaction no. number (sent to you via email after your order) and do tell us why you’re requesting a refund. We take customer feedback very seriously and use it to constantly improve our products and quality of service.
                     </li>
                     <li>
                        (iii)	All refunds, if eligible, will be processed within 15 (Fifteen) business days of receiving cancellation request and mode of refund would be the same through which the original transaction was done.
                     </li>
                  </ul>
                  <li className='bold'>
                     15. Notice for Claims of Copyright Violations and Agent for Notice
                  </li>
                  <li>
                     If you are a copyright owner and have a good faith belief that any material available through the service infringes upon your copyrights, you may submit a copyright infringement notification to prepBubble pursuant to the Digital Millennium Copyright Act by providing us with the following information in writing:
                     <ul>
                        <li>(i)	an electronic or physical signature of the copyright owner or the person authorized to act on behalf of the owner of the copyright interest;</li>
                        <li>(ii)	a description of the copyrighted work that you claim has been infringed;</li>
                        <li>(iii)	a description of where the material that you claim is infringing is located on the Service, with enough detail that we may find it on the Service;</li>
                        <li>(iv)	your address, telephone number, and email address;</li>
                        <li>(v)	a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and</li>
                        <li>(vi)	a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or are authorized to act on the copyright owner's behalf.</li>

                     </ul>
                     <li>Please consult your legal counsel for further details or see Section 51 of The Copyright Act, 1957. prepBubble’s Agent for Notice of claims of copyright infringement can be reached as follows: By email: support@aspirantlearning.com</li>



               </li>

               <li className='bold'>16. Governing Law and Arbitration</li>
               <li>
                  These Terms and Conditions, its subject matter and prepBubble and your respective rights under these Terms and Conditions shall be governed by the laws of India and you agree that the courts of Kochi will have exclusive jurisdiction over any dispute (contractual or non-contractual) concerning these terms.
               </li>

               <li className='bold'>

                  17. Injunctive Relief

               </li>
               <li>
                  In the event of your breach of these terms including but not limited to clause 9(vi), you agree that we will be irreparably harmed and may not have an adequate remedy in money or damages. We therefore, shall be entitled in such event to obtain an injunction against such a breach from any court of competent jurisdiction. Our right to obtain such relief shall not limit our right to obtain other remedies.
               </li>

               <li className='bold'>
                  18. Miscellaneous
               </li>
               <li>
                  These Terms and Conditions constitute the entire agreement between prepBubble and you concerning the subject matter hereof. In the event that any of the Terms and Conditions are held by a court or other tribunal of competent jurisdiction to be unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary so that these Terms and Conditions shall otherwise remain in full force and effect. A waiver by prepBubble or you of any provision of these Terms and Conditions or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. prepBubble may assign its rights or obligations under these Terms and Conditions without condition. These Terms and Conditions will be binding upon and will insure to the benefit of prepBubble and you, and prepBubble’s and your respective successors and permitted assigns.
               </li>

               <li className='bold'>

                  Updated on 11th November 2022.

               </li>
            </ul>
         </div>

         <Footer />

      </div>
   )
}

export default TermConditions