import React from "react";
import { useState, useEffect } from "react";
import DashboardDivision from "../../components/Dashboard/DashboardDivision";
import logo from "../../assets/header/logo.png";
import { BiMenuAltLeft } from "react-icons/bi";
import Sidebar from "../../components/Dashboard/Sidebar";
import { getUserName } from "../../@common/shared/LocalStorage/localstorage";
import { env } from "../../@common/helpers/EnvironmentConfig";
import axios from "axios";
import useRazorpay from "react-razorpay";
import { json } from "react-router-dom";
import swal from "sweetalert";

const Subscription = () => {
  const [phoneSidebarStatus, setPhoneSidebarStatus] = useState(false);
  const [userName] = useState(getUserName());
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [availablePlan, setAvailablePlan] = useState([]);

  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };


  const showAlert = () => {
    swal({
      title: "Please Login",
      text: "You can purchase any plan after login.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  };

  const success = () => {
    swal({
      title: "Plan Activated",
      text: "Enjoy Learning Journey!",
      icon: "success",
      buttons: true,
    });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  const [Razorpay] = useRazorpay();

  async function getPaymentId(price) {
    const url = env.API_ENDPOINT_URL + `participant/payment/start`;

    const userDetails = localStorage.getItem("user_Details");
    if (userDetails === null || userDetails === undefined) {
      showAlert();
    } else {
      try {
        let userId = localStorage.getItem("user_identity_id");

        const config = {
          userId: userId,
          amount: parseInt(price),
        };
        const resp = await axios.post(url, config, header_token);
        return resp.data.data.id; //returning the order_id of razorPay
        // return resp.data.data.transaction_id;
      } catch (err) {
        showAlert();
      }
    }
  }

  const handlePayment = async (price) => {
    const paymentId = await getPaymentId(price); //  Create order on your backend

    if (paymentId) {
      let userDetails = localStorage.getItem("user_Details");
      userDetails = JSON.parse(userDetails);

      const options = {
        key: env.RAZORPAY_URL, // Enter the Key ID generated from the Dashboard
        amount: price, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "prepBubble",
        description: "Subscription prepBubble",
        image:
          "https://www.aspirantlearning.com/static/media/logo.e62d09c28b35deee3d99.png",
        order_id: paymentId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          success();
        },
        prefill: {
          name: userDetails.first_name,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#7459ca",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });

      rzp1.open();
    }
  };

  async function getSubscription() {
    

    const token = localStorage.getItem("access_token");
    const header_token = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const url = env.API_ENDPOINT_URL + `participant/get-plan-details`;
      const resp = await axios.get(url, header_token);
      setSubscriptionDetails(resp.data.data.active_plan);
      setAvailablePlan(resp.data.data.available_plans)
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getSubscription();
  }, []);






  return (
    <>
      
      <div className="h-2.5 custom-gradiant"></div>
      {/* after completing content section remove height on this  */}

      <div className="flex items-center flex-row-reverse p-10 bg-[#FAFAFA] w-[95%] m-auto rounded-[20px] my-10 justify-between minlg:hidden">
        <div
          onClick={() => setPhoneSidebarStatus(!phoneSidebarStatus)}
          className="w-16"
        >
          <BiMenuAltLeft className="w-[100%] h-[100%]" />
        </div>
        <img
          src={logo}
          alt="logo"
          className="max-w-custom-s max-h-custom-s overflow-hidden"
        />
      </div>
      <div className="flex flex-wrap not-italic font-Inter w-[95%] m-auto">
        <Sidebar userName={userName} status={phoneSidebarStatus} />

        <div className="w-3/4  overflow-hidden flex flex-col mt-12 font-Inter non-italic text-[#303030] px-4 py-2 border-none lg:mt-0 lg:w-[98%] lg:m-auto ">
          <div className="flex flex-col mb-4">
            <h1 className="font-semibold  text-[28px] leading-[34px] mb-2">
              Subscription
            </h1>
            <p className="font-light text-[14px] leading-[17px] mt-2">
              Your subscription for success
            </p>
          </div>
          <DashboardDivision />

          <br></br>
          <h1 className="font-semibold  text-[28px] leading-[34px] mb-2">
            Your Active Subscription
          </h1>
          <div className="bg-[#E9F5FE] mx-12 my-12 rounded-[20px] px-14  py-6 text-left max-w-[321px] ml-0 ">
            <div className="bg-white rounded-full w-20 my-6">
              {/* <img src={whiteTick} alt="" /> */}
            </div>
            <h1 className="font-semibold text-[22px] max-w-[151px] text-black my-4">
              <span>Free Trial</span>
            </h1>
            <p className="max-w-[242px] font-semi-bold text-[16px] text-[#303030] my-8">
              <ul>
                <li>Plan Name: {subscriptionDetails?.plan_name}</li>
                <li>
                  Plan Validity: {subscriptionDetails?.plan_validity} Days
                </li>

                {/* <li>Test Per Day: {subscriptionDetails?.per_day}</li>
                <li>Total Test: {subscriptionDetails?.total_test}</li> */}
                <li>
                  Start Date: {subscriptionDetails?.createdAt.slice(0, 10)}
                </li>
                <li>
                  End Date: 2023-06-20
                </li>
                <li>Price: ₹ {subscriptionDetails?.price}</li>
              </ul>
            </p>
            <button
              className="bg-[#c30000] text-white text-center px-8 py-4 max-w-[260px] rounded-md w-[100%] my-6 hover:scale-110 transition-all"
              // onClick={() => {
              //   setDashboardHomeModal(true);
              // }}
            >
              Cancel
            </button>
          </div>
          <h1 className="font-semibold  text-[28px] leading-[34px] mb-12">
            Other Plans
          </h1>
          <div className="flex ml-0 pl-0 ">
            <div className="w-full">
              <section
                style={{ backgroundImage: "none" }}
                className="pricing-section"
              >
                  <div className="container-fluid">
            <div className="container1">
              <div className="row1">
                <div className="columns1">
                  <ul className="price1">
                    <li className="header1">BENEFITS</li>
                    <li className="head-text-li">Period</li>
                    {/* <li className="head-text-li">Previous Year Paper</li> */}
                    <li className="head-text-li">Full test</li>
                    <li className="head-text-li">Max Question Per Test</li>
                    <li className="head-text-li">Note Maker</li>
                    <li className="head-text-li">Learning Time Tracker</li>
                    {/* <li className="head-text-li">
                      Current Affairs Compilation
                    </li> */}
                    {/* <li className="head-text-li">
                      Current Affairs Compilation
                    </li> */}
                    <li className="head-text-li">Price</li>
                    {/* <li className="head-text-li">Discounts</li>
                    <li className="head-text-li">Price after Discount</li> */}
                  </ul>
                </div>

                <div className="columns">
                  <ul className="price">
                    <li
                      style={{ backgroundColor: "#00c514" }}
                      className="header"
                    >
                      FREE TRIAL
                    </li>
                    <li>30 Days</li>
                    
                    <li>Yes</li>
                    <li>10</li>
                    <li>Full Access</li>
                    <li>Full Access</li>
                   
                    {/* <li>Free</li>
                    <li>-</li> */}
                    <li className="price-final">Free</li>
                    <li
                      style={{
                        backgroundColor: "#00c514",
                        color: "white",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      Choose
                    </li>
                  </ul>
                </div>
                    {availablePlan.map((plan)=>(
                      <div className="columns">
                      <ul className="price">
                        <li
                          style={{ backgroundColor: "#d5c300" }}
                          className="header"
                        >
                          {plan.plan_name}
                        </li>
                        <li>{plan.plan_validity} days</li>
                        <li>{plan.full_test? "Yes": "No"}</li>
                        <li>{plan.max_questions_per_test}</li>
                      
                        <li>Full Access</li>
                        <li>{plan.time_tracker? "Full Access":"No Access"}</li>
                        
                        {/* <li>₹ {plan.price}</li> */}
                        {/* <li className="text-green-600 font-bold">33%</li> */}
                        <li className="price-final">₹ {plan.price}</li>
                        <li
                          onClick={() => handlePayment(plan.price * 100)}
                          style={{
                            backgroundColor: "#d5c300",
                            color: "white",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                        >
                          Choose
                        </li>
                      </ul>
                    </div>
                    ))}
                

              </div>
            </div>
          </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscription;
