import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DashboardGraph = (props) => {
  const data = {
    datasets: [
      {
        label: "progress",
        data: [props.completedTestsToday, props.allowedDailyTests],
        backgroundColor: ["#FF9900", "#9648AD"],
        borderColor: ["#FF9900", "#9648AD"],
        borderWidth: 1,
      },
    ],
  };
  var options = {
    cutout: 35,
  };

  return <Doughnut data={data} options={options} />;
};

export default DashboardGraph;
