import React, { useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./faq.css";

const FAQ = ({ questions }) => {
  const [activeId, setActiveId] = useState(null);
  const [faqs, setFaqs] = useState(
    questions ?? [
      {
        id: 1,
        question: "What is the prepBubble Platform?",
        answer:
          "prepBubble is a practice platform specifically designed for UPSC CSE Prelims. It helps you to cover your entire Prelims syllabus through MCQ's.",
      },
      {
        id: 2,
        question: "How does prepBubble Platform work?",
        answer:
          "It has mainly 4 components: Daily tasks, Test engine, Note maker, Learning Time Tracker. The analytics from the test engine and learning time tracker are used in the current version to give you comprehensive reports.",
      },
      {
        id: 3,
        question: "What are daily tasks?",
        answer:
          "Each user must perform 4 specific tasks, which are Daily current affairs reading, current affairs MCQs, a comprehensive test, and two subject-specific tests.",
      },
      {
        id: 4,
        question: "How does the prepBubble test work?",
        answer:
          "It has two test options: a full test option and a chapter-by-chapter test option. Based on your performance of each test you can view your detailed analytics under the “My Analytics” tab. In the current version there are no scores displayed, no negative marks nor any time limit.",
      },
      {
        id: 5,
        question: "What is a Full Test?",
        answer:
          "Under the full test option, you will be shown MCQs from all of the subjects in a mixed distribution. There is a time limit of 120 min and negative marking of .66 for each wrong question. Each test will include 100 questions, and fresh questions drawn from the repository will appear on every test you take.",
      },
      {
        id: 6,
        question:
          "Will there be questions on all of the subjects in a full test?",
        answer:
          "Yes, questions from all the subjects listed in prepBubble platform can appear in a full test. These questions will be randomly picked up in a mixed proportion from various chapters across the subjects. The subjects updated in our platform are Current Affairs, Polity, History, Economy, Environment and Ecology, Geography, Science and Technology, Previous year questions.",
      },
      {
        id: 7,
        question: "What is a chapter test?",
        answer:
          "You will be presented with MCQs from the particular subject - Chapter as per your choice. Chapter wise tests will never have 100 MCQs in a single test. Depending on the chapter, there could be 10–70 multiple-choice questions on each test. There will be no negative marking nor a time limit for these tests.",
      },
      {
        id: 8,
        question: "Can I retake the test that I have already attended?",
        answer:
          "No, you cannot take the test that is already attended but for Full tests the tests attended can be viewed again in the full test tab. For subject wise test you will not be able to attend or view a test attended. At times it might take up to 24 hours for the test explanation to appear.",
      },
      {
        id: 9,
        question:
          "Is there any upper limit for the no. of tests that can be taken in a day?",
        answer:
          "For all paid subscribers the upper limit for full tests is 10 and for chapter wise tests is 15.",
      },
      {
        id: 10,
        question: "When can I see the detailed explanations for each question?",
        answer:
          "For Subject wise test your correct and incorrect scores will appear when you've finished the test, and you'll see the option 'View Explanation' underneath them. When you click it, all of the multiple-choice questions from the test are displayed, along with the right answers and explanations. Also, you can view explanations for already attended Full tests under Full test tab.",
      },
      {
        id: 11,
        question: "What is a four stage reading method?",
        answer:
          "MCQs are attended in 4 separate rounds according to the technique during the test. Details are as below Round 1(R1): Only 100% sure questions are attended Round 2(R2): Only questions on which you are 50% certain, i.e., you are aware that two out of four statements or can rule out two out of four alternatives, are answered. Round 3(R3): Only questions for which you have partial knowledge, i.e., are certain about only one statement or a part of the statement, or can rule out only one choice, are answered. Round 3(R3): If there are any questions that you don't know the answer to, try to answer them. You'll be staking your luck in this place. How to use the 4 stage reading method in prepBubble platform?",
      },
      {
        id: 12,
        question: "Will you update the platform with new Mcqs?",
        answer:
          "Absolutely, we do continue to add more questions. We also continuously update the platform to better meet your needs, attempting to take into account your suggestions and opinions.",
      },
      {
        id: 13,
        question: "What is a note maker and how does it work?",
        answer:
          "It is designed to create, save and download your individual notes.It serves as a repository for all of your subject-related note",
      },
      {
        id: 14,
        question: "What is a learning time tracker and how does it work?",
        answer:
          "It helps you to track your study time for different subjects each day. Later, you may check analytics of the time spent each day, week, and month on each subject.",
      },
      {
        id: 15,
        question: "How does 7 Day free trial  work?",
        answer:
          "Under 30 Days free trial, a user can attend 1 previous year question paper, 3 full length tests and 7 subject wise tests within 30 days upon sign up. Note: There will only be 25 questions per subject-specific test when you use the free trial.",
      },
      {
        id: 16,
        question: "Can I retake the test that I have already attended?",
        answer:
          "No, you can still take the free tests after the 30-day free trial. Once the free tests are exhausted you will have to take any of the subscription packages to continue.",
      },
      {
        id: 17,
        question:
          "What features can I use as a free user in the prepBubble platform?",
        answer:
          "Following the expiration of your free trial, you can use the Learning Time Tracker and note-maker with minimal analytics and attend current affairs questions.",
      },
      {
        id: 18,
        question:
          "Will I be able to view the tests that are already attended even after the expiry of my subscription package?",
        answer:
          "No, you will not be able to view the explanations for the full tests once your subscription expires",
      },
      {
        id: 19,
        question:
          "Will I be able to see every question on a test if I take it, even if I skip it or submit it in early?",
        answer:
          "No, you can only view those questions which you have viewed or attended during the test. For those questions that you have not seen, they will not appear in the explanation section or in the Attended Tests under Full test tab.",
      },
      {
        id: 20,
        question: "How does average score in Full test analytics work?",
        answer:
          "It calculates the average score off all full test taken in a single day.",
      },
      {
        id: 21,
        question:
          "Why does strong subject pie diagram in Full test analytics does not work?",
        answer:
          "You have to attend at least 3 full tests with minimum 65 questions answered to start the strong subject pie diagram.",
      },
      {
        id: 22,
        question:
          "Are both full and subject wise tests included in Overall test analytics?",
        answer:
          "Yes, results from both Full tests and Subject wise tests are included to show overall test analytics",
      },
      {
        id: 23,
        question: "Will I be able to review all the tests which I have taken?",
        answer:
          "You can review those tests or questions in the 'Full Tests' section. However, you won't be able to go back and study the questions or tests you took for the 'subject-wise tests'",
      },
      {
        id: 24,
        questions: "Can several users use the same subscription package?",
        answer:
          "If login attempts are detected from multiple devices on a specific user ID, any active subscription packages will remain invalid and the account may be suspended temporarily or permanently.",
      },
    ]
  );
  const toggleActive = (id) => {
    if (id === activeId) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return !questions ? (
    <>
      <Header />
      <div className="faq-main">
        <h1 className="faq-head">FAQ</h1>
        <div className="max-w-5xl mx-auto mt-8">
          {faqs.map((faq) => (
            <div
              key={faq.id}
              className="bg-white rounded-lg shadow-md overflow-hidden mb-4"
            >
              <div
                className="flex justify-between items-center cursor-pointer px-6 py-4"
                onClick={() => toggleActive(faq.id)}
              >
                <h2
                  style={{ fontSize: "15px", color: "#9648ad" }}
                  className="text-lg font-large"
                >
                  {faq.question}
                </h2>
                {activeId === faq.id ? (
                  <p className="sign">-</p>
                ) : (
                  <p className="sign">+</p>
                )}
              </div>
              {activeId === faq.id && (
                <div className="p-6">
                  <p className="text-gray-700">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        <br></br>
        <br></br>
        <Footer />
      </div>
    </>
  ) : (
    <>
      <div className="max-w-5xl mx-auto mt-8">
        {faqs.map((faq) => (
          <div
            key={faq.id}
            className="bg-white rounded-lg shadow-md overflow-hidden mb-4"
          >
            <div
              className="flex justify-between items-center cursor-pointer px-6 py-4"
              onClick={() => toggleActive(faq.id)}
            >
              <h2
                style={{ fontSize: "15px", color: "#9648ad" }}
                className="text-lg font-large"
              >
                {faq.question}
              </h2>
              {activeId === faq.id ? (
                <p className="sign">-</p>
              ) : (
                <p className="sign">+</p>
              )}
            </div>
            {activeId === faq.id && (
              <div className="p-6">
                <p className="text-gray-700">{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default FAQ;
