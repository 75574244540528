import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function HourBarGraph({ timeOverviewTable }) {
  const [labels, setLabels] = useState([]);
  const [timeData, setTimeData] = useState([]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Daily Analysis of today",
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Time in min",
        data: timeData,
        backgroundColor: "#34a853",
        barThickness: 5,
      },
    ],
  };

  function formatDate(inputDate) {
    const [day, month, year] = inputDate.split("-");
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = new Date(`${month}/${day}/${year}`).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  useEffect(() => {
    const formattedLabels = timeOverviewTable.map((item) =>
      formatDate(item.createdAtFormatted)
    );
    const timeSpentValues = timeOverviewTable.map((item) =>
      parseInt(item.total_time_spent/60)
    );

    setLabels(formattedLabels);
    setTimeData(timeSpentValues);

   
    console.log("timeSpentValues:", timeSpentValues);
  }, [timeOverviewTable]);

  return (
    <div className="relative flex flex-col items-center ">
      <Bar options={options} data={data} />
      <p className="absolute -rotate-90 left-[-3%] top-[50%] text-black text-[10px]">
        Time in min
      </p>
    </div>
  );
}
