import React from "react";
import Header from "../../components/Quiz/Header.js";
import { useState, useEffect } from "react";
import Question from "../../components/Quiz/Question.js";
import QuizInfo from "../../components/Quiz/QuizInfo.js";
import Modal from "../../components/Quiz/Modal.js";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { env } from "../../@common/helpers/EnvironmentConfig";
import { toast } from "react-toastify";

const Quiz = () => {
  const [incorrectQuestion, setIncorrectQuestion] = useState(0);
  const [correctQuestion, setCorrectQuestion] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [questionTitle, setQuestionTitle] = useState("");
  const [question, setQuestion] = useState("");
  const [questionOptions, setQuestionOptions] = useState([]);
  const [maxQuestions, setMaxQuestions] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [nextQuestion, setNextQuestion] = useState({});
  const [currReadingStage, setCurrReadingStage] = useState(1);
  const [maxQuestReached,setMaxQuestReached]= useState(0);
  // const [selectedOptionId, setSelectedOptionId] = useState("");
  const [selected, setSelected] = useState(0);
  const location = useLocation();
  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };
  // useEffect(() => {
  //   console.log(maxQuestions);
  // }, [maxQuestions]);
  const [fullTest, setFullTest] = useState(location.state.isFullTest);
  // useEffect(() => {
  //   console.log(location.state.test_id);
  // }, []);
  // console.log(location.state.isFullTest);
  const getQuestion = async () => {
    const url = env.API_ENDPOINT_URL + `participant/test/get-question`;

    try {
      const resp = await axios.post(
        url,
        { ...location.state, current_question: currentQuestion + 1 },
        header_token
      );
      // console.log(resp.data.data.question);
      // console.log(location.state);
      setQuestion(resp);
      // console.log(resp);

      let counter = 1;
      const formattedQuestionHtml =
        resp.data.data.question.questionTitle.replace(
          /<li>/g,
          () => `<li>${counter++}. `
        );

      setQuestionTitle(formattedQuestionHtml);
      setMaxQuestions(resp.data.data.max_questions);
      // console.log(resp.data.data.question.questionOptions)
      setCurrentQuestion(resp.data.data.current_question);
      setQuestionOptions(resp.data.data.question.questionOptions);
      // console.log(resp.data.is_answered);
      if (resp.data.data.is_answered) {
        //   console.log(resp.data.is_answered.selected_answer);
        setSelected(resp.data.data.is_answered.selected_answer);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [headerArr, setHeaderArr] = useState([]);
  useEffect(() => {
    // const onPageLoad = () => {
    //   getQuestion();
    // };

    // Check if the page has already loaded
    // if (document.readyState === "complete") {
    //   onPageLoad();
    // } else {
    //   window.addEventListener("load", onPageLoad);
    //   // Remove the event listener when component unmounts
    //   return () => window.removeEventListener("load", onPageLoad);
    // }
    // console.log("1");
    // setNextQuestion(location.state);
    getQuestion();
  }, []);
  // useEffect(() => {
  //   getQuestion();
  // }, [nextQuestion]);


  useEffect(() => {
    const newArr = [];
    for (let i = 0; i < maxQuestions; i++) {
      if (i === 0) {
        newArr.push({ key: i, visited: true, answered: false });
      } else newArr.push({ key: i, visited: false, answered: false });
    }
    console.log(maxQuestions);
    setHeaderArr(newArr);
  }, [maxQuestions]);


  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
  }, []);

  // useEffect(() => {
  //   console.log(headerArr);
  // }, [headerArr]);

  useEffect(() => {
    let newArr = [];
    if (headerArr.length == 0) {
      // console.log(headerArr);

      for (let i = 0; i < maxQuestions; i++) {
        if (i <= currentQuestion - 1) {
          newArr.push({ key: i, visited: true, answered: false });
        } else newArr.push({ key: i, visited: false, answered: false });
      }
    } else {
      for (let i = 0; i < maxQuestions; i++) {
        newArr[i] = headerArr[i];
      }
      newArr[currentQuestion - 1].visited = true;
    }

    // console.log(newArr);
    // console.log(currentQuestion);
    setHeaderArr(newArr);
  }, [currentQuestion]);

  const NavigateToQuestion = async (index) => {
    const url = env.API_ENDPOINT_URL + `participant/test/get-question`;


    //FORWARD MOVEMENT NOT ALLOWED
    // if(index>=currentQuestion){
    //   alert('not allowed')
    //   toast.warn("Max Test Limit reached!")
    //   return;
    // }

    
    try {
      const resp = await axios.post(
        url,
        { ...location.state, current_question: index },
        header_token
      );
      // console.log(resp.data.data.question);
      // console.log(location.state);
      setQuestion(resp);
      
      let counter = 1;
      const formattedQuestionHtml =
        resp.data.data.question.questionTitle.replace(
          /<li>/g,
          () => `<li>${counter++}. `
        );

      setQuestionTitle(formattedQuestionHtml);
      setMaxQuestions(resp.data.data.max_questions);
      // console.log(resp.data.data.question.questionOptions)
      setCurrentQuestion(resp.data.data.current_question);
      setQuestionOptions(resp.data.data.question.questionOptions);

      // console.log(resp.data.data.is_answered);
      if (resp.data.data.is_answered) {
        // console.log(resp.data.data.is_answered.selected_answer);
        setSelected(resp.data.data.is_answered.selected_answer);
        setCurrReadingStage(resp.data.data.is_answered.reading_stage);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // useEffect(() => {
  //   console.log(fullTest);
  // }, [fullTest]);

  const totalMinutes = 60;

  return (
    <>
      <div className="relative h-[100%] min-h-[100vh] ">
        <div className="h-2.5 custom-gradiant"></div>

        <div className="w-[80%] m-auto font-Inter non-italic">
          <Header
            currentQuestion={currentQuestion}
            setCurrentQuestion={setCurrentQuestion}
            headerArr={headerArr}
            setHeaderArr={setHeaderArr}
            NavigateToQuestion={NavigateToQuestion}
            test_initiate={location.state}
            setIncorrectQuestion={setIncorrectQuestion}
            setCorrectQuestion={setCorrectQuestion}
            setOpenModal={setOpenModal}
            // fullTest={fullTest}
            setFullTest={setFullTest}
            maxQuestReached={maxQuestReached}
          ></Header>
          <div className="h-[1px] bg-[#E1E1E1] my-2 "></div>

          <div className="flex py-10 lg:flex-col">
            <QuizInfo
              totalMinutes={fullTest ? 120 : totalMinutes}
              currentQuestion={currentQuestion}
              maxQuestions={maxQuestions}
              currReadingStage={currReadingStage}
              setCurrReadingStage={setCurrReadingStage}
              fullTest={fullTest}
              setFullTest={setFullTest}
            />

            <Question
              setMaxQuestReached={setMaxQuestReached}
              maxQuestReached={maxQuestReached}
              question={question}
              test_initiate={location.state}
              questionTitle={questionTitle}
              questionOptions={questionOptions}
              getQuestion={getQuestion}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              setOpenModal={setOpenModal}
              headerArr={headerArr}
              setHeaderArr={setHeaderArr}
              nextQuestion={nextQuestion}
              setNextQuestion={setNextQuestion}
              state={location.state}
              setQuestion={setQuestion}
              setQuestionTitle={setQuestionTitle}
              openModal={openModal}
              setIncorrectQuestion={setIncorrectQuestion}
              setCorrectQuestion={setCorrectQuestion}
              setQuestionOptions={setQuestionOptions}
              currReadingStage={currReadingStage}
              setCurrReadingStage={setCurrReadingStage}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </div>
        <Modal
          open={openModal}
          correctQuestion={correctQuestion}
          incorrectQuestion={incorrectQuestion}
          testId={location.state.test_id}
          maxQuestions={maxQuestions}
        />
        {openModal ? (
          <div className="absolute top-0 left-0 w-[100vw] h-[100%] bg-black z-index-10 opacity-50"></div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Quiz;
